import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { apiCall } from "../Service/ApiService";
import { useSnackbar } from "notistack";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => ({
  trie: {
    margin: theme.spacing(4),
  },
}));

const columns: GridColDef[] = [
  //   { field: "id", headerName: "ID", width: 90 },
  {
    field: "date",
    headerName: "Date",
    type: "string",
    width: 200,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => {
      moment(params?.value, "DD/MM/YYYY, HH:mm").format("DD/MM/YYYY HH:mm");
    },
  },
  {
    field: "action",
    headerName: "Action",
    type: "string",
    width: 200,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "details",
    headerName: "Details",
    type: "string",
    width: 450,
    align: "center",
    headerAlign: "center",
    renderCell: (cellValues) => {
      return <Typography variant="body2"> {cellValues.row.details}</Typography>;
    },
  },
  {
    field: "type",
    headerName: "Type",
    type: "number",
    width: 200,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "utilisateur",
    headerName: "Utilisateur",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
  },
  //   {
  //     field: "fullName",
  //     headerName: "Full name",
  //     description: "This column has a value getter and is not sortable.",
  //     sortable: false,
  //     width: 160,
  //     valueGetter: (params: GridValueGetterParams) =>
  //       `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  //   },
];

function sortListLog(a: any, b: any) {
  var aa = parseInt(a.timestamp);
  var bb = parseInt(b.timestamp);
  if (aa < bb) return 1;
  if (aa > bb) return -1;
  return 0;
}

const Logs = () => {
  const classes = useStyles();

  // Stock les logs affiché à l'utilisateur
  //Stock l'ensemble des logs
  const [logs, setLogs] = useState([] as any);
  const [typeOfLogs, setTypeOfLogs] = useState("twoWeeks");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    getListLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filtrer = async (type: string) => {
    const infoKey = enqueueSnackbar("Chargement des logs en cours...", {
      variant: "info",
      autoHideDuration: 5000,
    });
    var newShowLogs: any[] = [].concat(logs);

    var start = moment();
    if (type === "twoWeeks") {
      start = start.subtract(15, "days");
    } else if (type === "oneMonth") {
      start = start.subtract(1, "months");
    } else if (type === "sixMonths") {
      start = start.subtract(6, "months");
    } else {
      start = moment("01/04/2022", "DD/MM/YYYY");
    }

    const apiObject = {
      terminaison: "listLogs",
      startDate: start.unix(),
      endDate: moment().add(1, "days").unix(),
    };
    const res = await apiCall(apiObject);
    newShowLogs = [].concat(JSON.parse(res.data.response).value);
    newShowLogs.sort(sortListLog);
    setLogs(newShowLogs);
    closeSnackbar(infoKey);
    enqueueSnackbar("Filtrage terminé", {
      variant: "success",
      autoHideDuration: 6000,
    });
  };

  const getListLog = async () => {
    const infoKey = enqueueSnackbar("Récupération des logs en cours...", {
      variant: "info",
      autoHideDuration: 5000,
    });
    const apiObject = {
      terminaison: "listLogs",
      startDate: moment().subtract(15, "days").unix(),
      endDate: moment().add(1, "days").unix(),
    };
    let logs = await apiCall(apiObject);
    var listLog = JSON.parse(logs.data.response).value;
    listLog.sort(sortListLog);
    setLogs(listLog);
    closeSnackbar(infoKey);
    enqueueSnackbar("Récupération réussie", {
      variant: "success",
      autoHideDuration: 6000,
    });
  };
  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Typography
          variant="h4"
          style={{ textAlign: "center" }}
          className={classes.trie}
        >
          Logs
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Liste des logs
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={typeOfLogs}
            onChange={async (event: any) => {
              setTypeOfLogs((event.target as HTMLInputElement).value);
              await filtrer((event.target as HTMLInputElement).value);
            }}
          >
            <FormControlLabel
              value="twoWeeks"
              control={<Radio />}
              label="2 semaines"
            />
            <FormControlLabel
              value="oneMonth"
              control={<Radio />}
              label="1 mois"
            />
            <FormControlLabel
              value="sixMonths"
              control={<Radio />}
              label="6 mois"
            />
            <FormControlLabel value="all" control={<Radio />} label="Tous" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={10} style={{ height: "70vh", alignItems: "center" }}>
        <DataGrid
          rows={logs}
          columns={columns}
          rowsPerPageOptions={[5, 10, 15]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Logs;
