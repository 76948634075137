import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import moment from "moment-timezone";
import {
  VictoryArea,
  LineSegment,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryCursorContainer,
  VictoryLine,
} from "victory";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
} from "@material-ui/core";

//Locale française pour moment.js
moment.updateLocale("fr", {
  months:
    "Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre".split(
      "_"
    ),
  monthsShort:
    "Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.".split("_"),
  monthsParseExact: true,
  weekdays: "Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi".split("_"),
  weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
  weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? "er" : "e");
  },
  meridiemParse: /PD|MD/,
  isPM: function (input) {
    return input.charAt(0) === "M";
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? "PD" : "MD";
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
});

const ucesoUTCBackground = "#545657";
const ucesoLocalBackground = "#F2F2F2";
const ucesoUTCDataFill = "#99ADD3";
const ucesoLocalDataFill = "#E88946";

const useStyles = makeStyles((theme) => ({
  //Quelques styles
  container: {
    marginTop: theme.spacing(3),
  },
}));

//Composant affichant le graphique des uceso d'une journée
const UcesoGraph = (props: any) => {
  const classes = useStyles();
  const [utc, setUtc] = useState(1);
  const [backBoneNm, setBackBoneNm] = useState(false);
  const [backBoneRH, setBackBoneRH] = useState(false);

  var lastLabel = 0;
  var position: any[] = [];
  var first = true;
  var compteur = 0;

  const CustomCursorLabel = (props: any) => {
    let { x, y } = props;
    if (x > 300) {
      x = x - 120;
    }
    if (y > 225) {
      y = y - 10;
    } else {
      y = y + 15;
    }

    return <VictoryLabel {...props} x={x} y={y} />;
  };

  return (
    props.uceso && (
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} className={classes.container}>
          {props.date && (
            <Typography
              variant="h5"
              component="h5"
              style={{ textAlign: "center" }}
            >
              Graphique des UCESO du{" "}
              {moment(props.date).format("dddd DD MMMM YYYY")}
            </Typography>
          )}
          {!props.date && (
            <Typography
              variant="h5"
              component="h5"
              style={{ textAlign: "center" }}
            >
              Graphique des UCESO du{" "}
              {moment(props.uceso[utc][500][2], "yyyy-MM-DD HH:mm").format(
                "dddd DD MMMM YYYY"
              )}
            </Typography>
          )}
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={2} className={classes.container}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={backBoneNm}
                  onChange={() => {
                    setBackBoneNm(!backBoneNm);
                  }}
                />
              }
              label="Backbone RH"
            />
          </FormGroup>
          <div
            style={{ height: "2vh", width: "60%", backgroundColor: "#D2B4DE" }}
          ></div>
        </Grid>
        <Grid item md={2} className={classes.container}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={backBoneRH}
                  onChange={() => {
                    setBackBoneRH(!backBoneRH);
                  }}
                />
              }
              label="Backbone NM"
            />
          </FormGroup>
          <div
            style={{ height: "2vh", width: "60%", backgroundColor: "#F9E79F" }}
          ></div>
        </Grid>
        <Grid
          item
          xs={12}
          onDoubleClick={() => {
            if (utc === 0) setUtc(1);
            else setUtc(0);
          }}
        >
          <VictoryChart
            containerComponent={
              <VictoryCursorContainer
                cursorDimension="x"
                cursorLabelComponent={<CustomCursorLabel />}
                cursorLabel={(props: any) => {
                  var heure = `${Math.floor(props.datum.x / 60)}`;
                  var min = `${Math.floor(props.datum.x % 60)}`;

                  if (Math.floor(props.datum.x / 60) < 10) {
                    heure = "0" + heure;
                  }
                  if (Math.floor(props.datum.x % 60) < 10) {
                    min = "0" + min;
                  }
                  return `${heure} : ${min}`;
                }}
              />
            }
            height={300}
            width={800}
            domainPadding={{ y: [0, 50] }}
            style={{
              background: {
                fill: utc === 0 ? ucesoLocalBackground : ucesoUTCBackground,
              },
            }}
            domain={{
              x: [0, 1440],
              y: [
                0,
                props.uceso[utc]
                  .map((x: any) => x[0])
                  .reduce((accumulator: any, currentValue: any) =>
                    Math.max(accumulator, currentValue)
                  ) + 1,
              ],
            }}
          >
            {props.testUceso && (
              <VictoryArea
                data={props.testUceso[utc]}
                x={1}
                y={0}
                interpolation="stepAfter"
                style={{
                  data: {
                    fill: utc === 0 ? ucesoLocalDataFill : ucesoUTCDataFill,
                    stroke: "#b5dbeb",
                    strokeWidth: 1,
                  },
                }}
                labels={({ datum }) => {
                  if (first) {
                    var last = props.uceso[utc][0][0];
                    var compte = -1;
                    props.testUceso[utc].forEach((element: any) => {
                      if (element[0] !== last) {
                        position.push([Math.round(compte / 2), last]);
                        last = element[0];
                        compte = 1;
                      } else {
                        compte++;
                      }
                    });

                    if (
                      props.testUceso[utc][
                        props.testUceso[utc].length - 1
                      ][0] === last
                    ) {
                      position.push([Math.round(compte / 2), last]);
                    }

                    first = false;
                  }

                  try {
                    if (position[lastLabel][0] === compteur) {
                      compteur++;
                      return `${position[lastLabel][1]}`;
                    } else if (position[lastLabel][1] !== datum[0]) {
                      lastLabel++;
                      compteur = 1;
                      return "";
                    } else {
                      compteur++;
                      return "";
                    }
                  } catch (e) {
                    return "";
                  }
                }}
                labelComponent={<VictoryLabel renderInPortal dy={0} />}
              />
            )}
            {props.testUceso && (
              <VictoryArea
                data={props.uceso[utc]}
                x={1}
                y={0}
                style={{
                  data: {
                    fill:
                      utc === 0
                        ? "rgba(153, 173, 211, 0)"
                        : "rgba(39, 167, 179, 0)",
                    stroke: "#F25781",
                    strokeWidth: 1,
                  },
                }}
              />
            )}

            {!props.testUceso && (
              <VictoryArea
                data={props.uceso[utc]}
                x={1}
                y={0}
                interpolation="stepAfter"
                style={{
                  data: {
                    fill: utc === 0 ? ucesoLocalDataFill : ucesoUTCDataFill,
                    stroke: "#b5dbeb",
                    strokeWidth: 1,
                  },
                }}
                labels={({ datum }) => {
                  if (first) {
                    var last = props.uceso[utc][0][0];
                    var compte = -1;
                    props.uceso[utc].forEach((element: any, indexe: number) => {
                      if (element[0] !== last) {
                        position.push([Math.round(compte / 2), last]);
                        last = element[0];
                        compte = 1;
                      } else {
                        compte++;
                      }
                    });

                    if (
                      props.uceso[utc][props.uceso[utc].length - 1][0] === last
                    ) {
                      position.push([Math.round(compte / 2), last]);
                    }

                    first = false;
                  }

                  try {
                    if (position[lastLabel][0] === compteur) {
                      compteur++;
                      return `${position[lastLabel][1]}`;
                    } else if (position[lastLabel][1] !== datum[0]) {
                      lastLabel++;
                      compteur = 1;
                      return "";
                    } else {
                      compteur++;
                      return "";
                    }
                  } catch (e) {
                    return "";
                  }
                }}
                labelComponent={<VictoryLabel renderInPortal dy={0} />}
              />
            )}

            <VictoryAxis
              fixLabelOverlap={true}
              gridComponent={<LineSegment />}
              style={{
                axis: { stroke: "#82abbd" },
                axisLabel: {
                  padding: 30,
                  fontSize: 15,
                  fill: utc === 0 ? "#E88946" : "#04948b",
                },
                grid: { stroke: "#82abbd60" },
                tickLabels: { fontSize: 8 },
              }}
              label={() => {
                if (utc === 0) return "Heures locales";
                else return "Heures UTC";
              }}
              tickValues={[
                0, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720,
                780, 840, 900, 960, 1020, 1080, 1140, 1200, 1260, 1320, 1380,
                1440,
              ]}
              tickFormat={(t) =>
                `${
                  Math.floor(t / 60) < 10
                    ? "0" + Math.floor(t / 60)
                    : Math.floor(t / 60)
                }:${t % 60 < 10 ? "0" + (t % 60) : t % 60}`
              }
            />
            {backBoneRH && (
              <VictoryLine
                style={{
                  data: { stroke: "#F9E79F" },
                  parent: { border: "1px solid #ccc" },
                }}
                data={props.nop[utc]}
              />
            )}

            {backBoneNm && (
              <VictoryLine
                style={{
                  data: { stroke: "#D2B4DE" },
                  parent: { border: "1px solid #ccc" },
                }}
                data={props.besoinFMP[utc]}
              />
            )}

            <VictoryAxis
              dependentAxis
              style={{
                axis: { stroke: "#82abbd" },
                axisLabel: { padding: 30, fontSize: 10, fill: "#04948b" },
                grid: { stroke: "#82abbd60" },
                tickLabels: { fontSize: 8 },
              }}
              label={"Uceso"}
            />
          </VictoryChart>
        </Grid>
      </Grid>
    )
  );
};

export default UcesoGraph;
