import { Card, Grid, useMediaQuery } from "@material-ui/core";
import styled from "@emotion/styled";
import logo from "../Images/logo3.png";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AuthenticationService from "../Service/AuthenticationService";
import { PATHS } from "../Route/routes";
import { CreatePasswordModal } from "../Composant/BackupConnection/CreatePasswordModal";

const StyledCardLitleScreen = styled(Card)`
  padding: 10px;
  margin: auto;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCardBigScreen = styled(Card)`
  padding: 10px;
  margin: auto;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const useStyles = makeStyles((theme) => ({
  fond: {
    backgroundColor: "#2D3E4E",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  logo: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    marginTop: theme.spacing(2),
  },
  btn: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(4),
  },
}));

const LoginCallback = () => {
  //Composant de callback de la connexion, permet de gérer l'attente avant la redirection de connexion

  const classes = useStyles();
  const history = useHistory() as any;
  const theme = useTheme();
  const petit = useMediaQuery(theme.breakpoints.down("md"));

  const [createPassword, setCreatePassword] = useState(false);

  useEffect(() => {
    const state = history.location.state;
    if (state !== undefined)
      AuthenticationService.login(state.code).then((isAuthenticated) => {
        if (isAuthenticated) {
          if (AuthenticationService.user.user.enablePassword === true) {
            history.push(PATHS.Home);
          } else {
            setCreatePassword(true);
          }
        } else {
          history.push(PATHS.Login);
        }
      });
    else history.push(PATHS.Login);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeCreatePasswordModal = () => {
    setCreatePassword(true);
    history.push(PATHS.Home);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (
    <div style={{ height: "100vh" }} className={classes.fond}>
      <div style={{ height: "25vh" }}></div>
      {petit && (
        <StyledCardLitleScreen>
          <Grid container alignContent="center" justifyContent="center">
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              className={classes.logo}
              style={{ height: "15vh" }}
            ></Grid>
            <br />
            <h1 style={{ fontSize: "20px", textAlign: "center" }}>
              Authentification en cours
            </h1>
          </Grid>
        </StyledCardLitleScreen>
      )}

      {!petit && (
        <StyledCardBigScreen>
          <Grid container alignContent="center" justifyContent="center">
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              className={classes.logo}
              style={{ height: "15vh" }}
            ></Grid>
            <br />
            <h1 style={{ fontSize: "20px", textAlign: "center" }}>
              Authentification en cours
            </h1>
          </Grid>
        </StyledCardBigScreen>
      )}

      <CreatePasswordModal
        open={createPassword}
        closeModal={closeCreatePasswordModal}
      />
    </div>
  );
};

export default LoginCallback;
