import {
  Grid,
  Button,
  MenuItem,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { ModalChooseUcesoDate, ModalManageUceso } from "../modal";
import AuthenticationService from "../../Service/AuthenticationService";
import { apiCall } from "../../Service/ApiService";
import UcesoCardGestion from "./UcesoCardGestion";

moment.locale("fr", {
  //Locale française pour moment.js
  months:
    "Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre".split(
      "_"
    ),
  monthsShort:
    "Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.".split("_"),
  monthsParseExact: true,
  weekdays: "Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi".split("_"),
  weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
  weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? "er" : "e");
  },
  meridiemParse: /PD|MD/,
  isPM: function (input) {
    return input.charAt(0) === "M";
  },
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? "PD" : "MD";
  },
  week: {
    dow: 1,
    doy: 4,
  },
});

//Composant permettant la gestion des UCESO, se situe sur la navBar
// Affichage différent en fonction de la taille de l'écran
const GestionUceso = (props: any) => {
  const user = AuthenticationService.user;

  const theme = useTheme();
  const petit = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    getListHoraire();
  }, []);

  const [chargeList, setChargeList] = useState([] as any);
  const [selectedDate, setSelectedDate] = useState(moment().startOf("day"));
  const [cibleCharge, setCibleCharge] = useState(
    props.date ? props.date.map(() => "") : ([] as any)
  );
  const [editHoraire, setEditHoraire] = useState(
    props.date ? props.date.map(() => false) : ([] as any)
  );

  const closeModalChooseUcesoDate = () => {
    props.closeNouveauGraph(false);
  };

  const closeModalListUceso = () => {
    props.closeGraphOuvert(false);
  };

  const closeModalChooseUcesoDateValidation = () => {
    setEditHoraire(editHoraire.concat([false]));
    setCibleCharge(cibleCharge.concat([""]));
    props.addUceDay(selectedDate, true);
  };

  const modalEditH = (e: any, position: number) => {
    if (e !== null) {
      setEditHoraire(
        editHoraire.map((x: any, index: number) =>
          index === position ? false : x
        )
      );
      props.horaireChange(e, position);
    } else {
      setEditHoraire(
        editHoraire.map((x: any, index: number) =>
          index === position ? true : x
        )
      );
    }
  };

  const modalCloseUceso = (e: any, i: any) => {
    const newCibleCharge = [...cibleCharge];
    newCibleCharge.splice(i, 1);
    setCibleCharge(newCibleCharge);
    const newEditH = [...editHoraire];
    newEditH.splice(i, 1);
    setEditHoraire(newEditH);
    props.removeUceDay(i);
  };

  async function getListHoraire() {
    const apiObject = {
      terminaison: "listHoraire",
    };
    const hListeData = await apiCall(apiObject);
    let listHoraire = JSON.parse(hListeData.data.response).value;
    const jsonregex = /.json$/;
    listHoraire = listHoraire.filter((x: any) => jsonregex.test(x));
    const hListeItems = listHoraire.map((horaireName: any, index: number) => (
      <MenuItem value={horaireName} key={horaireName + index}>
        {horaireName}
      </MenuItem>
    ));
    setChargeList(hListeItems);
  }

  const handleDateChange = (date: any) => {
    if (date && date.isValid()) {
      setSelectedDate(date);
    }
  };

  return (
    <>
      {user && !petit && (
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          {props.mode === "U" && user.role.droits.uceso.acces && (
            <>
              {props.date && props.date.length !== 0 && (
                <Grid item lg={12}>
                  <Typography align="center" style={{ color: "white" }}>
                    Liste des graphes :
                  </Typography>
                </Grid>
              )}
              <Grid item lg={12}>
                <Grid
                  container
                  style={{ maxHeight: 400, overflow: "auto" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  {props.uceDays && props.uceDays.length !== 0 && (
                    <>
                      {props.uceDays.map((uceDay: any, indexUceso: number) => (
                        <UcesoCardGestion
                          uceDay={uceDay}
                          horaire={props.horaire[indexUceso]}
                          indexUceso={indexUceso}
                          classes={props.classes}
                          listHoraire={chargeList}
                          horaireChange={props.horaireChange}
                          saveUceDay={props.saveUceDay}
                          removeUceDay={props.removeUceDay}
                          addUceDay={props.addUceDay}
                        />
                      ))}
                      <Grid item xs={10}>
                        <Divider
                          className={props.classes.horaireTopBar}
                          style={{ backgroundColor: "white" }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>

              {user.role.droits.uceso.lecture && (
                <Grid
                  item
                  lg={10}
                  style={{ backgroundColor: "white", borderRadius: "5%" }}
                  className={props.classes.horaireTopBar}
                >
                  <Grid
                    container
                    alignItems="center"
                    alignContent="center"
                    justifyContent="center"
                  >
                    <Grid item lg={10}>
                      <Typography align="center">Nouveau Graphe</Typography>
                    </Grid>
                    <Grid item lg={12}>
                      <Grid
                        container
                        alignItems="center"
                        alignContent="center"
                        justifyContent="center"
                        style={{ backgroundColor: "white", width: "100%" }}
                      >
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            format="DD/MM/yyyy"
                            error={false}
                            helperText={false ? "Date déjà choisie" : ""}
                            margin="normal"
                            id="ucesodate"
                            label="Date du graphique Uceso"
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ textAlign: "center" }}
                          onClick={() => {
                            setEditHoraire(editHoraire.concat([false]));
                            setCibleCharge(cibleCharge.concat([""]));
                            props.addUceDay(selectedDate, true);
                          }}
                        >
                          {/*Vérifier que la date n'est pas déjà présente, ajuster cibleCharge et editH*/}
                          Ouvrir
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      )}
      {user && petit && (
        <>
          {props.mode === "U" && (
            <ModalChooseUcesoDate
              open={props.nouveauGraph}
              closeModal={closeModalChooseUcesoDate}
              validation={closeModalChooseUcesoDateValidation}
              handleDateChange={handleDateChange}
              selectedDate={selectedDate}
            />
          )}
          {props.mode === "U" && (
            <ModalManageUceso
              open={props.graphOuvert}
              uceDays={props.uceDays}
              closeModal={closeModalListUceso}
              modalEditH={modalEditH}
              getHListe={getListHoraire}
              saveUceDay={props.saveUceDay}
              addUceDay={props.addUceDay}
              dateCalc={props.dateCalc}
              date={props.date}
              user={user}
              horaire={props.horaire}
              editH={editHoraire}
              chargeList={chargeList}
              cibleCharge={cibleCharge}
              modalCloseUceso={modalCloseUceso}
              ucesoSalto={props.ucesoSalto}
            />
          )}
        </>
      )}
    </>
  );
};

export default GestionUceso;
