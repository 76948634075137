import Grid from "@material-ui/core/Grid";
import moment from "moment-timezone";
import {
  VictoryBar,
  VictoryChart,
  VictoryCursorContainer,
  VictoryAxis,
  VictoryLabel,
  VictoryContainer,
} from "victory";
import { Typography } from "@material-ui/core";
import deepClone from "lodash/cloneDeep";
import { v4 as uuidv4 } from "uuid";
import AuthenticationService from "../../Service/AuthenticationService";
import { apiCall } from "../../Service/ApiService";
import { periode } from "../../Type/All";

type actionJ = {
  action: string;
  nom: string;
  nomOctime: string;
  veille: boolean;
  valeur: number;
  ExtPcMini: number;
  id: number;
};
type actionSJ = {
  action: string;
  nom: string;
  nomOctime: string;
  veille: boolean;
  valeur: number;
  ExtPcMini: number;
  id: number;
};

export async function sendLogs(action: string, details: string) {
  try {
    const apiObject = {
      terminaison: "postLog",
      item: {
        id: { S: uuidv4() },
        timestamp: { N: moment().unix().toString() },
        action: { S: action },
        date: { S: moment().format("DD-MM-YYYY, HH:mm") },
        type: { S: "Horaire" },
        details: { S: details },
        utilisateur: {
          S:
            AuthenticationService.user.user.firstname +
            " " +
            AuthenticationService.user.user.name,
        },
      },
    };
    const logs = await apiCall(apiObject);

    return logs;
  } catch (error) {
    console.error("Une erreur est survenue : " + error);
  }
}

class FichierHoraire {
  // Définition des propriétés
  validite: Array<Array<string>>; //Objet modélisant les fichiers horaires
  titre: string;
  version: string;
  jours: any[];
  historique: any[];
  strike: boolean;

  constructor(file: any) {
    this.historique = [];
    if (!file) {
      this.titre = "DefaultHoraire";
      this.validite = [
        [
          moment().format("DD/MM/YYYY"),
          moment().add(1, "d").format("DD/MM/YYYY"),
        ],
      ];
      this.version = "A";
      this.jours = [];
      this.strike = false;
    } else if (file.version === "A") {
      this.titre = file.titre;
      this.validite = file.validite;
      this.version = file.version;
      this.jours = file.jours;
      this.strike = file.strike;
    } else {
      this.titre = file.title;
      this.historique.push({
        action: "Add",
        details: "Création d'un nouveau fichier horaire :" + file.title,
      });
      this.validite = [
        [
          moment().format("DD/MM/YYYY"),
          moment().add(1, "d").format("DD/MM/YYYY"),
        ],
      ];
      this.version = "A";
      this.jours = [];
      let template: any[] = [];
      if (file.template === 1)
        template = [
          ["M1", "M1", false, 0],
          ["M2", "M2", false, 0],
          ["J", "J1", false, 0],
          ["S1", "S1", false, 0],
          ["S2", "S2E", false, 0],
          ["N", "N", false, 0],
          ["NVeille", "N", true, 0],
        ];
      this.strike = false;
      template.forEach((j) => {
        this.addJour({
          nom: j[0],
          nomOctime: j[1],
          veille: j[2],
          valeur: j[3],
        });
      });
    }
  }

  //Gestion de l'historique
  addHistory(jour: any) {
    const action = {
      action: "Add",
      details:
        "Ajout dans le fichier " +
        this.titre +
        " du jour " +
        jour.nom +
        " avec comme nom octime " +
        jour.nomOctime,
      id: jour.id,
    };
    this.historique.push(action);
  }

  editJHistory(jour: any) {
    const action = {
      action: "Update",
      details:
        "Mise à jour dans le fichier " +
        this.titre +
        " du jour " +
        jour.nom +
        " avec comme nom octime " +
        jour.nomOctime,
      id: jour.id,
    };
    this.historique.push(action);
  }

  deleteHistory(jour: any) {
    const action = {
      action: "Delete",
      details:
        "Suppression dans le fichier " +
        this.titre +
        " du jour " +
        jour.nom +
        " avec comme nom octime " +
        jour.nomOctime,
    };
    var trouve = false;
    // eslint-disable-next-line array-callback-return
    var historiques = this.historique.filter((changement: actionJ) => {
      if (
        (changement.action === "Add" || changement.action === "Update") &&
        changement.id === jour.id
      ) {
        trouve = true;
      } else {
        return changement;
      }
    });
    if (!trouve) historiques.push(action);
    this.historique = historiques;
  }

  addSJHistory(jour: any, sousJour: any) {
    const action = {
      action: "Add",
      details:
        "Ajout d'un sous-Jour à " +
        jour.nom +
        " ; Nombre d'extensions -> " +
        sousJour.nPExt +
        " ; Fichier " +
        this.titre,
      id: sousJour.id,
    };
    this.historique.push(action);
  }
  editSJHistory(jour: any, sousJour: any) {
    const action = {
      action: "Update",
      details:
        "Mise à jour du sous-Jour de " +
        jour.nom +
        " ; Nombre d'extensions -> " +
        sousJour.nPExt +
        " ; Nombre de périodes : " +
        sousJour.periodes.length +
        " ; Fichier " +
        this.titre,
      id: sousJour.id,
    };
    this.historique.push(action);
  }

  deleteSJHistory(jour: any, sousJour: any) {
    const action = {
      action: "Delete",
      details:
        "Suppression d'un sous-jour à " +
        jour.nom +
        " ; Nombre d'extensions -> " +
        sousJour.nPExt +
        " ; Nombre de période : " +
        sousJour.periodes.length +
        " ; Fichier " +
        this.titre,
      id: sousJour.id,
    };
    var trouve = false;
    // eslint-disable-next-line array-callback-return
    var historiques = this.historique.filter((changement: actionSJ) => {
      if (
        (changement.action === "Add" || changement.action === "Update") &&
        sousJour.id === changement.id
      ) {
        trouve = true;
      } else {
        return changement;
      }
    });
    if (!trouve) historiques.push(action);
    this.historique = historiques;
  }

  sort() {
    this.jours.forEach((jour, index: number) => {
      jour.sousJours.forEach((sj: any, indexSJ: number) => {
        this.jours[index].sousJours[indexSJ].periodes = sj.periodes.sort(
          (a: any, b: any) => {
            if (a[0] < b[0]) return -1;
            if (a[0] > b[0]) return 1;
            return 0;
          }
        );
      });
      this.jours[index].sousJours = jour.sousJours.sort((a: any, b: any) => {
        if (a.nPExt > b.nPExt) return -1;
        else if (a.nPExt < b.nPExt) return 1;
        else if (a.periodes.length > b.periodes.length) return -1;
        else return 1;
      });
    });
  }

  editVal(titre: string) {
    this.titre = titre;
  }

  editValidite(index: number, validite: string[]) {
    this.validite[index] = validite;
  }

  addValidite(validite: string[]) {
    this.validite.push(validite);
  }

  deleteValidite(index: number) {
    this.validite.splice(index, 1);
  }

  addJour(jour: any) {
    //jour contient les données du nouveau jour
    this.jours.push({
      nom: jour.nom,
      nomOctime: jour.nomOctime,
      veille: jour.veille,
      valeur: jour.valeur,
      ExtPcMini: jour.ExtPcMini,
      sousJours: [],
    });
    this.addHistory(jour);
  }

  editJour(dayName: any, nouveauJour: any) {
    //jour est le nom du jour, nouveau jour contient les nouvelles données
    let day = this.jours.find((x) => x.nom === dayName);
    day.nom = nouveauJour.nom;
    day.nomOctime = nouveauJour.nomOctime;
    day.veille = nouveauJour.veille;
    day.valeur = nouveauJour.valeur;
    day.ExtPcMini = nouveauJour.ExtPcMini;

    this.editJHistory(nouveauJour);
  }

  addSousJour(jour: any, sousJour: any) {
    //jour est le nom du jour, sousJour contient les données du sousJour
    const day = this.jours.find((x) => x.nom === jour.nom);

    const checkIfCDSAlreadyExist = day.sousJours.filter(
      (x: any) => x.cds === true
    );
    if (checkIfCDSAlreadyExist.length === 0 || sousJour.cds === false) {
      day.sousJours.push({
        nPExt: parseInt(sousJour.nPExt),
        cds: sousJour.cds,
        periodes: deepClone(sousJour.periodes),
        id: sousJour.id,
      });

      this.addSJHistory(jour, sousJour);
      return 0;
    } else return -1;
  }

  //Jour : nom du jour, sousJour : index du sousJour, nouveauSousJour: données du nouveau sous jour
  editSousJour(
    jour: any,
    indexOfSousJour: number,
    sousJour: any,
    nouveauSousJour: any
  ) {
    const indexOfDay: number = this.jours.findIndex((x) => x.nom === jour.nom);
    const checkIfCDSAlreadyExist = this.jours[indexOfDay].sousJours.filter(
      (x: any) => x.cds === true
    );
    if (checkIfCDSAlreadyExist.length === 0 || nouveauSousJour.cds === false) {
      this.jours[indexOfDay].sousJours[indexOfSousJour].nPExt = parseInt(
        nouveauSousJour.nPExt
      );
      this.jours[indexOfDay].sousJours[indexOfSousJour].cds =
        nouveauSousJour.cds;
      this.editSJHistory(jour, sousJour);
      return this.jours[indexOfDay].sousJours;
    } else return 0;
  }

  addPeriode(jour: any, sousJourIndex: any, periode: periode) {
    let day = this.jours[this.jours.findIndex((x) => x.nom === jour)];
    let subday = day.sousJours[sousJourIndex];
    subday.periodes.push(periode);
    subday.periodes.sort((a: any, b: any) => {
      if (a.debut < b.debut) return -1;
      if (a.debut > b.debut) return 1;
      return 0;
    }); //Critères de tri : nPExt maximum, periodes.length maximum, periodes en elle-même
  }

  removeJour(jour: any) {
    if (this.jours.findIndex((d) => d.nom === jour.nom) !== -1) {
      this.jours.splice(
        this.jours.findIndex((d) => d.nom === jour.nom),
        1
      );
    }
    this.deleteHistory(jour);
  }

  removeSousJour(jour: any, sousJourIndex: any, sousJour: any) {
    let day = this.jours[this.jours.findIndex((x) => x.nom === jour.nom)];
    if (sousJourIndex < day.sousJours.length) {
      day.sousJours.splice(sousJourIndex, 1);
    }
    this.deleteSJHistory(jour, sousJour);
  }

  removePeriode(jour: any, sousJourIndex: any, periode: periode) {
    const day = this.jours[this.jours.findIndex((x) => x.nom === jour)];
    const subday = day.sousJours[sousJourIndex];
    if (
      subday.periodes.findIndex(
        (x: periode) =>
          x.debut === periode.debut &&
          x.fin === periode.fin &&
          x.etat === periode.etat
      ) !== -1
    ) {
      subday.periodes.splice(
        subday.periodes.findIndex(
          (x: periode) =>
            x.debut === periode.debut &&
            x.fin === periode.fin &&
            x.etat === periode.etat
        ),
        1
      );
    }
  }

  printJour(jour: any) {
    let data: any[] = [];
    jour.sousJours.forEach((sousJour: any, i: number) => {
      sousJour.periodes.forEach((periode: periode) => {
        data.push({
          x: i + 1,
          y:
            parseInt(periode.fin.slice(0, 2)) * 60 +
            parseInt(periode.fin.slice(3, 5)),
          y0:
            parseInt(periode.debut.slice(0, 2)) * 60 +
            parseInt(periode.debut.slice(3, 5)),
          ext: sousJour.nPExt ? true : false,
        });
      });
    });
    return (
      <VictoryChart
        horizontal
        key={jour.nom}
        domain={{ x: [0, jour.sousJours.length + 1], y: [0, 1560] }}
        style={{ background: { fill: "#545657" } }}
        height={300}
        width={1800}
        containerComponent={
          <VictoryCursorContainer
            cursorDimension="y"
            cursorLabelComponent={
              <VictoryLabel style={{ fontSize: 12, fill: "#03fcec" }} />
            }
            cursorLabel={(props: any) => {
              var heure = `${Math.floor(props.datum.y / 60)}`;
              var min = `${Math.floor(props.datum.y % 60)}`;

              if (Math.floor(props.datum.y / 60) < 10) {
                heure = "0" + heure;
              }
              if (Math.floor(props.datum.y % 60) < 10) {
                min = "0" + min;
              }
              return `${heure} : ${min}`;
            }}
          />
        }
      >
        <VictoryBar
          data={data}
          style={{
            data: { fill: ({ datum }) => (datum.ext ? "#b5dbeb" : "#27a7b3") },
          }}
        />
        <VictoryAxis
          dependentAxis
          tickValues={[
            0, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720, 780,
            840, 900, 960, 1020, 1080, 1140, 1200, 1260, 1320, 1380, 1440, 1500,
            1560,
          ]}
          tickFormat={(t) =>
            `${
              Math.floor(t / 60) < 10
                ? "0" + Math.floor(t / 60)
                : Math.floor(t / 60)
            }:${t % 60 < 10 ? "0" + (t % 60) : t % 60}`
          }
          style={{
            axis: { stroke: "#82abbd" },
            axisLabel: { padding: 30, fontSize: 14, fill: "#04948b" },
            grid: { stroke: "#82abbd60" },
            tickLabels: { fontSize: 12, fill: "#00000" },
          }}
        />
        <VictoryAxis
          invertAxis={true}
          tickFormat={(t) => Math.round(t)}
          label={"N° Horaire"}
          style={{
            axis: { stroke: "#82abbd" },
            axisLabel: { padding: 30, fontSize: 14, fill: "#04948b" },
            grid: { stroke: "#82abbd60" },
            tickLabels: { fontSize: 12, fill: "#00000" },
          }}
        />
      </VictoryChart>
    );
  }

  printHoraire() {
    return (
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        {this.jours.map((jour) => {
          let data: any[] = [];
          jour.sousJours.forEach((sousJour: any, i: number) => {
            sousJour.periodes.forEach((periode: any) => {
              data.push({
                x: i + 1,
                y:
                  parseInt(periode.fin.slice(0, 2)) * 60 +
                  parseInt(periode.fin.slice(3, 5)),
                y0:
                  parseInt(periode.debut.slice(0, 2)) * 60 +
                  parseInt(periode.debut.slice(3, 5)),
                ext: sousJour.nPExt ? true : false,
              });
            });
          });
          return (
            <Grid item xs={12} key={jour.nom}>
              <Typography
                align="center"
                style={{ color: "#04948b", marginTop: "5px" }}
              >
                Jour {jour.nom}
              </Typography>
              <VictoryChart
                horizontal
                key={jour.nom}
                domain={{ x: [0, jour.sousJours.length + 1], y: [0, 1560] }}
                style={{
                  background: { fill: "#545657" },
                }}
                height={300}
                width={1800}
                containerComponent={
                  <VictoryContainer
                    style={{
                      pointerEvents: "auto",
                      userSelect: "auto",
                      touchAction: "auto",
                    }}
                  />
                }
              >
                <VictoryBar
                  data={data}
                  style={{
                    data: {
                      fill: ({ datum }) => (datum.ext ? "#b5dbeb" : "#27a7b3"),
                    },
                  }}
                />
                <VictoryAxis
                  dependentAxis
                  tickValues={[
                    0, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660,
                    720, 780, 840, 900, 960, 1020, 1080, 1140, 1200, 1260, 1320,
                    1380, 1440, 1500, 1560,
                  ]}
                  tickFormat={(t) =>
                    `${
                      Math.floor(t / 60) < 10
                        ? "0" + Math.floor(t / 60)
                        : Math.floor(t / 60)
                    }:${t % 60 < 10 ? "0" + (t % 60) : t % 60}`
                  }
                  style={{
                    axis: { stroke: "#82abbd" },
                    axisLabel: { padding: 30, fontSize: 14, fill: "#04948b" },
                    grid: { stroke: "#82abbd60" },
                    tickLabels: { fontSize: 12, fill: "#00000" },
                  }}
                />
                <VictoryAxis
                  invertAxis={true}
                  tickFormat={(t) => Math.round(t)}
                  label={"N° Horaire"}
                  style={{
                    axis: { stroke: "#82abbd" },
                    axisLabel: { padding: 30, fontSize: 14, fill: "#04948b" },
                    grid: { stroke: "#82abbd60" },
                    tickLabels: { fontSize: 12, fill: "#00000" },
                  }}
                />
              </VictoryChart>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}
export default FichierHoraire;
