import React, { useEffect, useState } from "react";
import { Grid, Button, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { EditUser, AddUser } from "../Composant/Form";
import DeleteIcon from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import { ModalDeleteUser } from "../Composant/modal";
import AuthenticationService from "../Service/AuthenticationService";
import { apiCall } from "../Service/ApiService";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(3),
  },
}));

const columns: GridColDef[] = [
  //   { field: "id", headerName: "ID", width: 90 },
  {
    field: "utilisateur",
    headerName: "Utilisateur",
    type: "string",
    headerAlign: "center",
    width: 300,
    align: "center",
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.firstname || ""} ${params.row.name || ""}`,
  },
  {
    field: "email",
    headerName: "Email",
    type: "string",
    width: 350,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "policy",
    headerName: "Rôle",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
  },
];

function Droits() {
  //Composant de gestion des droits

  const user = AuthenticationService.user;
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [userList, setUserList] = useState([] as any); //Liste des utilisateurs reconnus par le serveur
  const [selected, setSelected] = useState([] as any); //Utillisateurs actuellement sélectionnés
  const [selectedName, setSelectedName] = useState([] as any); //Utillisateurs actuellement sélectionnés
  const [edit, setEdit] = useState(false); //Liste des différents rôles
  const [delet, setDelete] = useState(false); //Liste des différents rôles
  const [roleList, setRoleList] = useState([] as any); //Liste des différents rôles
  const [update, setUpdate] = useState(0); //Permet de rerender

  useEffect(() => {
    //On cherche les listes d'utilisateurs et de rôles

    const requestUsers = async () => {
      const apiListUserObject = {
        terminaison: "listUser",
      };
      let liste = await apiCall(apiListUserObject);
      setUserList(JSON.parse(liste.data.response).value);

      const apiListRoleObject = {
        terminaison: "listRole",
      };
      let roleList = await apiCall(apiListRoleObject);
      setRoleList(JSON.parse(roleList.data.response).roleList);
    };

    requestUsers();
    //Requêtes liste utilisateurs, rôles/permissions
  }, [update]);

  const openDelete = () => {
    setDelete(true);
  };

  const closeModal = () => {
    setDelete(false);
  };

  //Contacte le serveur pour changer le rôle d'un utilisateur
  const editUser = async (role: any) => {
    let editSnack = enqueueSnackbar("Edition en cours", {
      variant: "info",
      persist: true,
    });

    const apiEditUserObject = {
      terminaison: "editUser",
      id: selected,
      role: role,
    };
    await apiCall(apiEditUserObject);
    closeSnackbar(editSnack);
    enqueueSnackbar("Edition réussie", {
      variant: "success",
      autoHideDuration: 750,
    });
    setSelected([]);
    setSelectedName([]);
    setUpdate(update + 1);
    setEdit(false);
  };
  //Contacte le serveur pour supprimer un utilisateur

  const deleteUser = async () => {
    setDelete(false);
    let deleteSnack = enqueueSnackbar("Suppression en cours", {
      variant: "info",
      persist: true,
    });
    const apiObject = {
      terminaison: "deleteUser",
      id: selected,
    };
    await apiCall(apiObject);
    closeSnackbar(deleteSnack);
    enqueueSnackbar("Suppression réussie", {
      variant: "success",
      autoHideDuration: 750,
    });
    setSelected([]);
    setSelectedName([]);

    setUpdate(update + 1);
  };

  //Contact le serveur pour ajouter un utilisateur
  const addUser = async (
    nom: string,
    prenom: string,
    policy: any,
    email: string
  ) => {
    let addSnack = enqueueSnackbar("Ajout en cours", {
      variant: "info",
      persist: true,
    });

    const apiObject = {
      terminaison: "postUser",
      id: uuidv4(),
      firstname: prenom.charAt(0).toUpperCase() + prenom.toLowerCase().slice(1),
      name: nom.toUpperCase(),
      policy: policy,
      email: email,
    };
    await apiCall(apiObject);
    closeSnackbar(addSnack);
    enqueueSnackbar("Ajout terminé", {
      variant: "success",
      autoHideDuration: 750,
    });
    setUpdate(update + 1);
  };

  const back = () => {
    setEdit(false);
  };

  const resetSelected = (e: any) => {
    //Réinitialise les utilisateurs sélectionnés
    setSelected([]);
    setSelectedName([]);
    return;
  };

  return (
    user.role.droits.droits.acces && (
      <div style={{ height: "100%", width: "100%" }}>
        {userList.length > 0 && roleList.length > 0 ? (
          <Grid>
            <Grid
              container
              alignItems="center"
              alignContent="center"
              justifyContent="center"
              className={classes.button}
            >
              {user.role.droits.droits.personnel &&
                roleList.length > 0 &&
                selected.length > 0 && (
                  <>
                    <Grid item lg={4} xs={12}>
                      {edit && (
                        <EditUser
                          roleList={roleList
                            .map((x: any) =>
                              user.role.droits.droits.niveau >= x.niveau
                                ? x
                                : null
                            )
                            .filter((x: any) => x)}
                          editUser={editUser}
                          selected={selectedName}
                          back={back}
                        />
                      )}
                      {!edit && (
                        <>
                          <br />
                          <Grid
                            container
                            alignItems="center"
                            alignContent="center"
                            justifyContent="center"
                          >
                            <Grid item sm={2} xs={3} md={2} lg={3}>
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setEdit(true);
                                }}
                              >
                                <Edit />
                              </Button>
                            </Grid>
                            <Grid item sm={2} xs={3} md={2} lg={3}>
                              <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={openDelete}
                              >
                                <DeleteIcon />
                              </Button>
                            </Grid>
                            <Grid item sm={2} xs={3} md={1} lg={3}>
                              <Button
                                size="small"
                                variant="outlined"
                                onClick={resetSelected}
                              >
                                Fermer
                              </Button>
                            </Grid>
                            {delet && (
                              <ModalDeleteUser
                                open={delet}
                                closeModal={closeModal}
                                selected={selectedName}
                                deleteUser={deleteUser}
                              ></ModalDeleteUser>
                            )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}

              {user.role.droits.droits.ajout &&
                roleList.length > 0 &&
                selected.length === 0 && (
                  <Grid item xs={10} lg={10}>
                    <AddUser
                      roleList={roleList
                        .map((x: any) =>
                          user.role.droits.droits.niveau >= x.niveau ? x : null
                        )
                        .filter((x: any) => x)}
                      addUser={addUser}
                      userList={userList}
                    />
                  </Grid>
                )}
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{ margin: "auto", height: "80vh" }}
            >
              <Grid item xs={10} style={{ height: "70vh" }}>
                <DataGrid
                  rows={userList}
                  columns={columns}
                  rowsPerPageOptions={[5, 10, 15]}
                  disableSelectionOnClick
                  checkboxSelection
                  selectionModel={selected}
                  onSelectionModelChange={(ids) => {
                    setSelected(ids);
                    const listOfUser = userList.filter((user: any) =>
                      ids.includes(user.id)
                    );
                    const listOfUserName = listOfUser.map((user: any) => {
                      return user.name + " " + user.firstname;
                    });
                    setSelectedName(listOfUserName);
                  }}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            style={{ height: "80vh" }}
          >
            <Grid item>
              <h3 style={{ textAlign: "center" }}>Chargement</h3>
            </Grid>
          </Grid>
        )}
      </div>
    )
  );
}

export default Droits;
