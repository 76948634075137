import { Grid, Button } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import AuthenticationService from "../../Service/AuthenticationService";
import { periode } from "../../Type/All";

export const Periode = (props: any) => {
  const user = AuthenticationService.user;

  //Appel la méthode deleteFPeriode de la classe SousJour qui permet de retirer une période
  const deleteFPeriode = (jour: any, sousJourIndex: any, periode: periode) => {
    props.deleteFPeriode(jour, sousJourIndex, periode);
  };

  return (
    <Grid item key={props.indexSJ + " " + props.index} xs>
      <span
        style={{
          marginRight: "10px",
          color: props.etat === "W" ? "black" : "red",
        }}
      >{`${props.debut} - ${props.fin}`}</span>
      {user.role.droits.horaire.edition && (
        <Button
          style={{
            maxWidth: "30px",
            maxHeight: "30px",
            minWidth: "30px",
            minHeight: "30px",
          }}
          variant="outlined"
          size="small"
          color="secondary"
          onClick={() => {
            deleteFPeriode(props.jour.nom, props.indexSJ, props.periode);
          }}
        >
          <Delete
            style={{
              width: "30px",
              height: "20px",
            }}
          />
        </Button>
      )}
    </Grid>
  );
};
