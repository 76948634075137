import ReactDOM from 'react-dom';
import './CSS/index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Content from './Route/content';

ReactDOM.render(
  <Router>
    <Content />
  </Router>,
  document.getElementById('root')
);
