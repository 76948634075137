import { TextField, Button, Grid, Link } from "@mui/material";
import { Formik, FormikProps, Form } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { PATHS } from "../../Route/routes";
import AuthenticationService from "../../Service/AuthenticationService";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { ErrorModal } from "./Modal/ErrorModal";
import { SuccessModal } from "./Modal/SuccessModal";

const useStyles = makeStyles((theme) => ({
  touch: {
    "&:hover": { cursor: "pointer" },
  },
}));

interface ConnexionForm {
  email: string;
  password: string;
}

const FormConnection = (props: any) => {
  const history = useHistory();
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [texte, setTexte] = useState("");

  const closeErrorModal = () => {
    setError(false);
  };
  const closeSuccessModal = () => {
    setSuccess(false);
  };

  const lostPasswordClick = () => {
    props.lostPasswordClick();
  };
  return (
    <>
      <Formik
        initialValues={{
          password: "",
          email: "",
          remember: false,
        }}
        onSubmit={(values: ConnexionForm, actions) => {
          AuthenticationService.backupLogin(values.email, values.password)
            .then((auth) => {
              if (auth === "OK") {
                history.push(PATHS.Home);
              } else if (auth === "Email and/or password is not correct") {
                setTexte(
                  "L'adresse email et/ou le mot de passe ne sont pas correct."
                );
                setError(true);
              }
            })
            .catch((error) => {
              try {
                if (error.response.data.response === "Aucune correspondance") {
                  setTexte(
                    "L'adresse email et/ou le mot de passe ne sont pas correct."
                  );
                  setError(true);
                } else {
                  setTexte(
                    "Merci de rééssayer! il semblerait qu'une erreur soit survenue en se connectant au serveur."
                  );
                  setError(true);
                }
              } catch (error) {
                setTexte(
                  "Merci de rééssayer ! Il semblerait qu'une erreur soit survenue en se connectant au serveur."
                );
                setError(true);
              }
            });
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required("Entrez un email valide"),
          password: Yup.string()
            .matches(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()/+-])/,
              "Minimum 1 upper case, 1 lower case, 1 number and 1 special character."
            )
            .min(8, "8 characters minimum")
            .required("Entrez un mot de passe valide"),
        })}
      >
        {(props: FormikProps<ConnexionForm>) => {
          const { values, touched, errors, handleBlur, handleChange } = props;
          return (
            <Form noValidate>
              <Grid
                container
                alignContent="center"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    style={{ color: "white" }}
                    value={values.email}
                    helperText={
                      errors.email && touched.email ? errors.email : ""
                    }
                    error={errors.email && touched.email ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={values.password}
                    helperText={
                      errors.password && touched.password
                        ? "Entrez un mot de passe valide"
                        : ""
                    }
                    error={errors.password && touched.password ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid
                item
                  xs={12}
                  md={12}
                  style={{ marginBottom: "8px", textAlign: "center" }}
                >
                  <Link
                    className={classes.touch}
                    variant="body2"
                    onClick={() => {
                      lostPasswordClick();
                    }}
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item xs={12} md={8} style={{ marginTop: "8px" }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <ErrorModal open={error} closeModal={closeErrorModal} texte={texte} />
      <SuccessModal
        open={success}
        closeModal={closeSuccessModal}
        texte={texte}
      />
    </>
  );
};

export default FormConnection;
