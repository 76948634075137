import moment from "moment";

//Calcul les uceso en fonction des attributions du personnel
const calculUceso = (
  horaire: any,
  personnel: any[],
  precPersonnel: any[],
  date: string
) => {
  try {
    if (!horaire || !personnel || !precPersonnel || !date) return;
    //Initilisation du tableau pour chacune des 26h
    let uceso: Array<number> = new Array(1560);
    uceso.fill(0);

    if (horaire && horaire.length !== 0) {
      horaire.forEach((jour: any) => {
        if (jour.nomOctime !== "" && jour.nomOctime) {
          let newAgent;
          //Récupération des agents correspondant au jour
          if (jour.nom.indexOf("Veille") === -1) {
            newAgent = personnel.find((agent) => {
              if (agent.length !== 0) {
                return (
                  agent &&
                  agent.length !== 0 &&
                  agent[0].horaire === jour.nomOctime
                );
              }
              return false;
            });
          } else {
            newAgent = precPersonnel.find((agent) => {
              if (agent.length !== 0) {
                return (
                  agent &&
                  agent.length !== 0 &&
                  (agent[0].horaire + "Veille" === jour.nom ||
                    agent[0].horaire === jour.nom)
                );
              }
              return false;
            });
          }
          if (newAgent) {
            newAgent.forEach((pers: any) => {
              if (pers.horaireNum !== undefined) {
                jour.sousJours[pers.horaireNum].periodes.forEach(
                  (periode: any) => {
                    if (periode.etat === "W") {
                      const startHoraire =
                        parseInt(periode.debut.slice(0, 2)) * 60 +
                        parseInt(periode.debut.slice(3, 5));
                      const endHoraire =
                        parseInt(periode.fin.slice(0, 2)) * 60 +
                        parseInt(periode.fin.slice(3, 5));

                      if (startHoraire + endHoraire)
                        for (
                          let ucesoValue = startHoraire;
                          ucesoValue < endHoraire;
                          ucesoValue++
                        ) {
                          uceso[ucesoValue]++;
                        }
                    }
                  }
                );
              }
            });
          }
        }
      });
    }

    uceso = uceso.map((x) => (x < 0 ? 0 : x));
    uceso = uceso.map((x) => Math.floor(x / 2));

    return writeUceso(uceso, date);
  } catch (error) {
    console.error(error);
    console.error("An error has occured");
  }
};

const writeUceso = (uceso: any, date: string) => {
  //Nécessaire pour récupérer les 2 dernières heures UTC
  const dateLendemain = moment
    .tz(`${date}T1400`, "Europe/Paris")
    .add(1, "day")
    .format("YYYYMMDD");

  const ucesoFinal: any[] = [];
  const ucesoUTCFinal: any[] = [];

  //On récupère le décallage horaire par rapport à utc, on prend 12h comme référence car la bascule se fait à 1h00
  const utcOffset = moment.tz(`${date}T1200`, "Europe/Paris").utcOffset();

  var lastUceso = uceso[0];

  uceso.forEach((data: number, index: number) => {
    var heure = `${Math.floor(index / 60)}`;
    var min = `${Math.floor(index % 60)}`;

    if (Math.floor(index / 60) < 10) {
      heure = "0" + heure;
    }
    if (Math.floor(index % 60) < 10) {
      min = "0" + min;
    }

    //On vérifie si la journée en heure UTC a commencé
    if (index - utcOffset < 0) {
      ucesoFinal.push([
        lastUceso,
        index,
        moment.tz(`${date}T${heure}${min}`, "Europe/Paris").utc(),
      ]);
    } else if (index < 1440) {
      ucesoUTCFinal.push([
        lastUceso,
        index - utcOffset,
        moment.tz(`${date}T${heure}${min}`, "Europe/Paris").utc(),
      ]);
      ucesoFinal.push([
        lastUceso,
        index,
        moment.tz(`${date}T${heure}${min}`, "Europe/Paris").utc(),
      ]);
    }
    //On complète les ucesos UTC avce le jour du lendemain (soit jusque 1500 soit jusque 1560)
    else if (index >= 1440 && index < 1440 + utcOffset) {
      if (parseInt(heure) >= 24) {
        heure = `${Math.floor(parseInt(heure) % 24)}`;
      }
      if (parseInt(heure) < 10) {
        heure = "0" + heure;
      }

      ucesoUTCFinal.push([
        lastUceso,
        index - utcOffset,
        moment.tz(`${dateLendemain}T${heure}${min}`, "Europe/Paris").utc(),
      ]);
    }

    lastUceso = data;
  });
  return [ucesoFinal, ucesoUTCFinal];
};

export { calculUceso, writeUceso };
