import { Grid } from "@material-ui/core";
import { AjoutSjForm } from "../Form";
import AuthenticationService from "../../Service/AuthenticationService";
import deepClone from "lodash/cloneDeep";
import { useState } from "react";
import { SousJour } from "./SousJour";
import { useSnackbar } from "notistack";

export const Jour = (props: any) => {
  const user = AuthenticationService.user;

  const [jour, setJour] = useState(props.jour);
  const [sousJours, setSousJours] = useState(props.jour.sousJours);
  const { enqueueSnackbar } = useSnackbar();

  // Appel la fonction d'ajout de sousJours et d'implémentation de l'historique de la classe FichierHoraire
  const newFSubday = (jour: any, sousJour: any) => {
    const retour = props.horaire.addSousJour(jour, sousJour); //Ici sousJour est un objet {nPExt:number, periodes:array[object], cds: boolean}
    if (retour === 0) {
      const newHoraire = deepClone(props.horaire);
      setJour(newHoraire.jours[props.position]);
    } else {
      enqueueSnackbar("Un sous-jours CDS existe déjà", {
        variant: "warning",
      });
    }
  };

  // Appel la fonction de suppression de sousJours et d'implémentation de l'historique de la classe FichierHoraire
  const deleteFSubday = (jour: any, sousJour: any, positionSousJour: any) => {
    props.horaire.removeSousJour(jour, positionSousJour, sousJour); //Ici sousJour est l'index de l'objet sousJour à retirer
    const newJour = deepClone(props.horaire.jours[props.position]);
    setJour(newJour);
  };

  //Edite un sous-jour
  const editFSubday = (
    jour: any,
    sousJourIndex: any,
    sousJour: any,
    newExtension: any
  ) => {
    const test = props.horaire.editSousJour(
      jour,
      sousJourIndex,
      sousJour,
      newExtension
    ); //Jour est un nom, sJIndex, l'index et sousJour le nouveau sous Jour
    if (test === 0) {
      enqueueSnackbar("Un sous-jours CDS existe déjà", {
        variant: "warning",
      });
    } else {
      setSousJours(test);
      const editJour = deepClone(props.horaire.jours[props.position]);
      editJour.sousJours = test;
      setJour(editJour);
    }
  };

  const updateJour = (newJour: any) => {
    setJour(newJour);
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        {sousJours.length} Sous-jours
      </Grid>
      <Grid item xs={12}>
        {/* Affiche le graphique de l'évolution des horaires */}
        {props.horaire.printJour(jour)}
      </Grid>

      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          alignContent: "stretch",
          alignItems: "stretch",
        }}
      >
        {user.role.droits.horaire.ajout && (
          <Grid item xs={12}>
            <AjoutSjForm
              newFSubday={newFSubday}
              jour={jour}
              style={{
                backgroundColor: jour.veille
                  ? "#CFCFCF"
                  : !jour.nomOctime
                  ? "#FFCFCF"
                  : "#CFCFFF",
                margin: "auto",
                padding: 5,
              }}
            />
          </Grid>
        )}
        {sousJours.map((sj: any, index: number) => (
          <SousJour
            horaire={props.horaire}
            updateJour={updateJour}
            jour={jour}
            sousJour={sj}
            index={index}
            key={index}
            positionJour={props.position}
            newFSubday={newFSubday}
            deleteFSubday={deleteFSubday}
            editFSubday={editFSubday}
          />
        ))}
      </Grid>
    </Grid>
  );
};
