import moment from "moment-timezone";
import FHoraire from "../Horaire/FichierHoraire";
import AuthenticationService from "../../Service/AuthenticationService";
import { log } from "../../Type/All";
import {
  jour,
  recycleur,
  octimeData,
  agent,
  agentAffecte,
  sousJour,
  salto,
  personnel,
} from "../../Type/All";
import { v4 as uuidv4 } from "uuid";
import { apiCall } from "../../Service/ApiService";
import { calculUceso } from "./UcesoTestShareFonction";

//Function de conversion en XML du fichier salto
function OBJtoXML(obj: any) {
  var xml = "";
  for (var prop in obj) {
    xml += "<" + prop + ">";
    if (obj[prop] instanceof Array) {
      for (var array in obj[prop]) {
        xml += OBJtoXML(obj[prop][array]);
      }
    } else if (typeof obj[prop] == "object") {
      xml += OBJtoXML(obj[prop]);
    } else {
      xml += obj[prop];
    }
    xml += "</" + prop + ">";
  }
  const lastXml = xml.replace(/<\/?[0-9]{1,}>/g, "");
  return lastXml;
}

function affectSJ(
  compteur: number,
  recyclage: any,
  nbrSousJours: number,
  extensionNumber: number,
  agent: any,
  compteurExt: number,
  jour: any
): any {
  if (
    compteur - (recyclage.length - 1) <
      nbrSousJours + (extensionNumber - (recyclage.length - 1)) &&
    compteur - (recyclage.length - 1) >= 0
  ) {
    //Si le sous-jour visé correspondant à un sous jour de cds, on incrémente compte et on relance la fonction
    if (jour.sousJours[compteur].cds) {
      compteur++;
      return affectSJ(
        compteur,
        recyclage,
        nbrSousJours,
        extensionNumber,
        agent,
        compteurExt,
        jour
      );
    } else {
      agent.horaireNum = compteur;
      //On vérifie si le sous-jour est un sous jour d'extension, si oui on met isExtension à true
      if (compteurExt < extensionNumber && compteur < extensionNumber) {
        agent.isExtension = true;
        compteurExt++;
      } else {
        agent.isExtension = false;
      }
      compteur++;
    }
  } else if (nbrSousJours > 0) {
    compteur = extensionNumber;
    agent.horaireNum = compteur;
    compteur++;
  } else {
    if (nbrSousJours > 0) compteur = nbrSousJours;
    else compteur = 0;
    agent.horaireNum = compteur;
  }
  return [compteur, compteurExt, agent];
}

// Récupère la liste des recyclages du jour et vérifie que les recycleurs sont bien présents si ce n'est pas le cas un message est montré aux utilisateurs (uniquement en dehors du mode de test)
export async function getExtInRecyclage(
  date: string,
  personnel: Array<Array<agentAffecte>>,
  veille: boolean
): Promise<Array<any>> {
  // On va lister les recycleurs qui sont absents dans octime
  const recylageAbsence: Array<string> = [];

  const apiObject = {
    terminaison: "recyclageExtension",
    date:
      date[0] +
      date[1] +
      date[2] +
      date[3] +
      "-" +
      date[4] +
      date[5] +
      "-" +
      date[6] +
      date[7],
  };
  const getRecyclage = await apiCall(apiObject);

  const recycleurs: Array<recycleur> = JSON.parse(getRecyclage.data.response);
  //On parcourt les recycleurs
  const newRecycleurs = recycleurs.map((recycleur: recycleur) => {
    let newNIL = false;
    //On parcourt le personnel du jour
    personnel.forEach((jour: Array<agentAffecte>) => {
      const agentsFiltre = jour.filter(
        (agent: agentAffecte) =>
          recycleur.pc === agent.matricule && agent.absence !== undefined
      );
      //On vérifie si il y a des agents absents, si oui on l'ajoute au tableau d'agents absents et on le définit sur NIL
      if (
        (agentsFiltre.length !== 0 && !veille) ||
        (agentsFiltre.length !== 0 && veille && recycleur.jour === "N")
      ) {
        recylageAbsence.push(
          agentsFiltre[0].nom +
            " " +
            agentsFiltre[0].prenom +
            " absent(e) en " +
            recycleur.jour +
            " pour cause de " +
            agentsFiltre[0].absence +
            " (Extension)"
        );
        newNIL = true;
      }
    });
    if (newNIL) {
      return { pc: "NIL", jour: recycleur.jour };
    }
    if (veille && recycleur.jour === "N") {
      return { pc: recycleur.pc, jour: "NVeille" };
    }
    return recycleur;
  });

  return [newRecycleurs, recylageAbsence];
}

// Récupère la liste des CDS du jour et vérifie que les cds sont bien présents si ce n'est pas le cas un message est montré aux utilisateurs (uniquement en dehors du mode de test)
export async function getCDSInRecyclage(
  date: string,
  personnel: Array<Array<agentAffecte>>,
  veille: boolean
): Promise<Array<any>> {
  // On va lister les cds qui sont absents dans octime
  const cdsAbsence: Array<string> = [];
  const apiObject = {
    terminaison: "cds",
    date:
      date[0] +
      date[1] +
      date[2] +
      date[3] +
      "-" +
      date[4] +
      date[5] +
      "-" +
      date[6] +
      date[7],
  };
  const getCDS = await apiCall(apiObject);
  const cds: Array<recycleur> = JSON.parse(getCDS.data.response);
  const newCDS = cds.map((cdsInformations: any) => {
    let newNIL = false;
    //On parcourt le personnel présent au jour choisit
    personnel.forEach((jour: Array<agentAffecte>) => {
      const agentsFiltre = jour.filter(
        (agent: agentAffecte) =>
          cdsInformations.cds === agent.matricule && agent.absence !== undefined
      );
      // On vérifie si le cds est présent, si il est absent on modifie son attribution à NIL et on ajoute un message
      if (
        (agentsFiltre.length !== 0 && !veille) ||
        (agentsFiltre.length !== 0 && veille && cdsInformations.jour === "N")
      ) {
        cdsAbsence.push(
          agentsFiltre[0].nom +
            " " +
            agentsFiltre[0].prenom +
            " absent(e) en " +
            cdsInformations.jour +
            " pour cause de " +
            agentsFiltre[0].absence +
            " (CDS)"
        );
        newNIL = true;
      }
    });
    if (newNIL) {
      return { cds: "NIL", jour: cdsInformations.jour };
    }
    if (veille && cdsInformations.jour === "N") {
      return { cds: cdsInformations.cds, jour: "NVeille" };
    }
    return cdsInformations;
  });

  return [newCDS, cdsAbsence];
}

// Récupère les fichiers BesoinFMP et Nop sur le bucket ucesorh
async function getCsvFile(nopDate: string): Promise<Array<Promise<any>>> {
  const apiNopObject = {
    terminaison: "nop",
    date: nopDate,
  };
  const nop = apiCall(apiNopObject).catch(() => {
    return [];
  });
  const apiBesoinFMPObject = {
    terminaison: "besoinFMP",
    date: nopDate,
  };
  const besoin_FMP = apiCall(apiBesoinFMPObject);
  return [nop, besoin_FMP];
}

//Trie les agents en fonction de leurs horaires
function personnalSort(list: Array<any>): Array<any> {
  return list.sort(function compare(a: any, b: any) {
    var aa = a.horaireNum;
    var bb = b.horaireNum;
    if (aa === undefined) aa = Infinity;
    if (bb === undefined) bb = Infinity;
    if (aa < bb) return -1;
    if (aa > bb) return 1;
    return 0;
  });
}

//Récupère les données du jour sur octime
async function getOctimeData(
  date: string = moment().format("YYYYMMDD")
): Promise<octimeData> {
  const apiObject = {
    terminaison: "octime",
    date: date,
  };
  const result = await apiCall(apiObject);
  return JSON.parse(result.data.response);
}

export async function sendLogs(action: string, details: string) {
  const utilisateur = AuthenticationService.user.user
    ? AuthenticationService.user.user.firstname +
      " " +
      AuthenticationService.user.user.name
    : JSON.stringify(AuthenticationService.user.user);
  try {
    const apiObject = {
      terminaison: "postLog",
      item: {
        id: { S: uuidv4() },
        timestamp: { N: moment().unix().toString() },
        action: { S: action },
        date: { S: moment().format("DD/MM/YYYY, HH:mm") },
        type: { S: "Uceday" },
        details: { S: details },
        utilisateur: {
          S: utilisateur,
        },
      },
    };
    const logs = await apiCall(apiObject);

    return logs;
  } catch (error) {
    console.error("Une erreur est survenue : " + error);
  }
}

class Uceday {
  salto: string;
  listOfUnassignedAgents: Array<agent>;
  personnel: any;
  precPersonnel: any;
  date: any;
  dateCalc: string;
  uceso: any[];
  horaire: FHoraire;
  nop: any;
  besoinFMP: any;
  private warning: string[];

  constructor() {
    this.warning = [];
    this.dateCalc = "";
    this.uceso = [];
    this.listOfUnassignedAgents = [];
    this.salto = "";
    this.horaire = new FHoraire(undefined);
  }

  //Renvoie les warning
  getWarning(): Array<string> {
    return this.warning;
  }

  //Fonction de test qui permet d'afficher sous forme textuel l'objet
  toString() {
    return {
      listOfUnassignedAgents: this.listOfUnassignedAgents,
      personnel: this.personnel,
      date: this.date,
      salto: this.salto,
      precPersonnel: this.precPersonnel,
      dateCalc: this.dateCalc,
      uceso: this.uceso,
      horaire: this.horaire,
      nop: this.nop,
      besoinFMP: this.besoinFMP,
    };
  }

  async repartitionAgent(
    ucedayDate: string,
    nop: any,
    besoinFMP: any,
    horaire: any = undefined
  ): Promise<number> {
    //On récupérer les astreintes de la veille et du jour si le fichier horaire est de grève
    if (this.horaire.strike) {
      var astreintsData: any[];
      var astreintsDataVeille: any[];
      try {
        const apiObjectAstrints = {
          terminaison: "astreints",
          date: moment(ucedayDate, "YYYYMMDD").format("YYYY-MM-DD"),
        };
        const astreints = await apiCall(apiObjectAstrints);
        astreintsData = JSON.parse(astreints.data.response);
        const apiObjectAstrintsVeilles = {
          terminaison: "astreints",
          date: moment(ucedayDate, "YYYYMMDD")
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
        };
        const astreintsVeille = await apiCall(apiObjectAstrintsVeilles);
        astreintsDataVeille = JSON.parse(astreintsVeille.data.response);
      } catch (e) {
        console.error("Problème dans la récupération des astreintes");
      }
    }

    return await getOctimeData(ucedayDate).then(async (data: any) => {
      //Si pas de valeur on retourne un code erreur
      if (!data) {
        return -1;
      }
      try {
        let perso = data.personnel;
        let precPerso = data.precPersonnel;

        //On vérifie que l'on a bien récupéré les listes de personnels
        if (perso.donnees && precPerso.donnees) {
          // On récupère les informations des agents
          perso.donnees = perso.donnees.map((agent: any) => {
            const agentInformations = data.personnelInfo.employe.find(
              (agentInformations: any) =>
                agentInformations.matricule === agent.matricule
            );

            if (agentInformations !== undefined)
              return {
                matricule: agent.matricule,
                horaire: agent.horaire,
                absence:
                  this.horaire.strike && astreintsData !== null
                    ? astreintsData.find((pc: any) => pc.pc === agent.matricule)
                      ? undefined
                      : "ABS"
                    : agent.absence,
                nom: agentInformations.nom,
                prenom: agentInformations.prenom,
                poste: agentInformations.poste,
              };
            else {
              return undefined;
            }
          });

          //On récupère les informations des agents de la veille
          precPerso.donnees = precPerso.donnees.map((agent: any) => {
            const agentInformations = data.personnelInfo.employe.find(
              (agentInformations: any) =>
                agentInformations.matricule === agent.matricule
            );

            if (agentInformations !== undefined)
              return {
                matricule: agent.matricule,
                horaire: agent.horaire,
                absence:
                  this.horaire.strike && astreintsDataVeille !== null
                    ? astreintsDataVeille.find(
                        (pc: any) => pc.pc === agent.matricule
                      )
                      ? undefined
                      : "ABS"
                    : agent.absence,
                nom: agentInformations.nom,
                prenom: agentInformations.prenom,
                poste: agentInformations.poste,
              };
            else return undefined;
          });

          perso.donnees = perso.donnees.filter((data: any) => {
            return data !== undefined;
          });
          precPerso.donnees = precPerso.donnees.filter((data: any) => {
            return data !== undefined;
          });

          data.personnelInfo.employe = data.personnelInfo.employe.filter(
            (data: any) => {
              return data !== undefined;
            }
          );

          const personnelInfos = data.personnelInfo.employe.filter(
            (agentInformations: agent) => {
              if (data !== undefined) {
                return (
                  perso.donnees.findIndex(
                    (agent: agentAffecte) =>
                      agent.matricule === agentInformations.matricule
                  ) === -1 &&
                  precPerso.donnees.findIndex(
                    (agent: agentAffecte) =>
                      agent.matricule === agentInformations.matricule &&
                      agent.horaire === "N"
                  ) === -1
                );
              } else return false;
            }
          );

          try {
            perso = this.traitementPersonnel(perso, ucedayDate);
            precPerso = this.traitementPersonnel(precPerso, ucedayDate);
          } catch (error) {
            return -1;
          }
          this.listOfUnassignedAgents = personnelInfos;
          let fhoraire = new FHoraire(
            horaire === undefined ? data.horaire : horaire
          );
          [perso, precPerso] = await this.attributionPersonnel(
            perso,
            precPerso,
            fhoraire,
            ucedayDate
          );

          this.personnel = perso;
          this.precPersonnel = precPerso;
          this.date = ucedayDate;
          this.dateCalc = moment().format("DD/MM/YYYY");
          this.uceso = this.calculUceso(
            fhoraire,
            perso,
            precPerso,
            ucedayDate
          )!;
          this.horaire = fhoraire;
          this.nop = nop;
          this.besoinFMP = besoinFMP;

          let isInRange = false;
          fhoraire.validite.forEach((validite: string[]) => {
            if (
              moment(ucedayDate, "YYYYMMDD").isBetween(
                moment(validite[0], "DD/MM/YYYY"),
                moment(validite[1], "DD/MM/YYYY"),
                undefined,
                "[]"
              )
            ) {
              isInRange = true;
            }
          });

          //Retourne le code 0 si jamais la date uceso n'est pas comprise dans les intervalles de validité de l'horaire
          if (!isInRange) {
            return 0;
          }
          return 1;
        } else {
          return -1;
        }
      } catch (error) {
        console.error(error);
        return -2;
      }
    });
  }

  //Ajoute un nouvel UceDay, inclue la récupération des données du serveur et l'appel des fonctions de calcul.
  //Trois cas, l'uceso est sauvegardé sur le serveur -> On le charge; l'uceso n'est pas sauvegardé-> On le calcule; L'uceso est sauvegardé mais on souhaite le recalculer.
  async addUceDay(
    date: moment.MomentInput,
    reload: boolean,
    uceDay: Array<Uceday>
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      (async () => {
        let ucedayDate = moment(date).format("YYYYMMDD");
        let csvDate = moment(date).format("M/D/YYYY");

        //Récupère depuis le serveur les fichiers NOP et Besoin FMP
        const [getNop, getBesoinFMP] = await getCsvFile(csvDate);

        var nop: any[];
        var besoinFMP: any[];
        try {
          nop = JSON.parse((await getNop).data.response);
        } catch (error: any) {
          nop = [];
        }
        try {
          besoinFMP = JSON.parse((await getBesoinFMP).data.response);
        } catch (error: any) {
          besoinFMP = [];
        }

        //Vérification de l'existance d'un fichier sauvegardé pour la date demandée
        if (uceDay.find((day: any) => day.date === ucedayDate) === undefined) {
          //Chargement du fichier existant, ou récupération des données nécessaires pour la création du fichier uceDay
          try {
            let getUceday = this.retrieveData(ucedayDate);

            //Récupération d'un tableau de 2 élements : d'une part le uceDay enregistré situé en position 0 du tableau, d'autre part les agents non attribués sur cette date
            await getUceday.then(async (uceDay: any) => {
              if (uceDay[0]) {
                this.nop = uceDay[0].nop;
                this.besoinFMP = uceDay[0].besoinFMP;
                this.salto = uceDay[0].salto;
                this.dateCalc = uceDay[0].dateCalc;
                this.uceso = uceDay[0].uceso;
                this.date = uceDay[0].date;
                this.horaire = uceDay[0].horaire;
                this.personnel = uceDay[0].personnel;
                this.precPersonnel = uceDay[0].precPersonnel;

                this.listOfUnassignedAgents = uceDay[1];
                this.calculUceso(
                  uceDay[0].horaire,
                  uceDay[0].personnel,
                  uceDay[0].precPersonnel,
                  uceDay[0].date
                );
                resolve(3);
              } else {
                // Si aucun uceday du nom existe
                const result = await this.repartitionAgent(
                  ucedayDate,
                  nop,
                  besoinFMP
                );
                resolve(result);
              }
            });
          } catch (error) {
            console.error(error);
            resolve(-1);
          }
        } else if (!reload) {
          const result = await this.repartitionAgent(
            ucedayDate,
            nop,
            besoinFMP
          );
          sendLogs(
            "Recalculate",
            "Fichier uceday du " +
              moment(this.date, "YYYYMMDD").format("DD/MM/YYYY")
          );

          if (result === 1 || result === 0) {
            resolve(2);
          } else {
            resolve(result);
          }
        } else {
          resolve(-3);
        }
      })();
    });
  }

  //Recalcule les uceso lorsque l'horaire attribué est modifié
  async horaireChange(jour: string) {
    return new Promise((resolve) => {
      (async () => {
        if (jour) {
          const apiObject = {
            terminaison: "getHoraire",
            title: jour,
          };
          const newHoraireData = await apiCall(apiObject);
          let newHoraire = new FHoraire(
            JSON.parse(newHoraireData.data.response)
          );

          let date = this.date;

          this.horaire = newHoraire;

          const retour = await this.repartitionAgent(
            date,
            this.nop,
            this.besoinFMP,
            newHoraire
          );
          if (retour < 0) {
            resolve(retour);
          }

          this.dateCalc = moment().format("DD/MM/YYYY");

          var isInRange = false;

          newHoraire.validite.forEach((validite: string[]) => {
            if (
              moment(this.date, "YYYYMMDD").isBetween(
                moment(validite[0], "DD/MM/YYYY"),
                moment(validite[1], "DD/MM/YYYY"),
                undefined,
                "[]"
              )
            ) {
              isInRange = true;
            }
          });
          sendLogs(
            "Change",
            moment(this.date, "YYYYMMDD").format("DD/MM/YYYY") +
              " ; Nouveau fichier horaire -> " +
              newHoraire.titre
          );
          if (retour === -2) {
            resolve(retour);
          } else if (!isInRange) {
            resolve(0);
          } else {
            resolve(retour);
          }
        } else resolve(0);
      })();
    });
  }

  // Trie et regroupe les contrôleurs par jour
  traitementPersonnel(
    personnel: personnel,
    date: string
  ): Array<Array<agentAffecte>> {
    if (!personnel || !personnel.donnees) return [];

    let sortedPersonnel = personnel.donnees;
    // Extraction des contrôleurs de la nuit de la veille
    if (personnel.date !== date) {
      sortedPersonnel = sortedPersonnel.filter(
        (person: any) => person.horaire === "N"
      );
    }

    let repartitionPersonnel: Array<Array<agentAffecte>> = [];

    //Tri des agents en fonction de leur jour
    sortedPersonnel.sort((person_a: agentAffecte, person_b: agentAffecte) => {
      if (person_a.horaire > person_b.horaire) {
        return 1;
      }
      if (person_b.horaire > person_a.horaire) {
        return -1;
      }
      return 0;
    });

    //On récupère le nom du premier jour
    let jour = sortedPersonnel[0].horaire;
    // Tableau temporaire regroupant tous les agents du même jour
    let tempPersonnel: Array<agentAffecte> = [];

    //On regroupe les agents par jour
    sortedPersonnel.forEach((personnel: agentAffecte) => {
      if (personnel.horaire !== jour) {
        jour = personnel.horaire;
        if (tempPersonnel) repartitionPersonnel.push(tempPersonnel);
        tempPersonnel = [];
      }
      tempPersonnel.push(personnel);
    });

    //Ajout du dernier jour
    if (tempPersonnel.length !== 0) repartitionPersonnel.push(tempPersonnel);

    return repartitionPersonnel;
  }

  //Récupère la liste des fichiers uceDay sur le serveur, vérifie si le uceDay voulu existe et si oui la fonction va le récupèrer sur le bucket et récupère la liste complète des contrôleurs
  async retrieveData(date: string) {
    const apiListUcedayObject = {
      terminaison: "listUceday",
    };
    let ucedayList = await apiCall(apiListUcedayObject);

    let uceList = JSON.parse(ucedayList.data.response).value;

    // Vérification de la présence du fichier du jour dans la liste
    if (uceList && uceList.includes(date + ".json")) {
      const apiGetUcedayObject = {
        terminaison: "getUceday",
        date: date,
      };
      const uceDayData = await apiCall(apiGetUcedayObject);
      const uceDay = JSON.parse(uceDayData.data.response);

      if (uceDay.listOfUnassignedAgents !== undefined) {
        uceDay.horaire = new FHoraire(uceDay.horaire);
        return [uceDay, uceDay.listOfUnassignedAgents, uceDay.salto];
      } else {
        const apiGetPersonnalInfoObject = {
          terminaison: "getPersonnalInfo",
          date: date,
        };

        //Récupération des informations globales sur les agents
        let personnelDataInfos = await apiCall(apiGetPersonnalInfoObject);

        let personnelInfos = JSON.parse(personnelDataInfos.data.response)
          .personnelInfo.employe;
        // On fusionne les différents jour de l'horaire dans un seul tableau pour ensuite filtrer la liste du personnel
        let agentActif: Array<agentAffecte> = [];
        for (let index = 0; index < uceDay.personnel.length; index++) {
          const jour = uceDay.personnel[index];
          agentActif = agentActif.concat(jour);
        }

        //Suppression de tous les contrôlleurs en poste (Jour choisi + NVeille), on ne garde que ceux qui ne sont pas dans les équipes
        const agentNonActif = personnelInfos.filter(
          (personnel: agentAffecte) =>
            agentActif.findIndex(
              (agent) => agent.matricule === personnel.matricule
            ) === -1 &&
            uceDay.precPersonnel[0].findIndex(
              (agent: { matricule: string }) =>
                agent.matricule === personnel.matricule
            ) === -1
        );
        //Création du fichier horaire
        uceDay.horaire = new FHoraire(uceDay.horaire);
        return [uceDay, agentNonActif, uceDay.salto];
      }
    } else {
      return 0;
    }
  }

  //Permet de sauvegarder le uceday sur le serveur
  async saveUceDay(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const apiObject = {
            terminaison: "postUceday",
            date: this.date,
            data: JSON.stringify({
              besoinFMP: this.besoinFMP,
              date: this.date,
              dateCalc: this.dateCalc,
              horaire: this.horaire,
              nop: this.nop,
              personnel: this.personnel,
              precPersonnel: this.precPersonnel,
              listOfUnassignedAgents: this.listOfUnassignedAgents,
              salto: this.salto,
              uceso: this.uceso,
            }),
            user:
              AuthenticationService.user.user.firstname +
              " " +
              AuthenticationService.user.user.name,
            details: this.date,
          };
          await apiCall(apiObject);
          sendLogs(
            "Save",
            "Fichier uceday du " +
              moment(this.date, "YYYYMMDD").format("DD/MM/YYYY")
          );
          resolve(true);
        } catch (error) {
          console.error(error);
          resolve(false);
        }
      })();
    });
  }

  repartitionSousJour(
    agentsOfJour: Array<agentAffecte>,
    jour: jour,
    recyclageExtension: Array<recycleur>,
    cds: any,
    repartitionJE: any
  ) {
    const cdsOfDay = cds.find((agent: any) => agent.jour === jour.nom);
    // Mise à undefined de l'ensemble des numéros de sous-jours pour pouvoir ensuite les attribuer
    agentsOfJour.forEach((agent: any) => (agent.horaireNum = undefined));

    //Tableau indiquant la répartition des extensions dans le jour
    let extensionDistribution: Array<number> = new Array(jour.sousJours.length);
    extensionDistribution = extensionDistribution.fill(0);

    // Attibution des JE
    if (jour.nom === "JE") {
      const newJE = repartitionJE;
      let repPeriodesSJ = jour.sousJours.map((sousJour) => {
        // On formate pour correspondre au retour de l'API
        return (
          sousJour.periodes[0].debut[0] +
          sousJour.periodes[0].debut[1] +
          "h" +
          sousJour.periodes[0].debut[3] +
          sousJour.periodes[0].debut[4]
        );
      });

      newJE.forEach((je: any) => {
        const startHour = je.jour.substring(3);
        const indexSJ = repPeriodesSJ.findIndex((value) => value === startHour);
        const newAgent = agentsOfJour.find(
          (agent) => agent.matricule === je.pc
        );
        if (newAgent !== undefined && indexSJ !== -1) {
          if (newAgent.absence === undefined) {
            newAgent.horaireNum = indexSJ;
            newAgent.isExtension = true;
            //On met à vide le sous-jours utilisés pour qu'il ne puisse plus être attribué
            repPeriodesSJ[indexSJ] = "";
          } else {
            this.warning.push(
              "L'agent " + je.pc + " en JE, est absent sur octime !"
            );
          }
        } else if (newAgent === undefined) {
          this.warning.push(
            "L'agent " + je.pc + " en JE, est absent sur le site de recyclage !"
          );
          //On met à vide le sous-jours utilisés pour qu'il ne puisse plus être attribué
          repPeriodesSJ[indexSJ] = "";
        }
      });

      const noAttribuateAgent = agentsOfJour.filter(
        (agent) => agent.horaireNum === undefined && agent.absence === undefined
      );

      noAttribuateAgent.forEach((newAgent) => {
        const newHoraireNum = repPeriodesSJ.findIndex(
          (element) => element.length !== 0
        );

        if (newHoraireNum === -1) {
          repPeriodesSJ = jour.sousJours.map((sousJour) => {
            // On formate pour correspondre au retour de l'API
            return (
              sousJour.periodes[0].debut[0] +
              sousJour.periodes[0].debut[1] +
              "h" +
              sousJour.periodes[0].debut[3] +
              sousJour.periodes[0].debut[4]
            );
          });
        }
        newAgent.horaireNum = newHoraireNum === -1 ? 0 : newHoraireNum;
        newAgent.isExtension = true;
        repPeriodesSJ[newHoraireNum === -1 ? 0 : newHoraireNum] = "";
        // this.warning.push(
        //   "L'agent " +
        //     newAgent.nom +
        //     " n'est pas attribué sur le recyclage en JE"
        // );
      });
    } else {
      //On check si il y a un sous-jour CDS et on l'attribue si l'agent est présent ce jour la
      jour.sousJours.forEach((sJ: any, index: number) => {
        if (sJ.cds && cdsOfDay !== undefined) {
          const p = agentsOfJour.find(
            (agent: any) =>
              isNaN(agent.horaireNum) && agent.matricule === cdsOfDay.cds
          );
          if (p !== undefined) {
            p.horaireNum = index;
            p.cds = true;
          }
        } else if (sJ.cds && cdsOfDay === undefined) {
          this.warning.push(
            "Le sous-jours " + jour.nom + " n'a pas de CDS attribué"
          );
        }
      });

      //Récupère le nombre d'extension du jour
      let extensionNumber =
        jour.sousJours
          .map((x: sousJour) => x.nPExt)
          .reduce(
            (accumulator: number, currentValue: number) =>
              accumulator + currentValue,
            0
          ) || 0;
      //Récupère le nombre d'agent disponible
      const nbrAgentsAvailable = agentsOfJour.filter(
        (agent: agentAffecte) => agent.absence === undefined
      ).length;

      //Récupère le nombre de sousJours n'étant pas des extensions
      const nbrSousJours = jour.sousJours.length - extensionNumber;

      //Permet de suivre les attributions des extensions au cours du processus
      const reelExtesionAtribution: number[] = [];
      jour.sousJours.forEach(() => {
        reelExtesionAtribution.push(0);
      });

      let compteur = 0;
      let compteurExt = 0;

      if (nbrAgentsAvailable >= extensionNumber) {
        extensionDistribution = extensionDistribution.map(
          (x, i) => jour.sousJours[i].nPExt
        );
      } else {
        for (let j = 0; j < nbrAgentsAvailable; j++) {
          extensionDistribution[j % extensionNumber]++;
        }
      }
      const currentAttrib = extensionDistribution.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      if (nbrAgentsAvailable > currentAttrib) {
        for (let j = currentAttrib; j < nbrAgentsAvailable; j++) {
          extensionDistribution[
            extensionNumber +
              ((j - extensionNumber) %
                (extensionDistribution.length - extensionNumber))
          ]++;
        }
      }

      compteur = 0;

      // Récupère les recycleurs du jour
      const recyclage =
        !this.horaire.strike && extensionNumber !== 0
          ? recyclageExtension.filter(
              (recycleur: recycleur) => recycleur.jour === jour.nom
            )
          : [];

      //On attribue les extensions aux recycleurs si l'horaire n'est pas de grève
      if (!this.horaire.strike) {
        let indexExtension = 0;
        if (
          jour.ExtPcMini <
            agentsOfJour.filter(
              (personne: any) => personne.absence === undefined
            ).length ||
          jour.ExtPcMini === -1
        ) {
          recyclage.forEach((agentRecyclage: recycleur, index: number) => {
            if (agentRecyclage.pc !== "NIL") {
              const p = agentsOfJour.find(
                (agent: any) =>
                  agent.matricule === recyclage[index].pc &&
                  isNaN(agent.horaireNum) &&
                  agent.absence === undefined
              );
              if (p !== undefined) {
                p.horaireNum = compteur;
                p.isExtension = true;
                reelExtesionAtribution[indexExtension]++;

                if (
                  compteur + 1 < jour.sousJours.length &&
                  extensionDistribution[indexExtension] ===
                    reelExtesionAtribution[indexExtension]
                ) {
                  compteur++;
                  indexExtension++;
                }
                compteurExt++;
              }
            } else {
              if (compteur + 1 < jour.sousJours.length) {
                compteur++;
              }
            }
          });
        } else {
          compteur = extensionNumber;
          compteurExt = extensionNumber;
        }
      }

      //On récupère le reste des agents disponible
      const noExtensionAgent = agentsOfJour.filter(
        (agent: agentAffecte) =>
          agent.absence === undefined && isNaN(agent.horaireNum)
      );
      //Pour chacun d'entre eux on leur affecte leur sous-jour
      noExtensionAgent.forEach((agent: agentAffecte) => {
        const retour = affectSJ(
          compteur,
          recyclage,
          nbrSousJours,
          extensionNumber,
          agent,
          compteurExt,
          jour
        );
        compteur = retour[0];
        compteurExt = retour[1];
        agent = retour[2];
      });
    }
  }

  //Attribue les sous-jour au personnel selon leur jour
  async attributionPersonnel(
    personnel: Array<Array<agentAffecte>>,
    precPersonnel: Array<Array<agentAffecte>>,
    horaire: FHoraire,
    date: string
  ) {
    const agents = personnel;

    //Récupère les extensions du jour et de la veille
    const recyclageAsync = await getExtInRecyclage(date, personnel, false);
    const recyclageVeilleAsync = await getExtInRecyclage(
      moment(date, "YYYYMMDD").subtract(1, "day").format("YYYYMMDD"),
      personnel,
      true
    );

    //Récupère les CDS du jour et de la veille
    const cdsAsync = await getCDSInRecyclage(date, personnel, false);
    const cdsVeilleAsync = await getCDSInRecyclage(
      moment(date, "YYYYMMDD").subtract(1, "day").format("YYYYMMDD"),
      personnel,
      true
    );

    const [recyclage, recyclageVeille, cds, cdsVeille] = await Promise.all([
      recyclageAsync,
      recyclageVeilleAsync,
      cdsAsync,
      cdsVeilleAsync,
    ]);

    // Si il n'y a pas de JE désigné, il fera l'attribution de manière automatique en prenant le premier puis le second,...
    let repartitionJE: any = [];
    try {
      // On ne le met pas dans le promise.all pour mieux traiter les erreurs si aucun JE n'est déclaré
      const apiObject = {
        terminaison: "je",
        date: moment(date, "YYYYMMDD").format("YYYY-MM-DD"),
        user:
          AuthenticationService.user.user.firstname +
          " " +
          AuthenticationService.user.user.name,
      };
      const newRepartitionJE = await apiCall(apiObject);
      repartitionJE = JSON.parse(newRepartitionJE.data.response);
    } catch (error) {
      console.error(error);
    }

    //Le [1] regroupe les alertes concernant les agents qui ont été désigné mais qui sont absents soit en recyclage soit en CDS
    if (
      recyclage[1].length !== 0 ||
      recyclageVeille[1].length !== 0 ||
      cds[1].length !== 0 ||
      cdsVeille[1].length !== 0
    ) {
      this.warning = recyclage[1];
      recyclageVeille[1].forEach((difference: string) => {
        this.warning.push(difference);
      });
      cds[1].forEach((difference: string) => {
        this.warning.push(difference);
      });
      cdsVeille[1].forEach((difference: string) => {
        this.warning.push(difference);
      });
    }
    const recyclageExtension = recyclage[0];
    const agentsBeforeDay = precPersonnel;

    //On vérifie que nos données ne sont pas vide
    if (
      !personnel ||
      !precPersonnel ||
      !horaire ||
      !horaire.jours ||
      horaire.jours.length === 0
    )
      return [null, null];

    //On boucle sur l'ensemble des jours de l'horaire
    horaire.jours.forEach((jour: any) => {
      if (
        jour.nomOctime &&
        jour.sousJours &&
        jour.sousJours.length !== 0 &&
        jour.nom !== "NVeille"
      ) {
        const agentsOfJour: Array<agentAffecte> =
          agents.find((agent: any) => agent[0].horaire === jour.nomOctime) ||
          [];
        if (agentsOfJour !== undefined) {
          this.repartitionSousJour(
            agentsOfJour,
            jour,
            recyclageExtension,
            cds[0],
            repartitionJE
          );
        }
      } else if (jour.nom === "NVeille") {
        const agentsOfJour: Array<agentAffecte> =
          agentsBeforeDay.find((x: any) => x[0].horaire === jour.nomOctime) ||
          [];
        if (agentsOfJour !== undefined) {
          this.repartitionSousJour(
            agentsOfJour,
            jour,
            recyclageVeille[0],
            cdsVeille[0],
            repartitionJE
          );
        }
      }
    });

    agents.forEach((sousJours: Array<agentAffecte>, index: number) => {
      agents[index] = personnalSort(sousJours);
    });

    agentsBeforeDay.forEach((sousJours: Array<agentAffecte>, index: number) => {
      agentsBeforeDay[index] = personnalSort(sousJours);
    });

    return [agents, agentsBeforeDay];
  }

  //Calcule les uceso en fonction des attributions du personnel
  calculUceso(
    horaire: FHoraire,
    personnel: Array<Array<agentAffecte>>,
    precPersonnel: Array<Array<agentAffecte>>,
    date: string
  ): Array<Array<Array<number>>> {
    const [ucesoFinal, ucesoUTCFinal]: any = calculUceso(
      horaire.jours,
      personnel,
      precPersonnel,
      date
    );

    return this.writeUceso(ucesoFinal, ucesoUTCFinal, date);
  }

  //Permet de metttre absent/présent une ou plusieurs personnes
  handleAbsence(personnelSelected: Array<string>): void {
    var logs: Array<log> = [];
    personnelSelected.forEach((personnel: string) => {
      //étape 1, trouver la personne
      let newAgent: any;
      this.personnel.forEach((agents: Array<agentAffecte>) => {
        if (newAgent === undefined) {
          newAgent = agents.find(
            (agent: agentAffecte) => agent.matricule === personnel
          );
        }
      });
      if (newAgent === undefined) {
        newAgent = this.precPersonnel[0].find(
          (agent: agentAffecte) => agent.matricule === personnel
        );
      }
      //Person pointe maintenant vers l'objet personne
      //étape 2, modifier la présence de la personne
      //On vérifie si la personne est à l'origine absente ou présente
      if (!newAgent.absence) {
        newAgent.absence = "ABS";
        newAgent.isExtension = false;
        newAgent.horaireNum = undefined;
        newAgent.cds = undefined;
        logs.push({
          action: "Update",
          details:
            "Mise à absent de " +
            newAgent.matricule +
            " le " +
            moment(this.date, "YYYYMMDD").format("DD/MM/YYYY"),
          id: new Date().getTime().toString(),
        });
      } else {
        newAgent.absence = undefined;
        var newJour: Array<agentAffecte> = [];

        this.personnel.forEach((jour: Array<agentAffecte>) => {
          if (jour[0].horaire === newAgent.horaire) {
            newJour = newJour.concat(jour);
          }
        });

        //Nombre de sous-jours en extension
        var nbrExt: number = 0;
        //Nombre maximum de sous-jours
        var max: number = 0;

        //On détermine le nombre d'extension déjà attribuées pour l'horaire
        this.horaire.jours.forEach((jour: jour) => {
          if (newAgent.veille) {
            if (jour.nom === newAgent.horaire) {
              max = jour.sousJours.length;
              nbrExt = jour.sousJours.filter(
                (sousJour: sousJour) => sousJour.nPExt > 0
              ).length;
            }
          } else {
            if (
              jour.nom === newAgent.horaire ||
              jour.nomOctime === newAgent.horaire
            ) {
              max = jour.sousJours.length;
              nbrExt = jour.sousJours.filter(
                (sousJour: sousJour) => sousJour.nPExt > 0
              ).length;
            }
          }
        });

        //On va maintenant déterminer le sous-jours le plus adapaté pour ajouter l'agent

        //Permet de compter le nombre d'occurence pour chacun des sous-jours
        var occurence = new Array(max).fill(0);

        newJour.forEach((personnel) => {
          if (
            personnel.absence === undefined &&
            personnel.matricule !== newAgent.matricule
          ) {
            occurence[personnel.horaireNum] =
              occurence[personnel.horaireNum] + 1;
          }
        });

        var min = Math.min.apply(null, occurence);
        var indexMin = occurence.indexOf(min);

        //liste l'ensemble des plus petites valeurs (index 0 uniquement si il n'y a q'une seule plus petite valeure)
        var listMin: Array<number> = [];

        occurence.forEach((valeur, index) => {
          if (valeur === min && index >= nbrExt) {
            listMin.push(index);
          }
        });

        //On détermine le nouveau sous-jour de la personne en fonction des attributions déjà faite

        if (indexMin < nbrExt) {
          if (min >= 1 && listMin.length !== 0) {
            newAgent.horaireNum = listMin[0];
            newAgent.isExtension = false;
            newAgent.cds = false;
          } else if (min >= 1 && listMin.length === 0) {
            newAgent.horaireNum = nbrExt;
            newAgent.isExtension = false;
            newAgent.cds = false;
          } else {
            newAgent.horaireNum = indexMin;
            newAgent.isExtension = true;
            newAgent.cds = false;
          }
        } else {
          newAgent.horaireNum = indexMin;
          newAgent.isExtension = false;
          newAgent.cds = false;
        }

        logs.push({
          action: "Update",
          details:
            "Mise à présent de " +
            newAgent.matricule +
            " le " +
            moment(this.date, "YYYYMMDD").format("DD/MM/YYYY"),
          id: new Date().getTime().toString(),
        });
      }
    });

    //On trie les listes
    this.personnel.forEach((sousJours: any, index: number) => {
      this.personnel[index] = personnalSort(sousJours);
    });
    this.precPersonnel.forEach((sousJours: any, index: number) => {
      this.precPersonnel[index] = personnalSort(sousJours);
    });

    this.uceso = this.calculUceso(
      this.horaire,
      this.personnel,
      this.precPersonnel,
      this.date
    )!;

    logs.forEach((log: log) => sendLogs(log.action, log.details));
  }

  //Change l'attributions des personnes séléctionnées
  changeAttribution(
    selected: Array<string>,
    jour: string,
    sousJour: string
  ): void {
    var logs: Array<log> = [];
    let indexNewHoraire = -1;
    selected.forEach((element: any) => {
      //étape 1, trouver la personne
      var indexfind: number = -1;
      let newAgent: any;
      let insertion: boolean = false;
      var listExtension = [];

      //Recherche l'agent parmi ceux du jour
      this.personnel.forEach((agents: any[], index: number) => {
        if (jour === agents[0].horaire) {
          indexNewHoraire = index;
        }
        if (newAgent === undefined) {
          newAgent = agents.find((agent, index) => agent.matricule === element);
          if (newAgent !== undefined) {
            indexfind = agents.indexOf(newAgent);
            //suppresion de l'agent trouvé
            agents.splice(indexfind, 1);
            listExtension = agents.find(
              (agent: any) => agent.isExtension === true
            );
          }
        }
      });

      // Si l'agent n'a pas été trouvé dans les agents du jour, on regarde dans ceux de la veille
      if (newAgent === undefined) {
        newAgent = this.precPersonnel[0].find(
          (personne: { matricule: any }) => personne.matricule === element
        );

        if (newAgent !== undefined) {
          listExtension = this.precPersonnel[0].find(
            (personne: any) => personne.isExtension === true
          );

          indexfind = this.precPersonnel[0].indexOf(newAgent);
          //Suppresion de l'agent trouvé
          this.precPersonnel[0].splice(indexfind, 1);
        }
      }

      //newAgent pointe maintenant vers l'objet personne
      //étape 2, modifier le sous-jour de la personne en vérifiant si c'est un sous-jour de veille
      if (jour.indexOf("Veille") === -1) {
        newAgent.horaire = jour;
        newAgent.horaireNum = parseInt(sousJour);
        newAgent.absence = undefined;
        newAgent.veille = false;
      } else {
        newAgent.horaire = jour.replace("Veille", "");
        newAgent.horaireNum = parseInt(sousJour);
        newAgent.absence = undefined;
        newAgent.veille = true;
      }

      if (listExtension === undefined) {
        listExtension = [];
      }
      if (listExtension.length >= jour) {
        newAgent.isExtension = true;
      } else {
        var isExtension = false;

        this.horaire.jours.forEach((jourofHoraire: jour) => {
          if (jourofHoraire.nom === jour || jourofHoraire.nomOctime === jour) {
            if (jourofHoraire.sousJours[parseInt(sousJour)].nPExt > 0) {
              isExtension = true;
            }
          }
        });

        newAgent.isExtension = isExtension;
      }

      //On regarde si le sous-jour est de la veille ou non (si il est de la veille cela renverra -1)
      if (indexNewHoraire !== -1) {
        this.personnel[indexNewHoraire].forEach(
          (agent: agentAffecte, index: number) => {
            if (agent.horaireNum >= parseInt(sousJour) && !insertion) {
              this.personnel[indexNewHoraire].splice(index, 0, newAgent);
              insertion = true;
            }
            if (agent.horaireNum === undefined && !insertion) {
              this.personnel[indexNewHoraire].splice(index, 0, newAgent);
              insertion = true;
            }
          }
        );
        if (!insertion) {
          this.personnel[indexNewHoraire].push(newAgent);
        }
      } else {
        this.precPersonnel[0].forEach((agent: agentAffecte, index: number) => {
          if (agent.horaireNum >= parseInt(sousJour) && !insertion) {
            this.precPersonnel[0].splice(index, 0, newAgent);
            insertion = true;
          }
          if (agent.horaireNum === undefined && !insertion) {
            this.precPersonnel[0].splice(index, 0, newAgent);
            insertion = true;
          }
        });
        if (!insertion) {
          this.precPersonnel[0].push(newAgent);
        }
      }
      logs.push({
        action: "Update",
        details:
          newAgent.matricule +
          " ; Nouvelle attribution -> " +
          newAgent.horaire +
          " " +
          (newAgent.horaireNum + 1),
        id: new Date().getTime().toString(),
      });
    });

    //on recalcule les uceso
    this.uceso = this.calculUceso(
      this.horaire,
      this.personnel,
      this.precPersonnel,
      this.date
    )!;
    logs.forEach((log: log) => sendLogs(log.action, log.details));
  }

  //Permet d'ajouter une nouvelle personne à un horaire à partir de la liste des agents non affectés
  addPerson(
    personnes: any,
    selected: Array<string>,
    positionHoraire: string
  ): void {
    var logs: Array<log> = [];
    let indexHoraire: number = -1;
    for (let index = 0; index < this.personnel.length; index++) {
      const agent = this.personnel[index][0];
      if (agent.horaire === positionHoraire) {
        indexHoraire = index;
      }
    }
    if (indexHoraire !== -1) {
      for (let i = 0; i < personnes.length; i++) {
        const agent = personnes[i];
        this.personnel[indexHoraire].push(agent);
      }
    } else if (positionHoraire.indexOf("Veille") === -1) {
      let newPers = [];
      for (let i = 0; i < personnes.length; i++) {
        const agent = personnes[i];
        newPers.push(agent);
      }
      this.personnel.push(newPers);
    } else {
      for (let i = 0; i < personnes.length; i++) {
        const agent = personnes[i];
        this.precPersonnel[0].push(agent);
      }
    }

    selected.forEach((matricule: string) => {
      //étape 1, trouver la personne
      let newAgent: any;
      this.personnel.forEach((tab: any[]) => {
        if (newAgent === undefined) {
          newAgent = tab.find(
            (agent: agentAffecte) => agent.matricule === matricule
          );
        }
      });
      if (newAgent === undefined) {
        newAgent = this.precPersonnel[0].find(
          (agent: agentAffecte) => agent.matricule === matricule
        );
      }
      if (positionHoraire.indexOf("Veille") !== -1) {
        newAgent.veille = true;
        newAgent.horaire = newAgent.horaire.replace("Veille", "");
      }

      //newAgent pointe maintenant vers l'objet personne de la copie d'uceDay
      newAgent.absence = undefined;
      var listOfUnassignedAgents: Array<agentAffecte> = [];

      this.personnel.forEach((jour: Array<agentAffecte>) => {
        if (jour[0].horaire === newAgent.horaire) {
          listOfUnassignedAgents = listOfUnassignedAgents.concat(jour);
        }
      });

      var nbrExt: number = 0;
      var max: number = 0;

      //On détermine le nombre d'extension pour l'horaire

      this.horaire.jours.forEach((jour: jour) => {
        if (newAgent.veille) {
          if (jour.nom === newAgent.horaire) {
            max = jour.sousJours.length;
            nbrExt = jour.sousJours.filter(
              (sousJour: sousJour) => sousJour.nPExt > 0
            ).length;
          }
        } else {
          if (
            jour.nom === newAgent.horaire ||
            jour.nomOctime === newAgent.horaire
          ) {
            max = jour.sousJours.length;
            nbrExt = jour.sousJours.filter(
              (sousJour: sousJour) => sousJour.nPExt > 0
            ).length;
          }
        }
      });

      var occurence = new Array(max).fill(0);

      listOfUnassignedAgents.forEach((personnel) => {
        if (
          personnel.absence === undefined &&
          personnel.matricule !== newAgent.matricule
        ) {
          occurence[personnel.horaireNum] = occurence[personnel.horaireNum] + 1;
        }
      });

      var min = Math.min.apply(null, occurence);
      var indexMin = occurence.indexOf(min);

      var listMin: Array<number> = [];

      occurence.forEach((valeur, index) => {
        if (valeur === min && index >= nbrExt) {
          listMin.push(index);
        }
      });

      //On détermine le nouveau sous-jour de la personne en fonction des attributions déjà faite

      if (indexMin < nbrExt) {
        if (min >= 1 && listMin.length !== 0) {
          newAgent.horaireNum = listMin[0];
          newAgent.isExtension = false;
          newAgent.cds = false;
        } else if (min >= 1 && listMin.length === 0) {
          newAgent.horaireNum = nbrExt;
          newAgent.isExtension = false;
          newAgent.cds = false;
        } else {
          newAgent.horaireNum = indexMin;
          newAgent.isExtension = true;
          newAgent.cds = false;
        }
      } else {
        newAgent.horaireNum = indexMin;
        newAgent.isExtension = false;
        newAgent.cds = false;
      }

      logs.push({
        action: "Add",
        details:
          "Ajout de " +
          newAgent.matricule +
          " ; Attribution -> " +
          newAgent.horaire +
          " " +
          (newAgent.horaireNum + 1),
        id: new Date().getTime().toString(),
      });
    });

    //Fonction de trie par ordre de sous jours
    this.personnel.forEach((sousJours: Array<agentAffecte>, index: number) => {
      this.personnel[index] = personnalSort(sousJours);
    });
    this.precPersonnel.forEach(
      (sousJours: Array<agentAffecte>, index: number) => {
        this.precPersonnel[index] = personnalSort(sousJours);
      }
    );

    this.uceso = this.calculUceso(
      this.horaire,
      this.personnel,
      this.precPersonnel,
      this.date
    )!;

    let newListOfUnassignedAgents = this.listOfUnassignedAgents.filter(
      (personnel: agent) =>
        personnes.findIndex(
          (agent: agentAffecte) => agent.matricule === personnel.matricule
        ) === -1
    );
    this.listOfUnassignedAgents = newListOfUnassignedAgents;

    logs.forEach((log: log) => {
      sendLogs(log.action, log.details);
    });
  }

  //Fonction qui permet de vérifier si il y a collision
  checkIfTimesOverlap(start: string, end: string, timeToCompare: string) {
    const startMoment = moment(start, "HH:mm");
    const endMoment = moment(end, "HH:mm");

    const timeToCompareMoment = moment(timeToCompare, "HH:mm");

    return timeToCompareMoment.isBetween(startMoment, endMoment);
  }

  //Récupère la liste des ucesos pour la journée, de 0h00 à 26h00, minute par minute. Créé des listes de 1440 éléments
  //Renvoie 2 listes : uceso heure locale et uceso UTC
  writeUceso(
    ucesoFinal: any,
    ucesoUTCFinal: any,
    date: string
  ): Array<Array<Array<number>>> {
    //On prépare la structure du fichier salto
    const jsonUceso: salto = {
      ConfigurationUCESO: {
        Date: date.substring(2),
        Centre: "LFEE",
        UCESO: [] as any[],
      },
    };

    var lastStart = moment.tz(`${date}T0000`, "Europe/Paris");
    var lastUceso = ucesoUTCFinal[0][0];

    //On va découper en plusieurs intervalle de même valeur d'uceso
    ucesoUTCFinal.forEach((ucesoUTC: any, index: number) => {
      // Pour gérer le changement d'heure on va recalculer la date et l'heure
      var heure = `${Math.floor(index / 60)}`;
      var min = `${Math.floor(index % 60)}`;

      if (Math.floor(index / 60) < 10) {
        heure = "0" + heure;
      }
      if (Math.floor(index % 60) < 10) {
        min = "0" + min;
      }
      const newStart = moment.tz(`${date}T${heure}${min}`, "Europe/Paris");

      //On fait une exception pour la deuxième valeur pour éviter la redondance
      if (index === 1) {
        if (
          lastUceso !== ucesoUTC[0] &&
          lastStart.format("HH:mm") === newStart.format("HH:mm")
        ) {
          lastUceso = ucesoUTC[0];
          lastStart = newStart;
        }
      } else if (lastUceso !== ucesoUTC[0]) {
        jsonUceso.ConfigurationUCESO.UCESO.push({
          Plage: {
            Debut: lastStart.format("HH:mm"),
            NombreUCESO: lastUceso,
            Fin: newStart.subtract(1, "minutes").format("HH:mm"),
          },
        });
        lastUceso = ucesoUTC[0];
        lastStart = newStart;
      }
    });

    jsonUceso.ConfigurationUCESO.UCESO.push({
      Plage: {
        Debut: lastStart.format("HH:mm"),
        NombreUCESO: lastUceso,
        Fin: ucesoUTCFinal[ucesoUTCFinal.length - 1][2].format("HH:mm"),
      },
    });

    //Vérifie si il y a des collisions dans le fichier UCESO
    let noCollision = true;
    jsonUceso.ConfigurationUCESO.UCESO.forEach((plage, index) => {
      jsonUceso.ConfigurationUCESO.UCESO.forEach(
        (plageToCompare, indexToCompare) => {
          if (index !== indexToCompare) {
            const start1 = plage.Plage.Debut;
            const end1 = plage.Plage.Fin;

            const start2 = plageToCompare.Plage.Debut;
            const end2 = plageToCompare.Plage.Fin;
            // si collision on donne l'heure de fin
            if (this.checkIfTimesOverlap(start1, end1, start2)) {
              jsonUceso.ConfigurationUCESO.UCESO[indexToCompare].Plage.Debut =
                end1;
              noCollision = false;
            }
            if (this.checkIfTimesOverlap(start1, end1, end2)) {
              jsonUceso.ConfigurationUCESO.UCESO[indexToCompare].Plage.Fin =
                end1;
              noCollision = false;
            }
          }
        }
      );
    });

    //créé le fichier pour salto
    try {
      const ucesoSaltoXML = OBJtoXML(jsonUceso);
      this.salto = ucesoSaltoXML;

      //Si une collision a été détecté on envoie un log
      if (!noCollision) {
        sendLogs(
          "Collision",
          "Fichier salto du " +
            moment(this.date, "YYYYMMDD").format("DD/MM/YYYY")
        );
        console.error(
          "Des collisions sont arrivées lors de la création du fichier Salto"
        );
      }
    } catch (error) {
      console.error(error);
    }
    return [ucesoFinal, ucesoUTCFinal];
  }
}
export default Uceday;
