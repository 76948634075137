//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//Composant principal permettant de gérer l'ensemble de l'application une fois que l'utilisateur est connecté/
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
import "../CSS/App.css";
import NavBar from "../Composant/NavBar";
import Uceso from "./Uceso";
import Horaire from "./Horaire";
import { useState } from "react";
import moment from "moment-timezone";
import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import deepClone from "lodash/cloneDeep";
import Droits from "./Droits";
import TestHoraire from "./TestHoraire";
import AuthenticationService from "../Service/AuthenticationService";
import { ModalOctimeDifference } from "../Composant/modal";
import Logs from "./Logs";
import UcesoClass from "../Composant/Uceso/Uceday";
import { apiCall } from "../Service/ApiService";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    height: "18vh",
  },
  change: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  logo: {
    textalign: "center",
    maxWidth: "55%",
    maxHeight: "30%",
  },
  container: {
    maxHeight: 440,
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    textAlign: "center",
  },
  button: {
    flex: 1,
  },
  horaireTopBar: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  SpaceBetween: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  jour: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  testHoraireTitreJour: {
    marginTop: theme.spacing(3),
  },
  horaireValidite: {
    marginLeft: theme.spacing(1),
  },
  horaireValiditeSpace: {
    marginBottom: theme.spacing(1),
  },
  disabledColor: {
    color: "white",
  },
}));

function App() {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const petit = useMediaQuery(theme.breakpoints.down("md"));

  const [uceDays, setUceDays] = useState([] as any); //Liste des objets uceDay, une agglomération d'objets comprenant les uceso, personnels et fichiers nécessaire à leur calcul
  const [mode, setMode] = useState("U"); //Mode de l'application, U->Uceso, H->Horaire, D->Droits
  const [saveHoraire, setSaveHoraire] = useState(false);
  const [deleteHoraire, setDeleteHoraire] = useState(false);
  const [currentHoraire, setCurrentHoraire] = useState(null as any);
  const [octimeDifference, setOctimeDifference] = useState(false);
  const [recycleurOctimeDifference, setRecycleurOctimeDifference] = useState(
    [] as string[]
  );

  //Gestion des modals

  const closeModalOctimeDifference = () => {
    setOctimeDifference(false);
  };

  const successSaveHoraire = () => {
    setSaveHoraire(false);
  };

  const successDeleteHoraire = () => {
    setDeleteHoraire(false);
  };

  const execSaveHoraire = () => {
    setSaveHoraire(true);
  };

  const execDeleteHoraire = () => {
    setDeleteHoraire(true);
  };

  const getCurrentHoraire = (h: any) => {
    setCurrentHoraire(h);
  };

  //Changement de mode
  const handleModeChange = (newMode: string) => {
    switch (newMode) {
      case "U":
        setMode("U");
        break;
      case "H":
        setMode("H");
        break;
      case "TH":
        setMode("TH");
        break;
      case "D":
        setMode("D");
        break;
      case "L":
        setMode("L");
        break;
      default:
        setMode("U");
    }
  };

  const handleAbsence = async (
    selected: any,
    position: number,
    infoKey: any
  ) => {
    const uceDay = deepClone(uceDays);
    uceDay[position].handleAbsence(selected);
    closeSnackbar(infoKey);
    enqueueSnackbar("Modification effectuée", {
      variant: "success",
      autoHideDuration: 6000,
    });
    setUceDays(uceDay);
  };

  const changeAttribution = async (
    selected: any,
    infoKey: any,
    jour: string,
    sousJour: string,
    position: number
  ) => {
    const uceDay = deepClone(uceDays);
    uceDay[position].changeAttribution(selected, jour, sousJour);
    closeSnackbar(infoKey);
    enqueueSnackbar("Modification effectuée", {
      variant: "success",
      autoHideDuration: 6000,
    });
    setUceDays(uceDay);
  };

  const addPerson = async (
    personnes: any,
    selected: any,
    positionHoraire: any,
    position: number,
    infoKey: any
  ) => {
    const uceDay = deepClone(uceDays);
    uceDay[position].addPerson(personnes, selected, positionHoraire);
    closeSnackbar(infoKey);
    enqueueSnackbar("Ajout effectué", {
      variant: "success",
      autoHideDuration: 6000,
    });
    setUceDays(uceDay);
  };

  const removeUceso = async (index: number) => {
    let newUceday = [...uceDays];
    newUceday.splice(index, 1);
    setUceDays(newUceday);
  };

  const processMessage = async (
    result: number,
    Uceday: any,
    position: number
  ) => {
    // addUceDay nous renvoie un nombre qui indique la réussite ou non de l'ajout
    // Référentiel concernant les possibles retours :
    // 1 => Ajout réussi || 0 => Ajout réussi mais aucun horaire n'était valide pour cette date || -1 => Echec de l'ajout || -2 => Echec de l'ajout car les données des agents ne sont pas accessibles || 2 => UCESO Recalculées à la demande de l'utilisateur || -3 => Les UCESO à la date demandé sont déjà chargé
    switch (result) {
      case 1:
        enqueueSnackbar("UCESO calculées", {
          variant: "success",
          autoHideDuration: 6000,
        });
        if (position === -1) setUceDays(uceDays.concat(Uceday));
        else setUceDays(Uceday);
        break;
      case 0:
        enqueueSnackbar("L'horaire est invalide à la date de l'UCESO", {
          variant: "warning",
          autoHideDuration: 6000,
        });
        enqueueSnackbar("UCESO calculées", {
          variant: "success",
          autoHideDuration: 6000,
        });
        if (position === -1) setUceDays(uceDays.concat(Uceday));
        else setUceDays(Uceday);
        break;
      case -1:
        enqueueSnackbar("Echec de la récupération des données", {
          variant: "error",
          autoHideDuration: 6000,
        });
        break;
      case -2:
        enqueueSnackbar("Une erreur est survenue", {
          variant: "error",
          autoHideDuration: 6000,
        });
        break;
      case 2:
        const newUceDay = deepClone(uceDays);
        const positionUceday = await uceDays
          .map(function (e: any) {
            return e.date;
          })
          .indexOf(Uceday.date);
        newUceDay[positionUceday] = Uceday;
        setUceDays(newUceDay);
        enqueueSnackbar("UCESO recalculées", {
          variant: "success",
          autoHideDuration: 6000,
        });
        break;
      case -3:
        enqueueSnackbar("Les UCESO de ce jour sont déjà chargées", {
          variant: "error",
          autoHideDuration: 6000,
        });
        break;
      case 3:
        enqueueSnackbar("UCESO chargées", {
          variant: "success",
          autoHideDuration: 6000,
        });
        if (position === -1) setUceDays(uceDays.concat(Uceday));
        else setUceDays(Uceday);
        break;
      default:
        break;
    }
    const verifUceday = position === -1 ? Uceday : Uceday[position];
    console.log(verifUceday);
    if (verifUceday.nop.length === 0) {
      enqueueSnackbar("La Backbone NM n'est pas disponible pour ce jour", {
        variant: "warning",
        autoHideDuration: 6000,
      });
    }
    if (verifUceday.besoinFMP.length === 0) {
      enqueueSnackbar("La Backbone RH n'est pas disponible pour ce jour", {
        variant: "warning",
        autoHideDuration: 6000,
      });
    }
  };

  const addUceso = async (date: moment.MomentInput, check: any) => {
    let infoKey;
    var Uceday = new UcesoClass();

    const apiListUcedayObject = {
      terminaison: "listUceday",
    };
    const ucedayList = await apiCall(apiListUcedayObject);

    const uceList = JSON.parse(ucedayList.data.response).value;

    // Vérification de la présence du fichier du jour dans la liste
    if (
      uceList &&
      uceList.includes(
        moment(date).format("YYYYMMDD") + ".json" &&
          uceDays.find(
            (day: any) => day.date === moment(date).format("YYYYMMDD")
          ) === undefined
      )
    ) {
      infoKey = enqueueSnackbar("Chargement du fichier sauvegardé", {
        variant: "info",
        autoHideDuration: 10000,
      });
    } else if (
      uceDays.find(
        (day: any) => day.date === moment(date).format("YYYYMMDD")
      ) !== undefined
    ) {
      infoKey = enqueueSnackbar("Nouveau calcul en cours", {
        variant: "info",
        autoHideDuration: 10000,
      });
    } else {
      infoKey = enqueueSnackbar("Calcul en cours", {
        variant: "info",
        autoHideDuration: 10000,
      });
    }
    const result = await Uceday.addUceDay(date, check, uceDays);
    console.log(result);
    closeSnackbar(infoKey);

    const octimeDifference: string[] = Uceday.getWarning();
    if (octimeDifference.length !== 0) {
      setRecycleurOctimeDifference(octimeDifference);
      setOctimeDifference(true);
    }

    processMessage(result, Uceday, -1);
  };

  const handleHoraireChange = async (
    event: { target: { value: number } },
    positionUceday: number
  ) => {
    const snackBarKey = enqueueSnackbar("Changement en cours...", {
      variant: "info",
      autoHideDuration: 6000,
    });
    const uceDay = deepClone(uceDays);
    const result = await uceDay[positionUceday].horaireChange(
      event.target.value
    );
    closeSnackbar(snackBarKey);

    const octimeDifference: string[] = uceDay[positionUceday].getWarning();

    if (octimeDifference.length !== 0) {
      setRecycleurOctimeDifference(octimeDifference);
      setOctimeDifference(true);
    }

    await processMessage(result, uceDay, positionUceday);
  };

  const saveUceDay = async (i: number) => {
    const snackBarKey = enqueueSnackbar("Sauvegarde en cours...", {
      variant: "info",
      autoHideDuration: 6000,
    });
    const result = await uceDays[i].saveUceDay();
    closeSnackbar(snackBarKey);
    if (result) {
      enqueueSnackbar("Fichier UCESO sauvegardé", {
        variant: "success",
        autoHideDuration: 6000,
      });
    } else {
      enqueueSnackbar("Echec de la sauvegarde du fichier UCESO", {
        variant: "error",
        autoHideDuration: 6000,
      });
    }
  };

  return (
    AuthenticationService.user && (
      <>
        {/* On différencie la taille des écrans pour adapter au mieux le visuel */}
        {/* Ecran de laptop et plus grands */}
        {!petit && (
          <Grid container style={{ minHeight: "100vh", width: "100%" }}>
            <Grid item style={{ minHeight: "100vh", width: "20%" }}>
              {uceDays && (
                <NavBar
                  mode={mode}
                  horaireChange={handleHoraireChange}
                  uceDays={uceDays}
                  date={uceDays.map((element: any) => element.date)}
                  horaire={uceDays.map((element: any) => element.horaire)}
                  dateCalc={uceDays.map((element: any) => element.dateCalc)}
                  addUceDay={addUceso}
                  removeUceDay={removeUceso}
                  saveUceDay={saveUceDay}
                  ucesoSalto={uceDays}
                  handleModeChange={handleModeChange}
                  classes={classes}
                />
              )}
            </Grid>

            <Grid item style={{ height: "100%", width: "80%" }}>
              <Grid container>
                {mode === "U" &&
                  AuthenticationService.user.role.droits.uceso.acces &&
                  uceDays &&
                  uceDays.map((uceDay: any, index: any) =>
                    uceDay.personnel && uceDay.precPersonnel ? (
                      <Uceso
                        uceDay={uceDay}
                        classes={classes}
                        horaire={uceDay.horaire}
                        handleAbsence={handleAbsence}
                        key={uceDay.date}
                        position={index}
                        listOfUnassignedAgents={uceDay.listOfUnassignedAgents}
                        addPerson={addPerson}
                        changeAttribution={changeAttribution}
                        nop={uceDay.nop}
                        besoinFMP={uceDay.besoinFMP}
                      />
                    ) : (
                      "Pas de données de personnel pour cette date : " +
                      uceDay.date.slice(0, 4) +
                      "/" +
                      uceDay.date.slice(4, 6) +
                      "/" +
                      uceDay.date.slice(6, 8)
                    )
                  )}
                {AuthenticationService.user.role.droits.horaire.acces &&
                  mode === "H" && (
                    <Horaire
                      successSaveHoraire={successSaveHoraire}
                      successDeleteHoraire={successDeleteHoraire}
                      saveHoraire={saveHoraire}
                      deleteHoraire={deleteHoraire}
                      getCurrentHoraire={getCurrentHoraire}
                      classes={classes}
                    />
                  )}
                {AuthenticationService.user.role.droits.droits.acces &&
                  mode === "D" && <Droits />}
                {AuthenticationService.user.role.droits.horaire.acces &&
                  mode === "TH" && <TestHoraire classes={classes} />}
                {AuthenticationService.user.role.droits.logs.acces &&
                  mode === "L" && <Logs />}
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* Ecran de tablette et plus petits  */}
        {petit && (
          <>
            <Grid
              container
              direction="row"
              style={{
                height: "90vh",
                position: "relative",
                overflow: "scroll",
                minHeight: "90vh",
                maxHeight: "90vh",
              }}
            >
              <Grid style={{ height: "100%" }} item xs={12} md={12}>
                <Grid container>
                  {mode === "U" &&
                    AuthenticationService.user.role.droits.uceso.acces &&
                    uceDays &&
                    uceDays.map((element: any, index: any) =>
                      element.personnel && element.precPersonnel ? (
                        <Uceso
                          uceDay={element}
                          classes={classes}
                          horaire={element.horaire}
                          handleAbsence={handleAbsence}
                          key={element.date}
                          listOfUnassignedAgents={
                            element.listOfUnassignedAgents
                          }
                          position={index}
                          addPerson={addPerson}
                          changeAttribution={changeAttribution}
                          nop={element.nop}
                          besoinFMP={element.besoinFMP}
                        />
                      ) : (
                        "Pas de données de personnel pour cette date : " +
                        element.date.slice(0, 4) +
                        "/" +
                        element.date.slice(4, 6) +
                        "/" +
                        element.date.slice(6, 8)
                      )
                    )}

                  {AuthenticationService.user.role.droits.horaire.acces &&
                    mode === "H" && (
                      <Horaire
                        successSaveHoraire={successSaveHoraire}
                        successDeleteHoraire={successDeleteHoraire}
                        saveHoraire={saveHoraire}
                        deleteHoraire={deleteHoraire}
                        getCurrentHoraire={getCurrentHoraire}
                        classes={classes}
                      />
                    )}
                  {AuthenticationService.user.role.droits.droits.acces &&
                    mode === "D" && <Droits />}
                  {AuthenticationService.user.role.droits.horaire.acces &&
                    mode === "TH" && <TestHoraire classes={classes} />}
                  {AuthenticationService.user.role.droits.logs.acces &&
                    mode === "L" && <Logs />}
                </Grid>
              </Grid>
            </Grid>
            <NavBar
              mode={mode}
              horaireChange={handleHoraireChange}
              date={uceDays.map((element: any) => element.date)}
              horaire={uceDays.map((element: any) => element.horaire)}
              dateCalc={uceDays.map((element: any) => element.dateCalc)}
              addUceDay={addUceso}
              removeUceDay={removeUceso}
              uceDays={uceDays}
              saveUceDay={saveUceDay}
              handleModeChange={handleModeChange}
              execSaveHoraire={execSaveHoraire}
              execDeleteHoraire={execDeleteHoraire}
              ucesoSalto={uceDays}
              currentHoraire={currentHoraire}
              classes={classes}
            />
          </>
        )}
        <ModalOctimeDifference
          recycleurs={recycleurOctimeDifference}
          closeModal={closeModalOctimeDifference}
          open={octimeDifference}
        />
      </>
    )
  );
}

export default App;
