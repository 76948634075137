import { TextField, Button, Grid } from "@mui/material";
import { Formik, FormikProps, Form } from "formik";
import * as Yup from "yup";
import { apiCall } from "../../Service/ApiService";
import { useState } from "react";
import { ErrorModal } from "./Modal/ErrorModal";
import { SuccessModal } from "./Modal/SuccessModal";

interface LostPasswordForm {
  email: string;
}

const FormForgotPassword = (props: any) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [texte, setTexte] = useState("");

  const closeErrorModal = () => {
    setError(false);
  };
  const closeSuccessModal = () => {
    setSuccess(false);
    props.lostPasswordValid();
  };
  return (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={(values: LostPasswordForm, actions) => {
          const apiLostPasswordObject = {
            terminaison: "lostPassword",
            email: values.email,
          };
          apiCall(apiLostPasswordObject)
            .then((result) => {
              try {
                setTexte(
                  "Si l'adresse mail existe un email vous a été envoyé. Merci de cliquer sur le lien pour changer votre mot de passe (le lien est valide 24H)."
                );
                setSuccess(true);
                actions.resetForm();
              } catch (error) {
                setTexte(
                  "Merci de rééssayer ! Il semblerait qu'une erreur soit survenue en se connectant au serveur."
                );
                setError(true);
              }
            })
            .catch((error) => {
              try {
                if (error.response.data.response === "Aucune correspondance") {
                  setTexte(
                    "Si l'adresse mail existe un mail vous a été envoyé. Merci de cliquer sur le lien pour changer votre mot de passe (le lien est valide 24H). "
                  );
                  setSuccess(true);
                } else {
                  setTexte(
                    "Merci de rééssayer ! Il semblerait qu'une erreur soit survenue en se connectant au serveur."
                  );
                  setError(true);
                }
              } catch (error) {
                setTexte(
                  "Merci de rééssayer ! Il semblerait qu'une erreur soit survenue en se connectant au serveur."
                );
                setError(true);
              }
            });
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required("Entrez un email valide"),
        })}
      >
        {(props: FormikProps<LostPasswordForm>) => {
          const { values, touched, errors, handleBlur, handleChange } = props;
          return (
            <Form noValidate>
              <Grid
                container
                alignContent="center"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    style={{ color: "white" }}
                    value={values.email}
                    helperText={
                      errors.email && touched.email ? errors.email : ""
                    }
                    error={errors.email && touched.email ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} md={8} style={{ marginTop: "8px" }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <ErrorModal open={error} closeModal={closeErrorModal} texte={texte} />
      <SuccessModal
        open={success}
        closeModal={closeSuccessModal}
        texte={texte}
      />
    </>
  );
};

export default FormForgotPassword;
