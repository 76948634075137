////////////////////////////////////////////////////////////////////////////////////////////////
//Composant permettant d'autoriser l'accès à la page uniquement si l'utilisateur est authentifié/
////////////////////////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PATHS } from './routes';
import AuthenticationService from '../Service/AuthenticationService';

const PrivateRoute = ({ component: Component, ...rest }: any) => { //Route privée, nécessite authentification ou redirige vers la page de login

    return (
        <>
            <Route
                {...rest}
                render={(props) => {

                    if (AuthenticationService.isAuthenticated) {
                        return <Component {...props} />
                    }

                    else {
                        return <Redirect to={PATHS.Login} />
                    }
                }}
            />
        </>
    )


};

export default PrivateRoute;