import { Grid, Button, Select, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import moment from "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import Save from "@material-ui/icons/Save";
import Calculate from "@material-ui/icons/Replay";
import { ModalUploadSalto } from "../modal";
import AuthenticationService from "../../Service/AuthenticationService";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { useSnackbar } from "notistack";
import { sendLogs } from "./Uceday";
import { apiCall } from "../../Service/ApiService";

const env = process.env.REACT_APP_ENV;

const useStyles = makeStyles((theme) => ({
  save: {
    marginRight: theme.spacing(2),
  },
  ucesoOpenList: {
    marginBottom: theme.spacing(0.5),
  },
  spaceBetweenUceso: {
    marginBottom: theme.spacing(1),
  },
}));

moment.locale("fr", {
  //Locale française pour moment.js
  months:
    "Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre".split(
      "_"
    ),
  monthsShort:
    "Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.".split("_"),
  monthsParseExact: true,
  weekdays: "Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi".split("_"),
  weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
  weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? "er" : "e");
  },
  meridiemParse: /PD|MD/,
  isPM: function (input) {
    return input.charAt(0) === "M";
  },
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? "PD" : "MD";
  },
  week: {
    dow: 1,
    doy: 4,
  },
});

//Composant permettant la gestion des UCESO, se situe sur la navBar
// Affichage différent en fonction de la taille de l'écran
const UcesoCardGestion = (props: any) => {
  const user = AuthenticationService.user;

  const classes = useStyles();

  const [saltoModal, setSaltoModal] = useState(false);

  const [cibleCharge, setCibleCharge] = useState(
    props.date ? props.date.map(() => "") : ([] as any)
  );

  const [disableDelete, setDisableDelete] = useState(false);
  const [currentTitle, setCurrentTitle] = useState("" as any);
  const [currentDate, setCurrentDate] = useState("" as any);
  const [currentData, setCurrentData] = useState("" as any);

  const [changeHoraire, setChangeHoraire] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const closeSaltoModal = () => {
    setSaltoModal(false);
  };

  async function signSalto(title: string, data: any, date: string) {
    const infoKey = enqueueSnackbar("Export du fichier en cours", {
      variant: "info",
      autoHideDuration: 8000,
    });
    const apiObject = {
      terminaison: "salto",
      title: title,
      data: data,
      date: date,
      user:
        AuthenticationService.user.user.firstname +
        " " +
        AuthenticationService.user.user.name,
    };
    await apiCall(apiObject)
      .then(() => {
        closeSnackbar(infoKey);
        enqueueSnackbar("Export réussi", {
          variant: "success",
          autoHideDuration: 6000,
        });
        sendLogs(
          "Sign",
          "Upload du fichier salto du " +
            moment(date, "DD-MM-YYYY").format("DD/MM/YYYY") +
            " pour signature"
        );
      })
      .catch(() => {
        closeSnackbar(infoKey);
        enqueueSnackbar("Une erreur s'est produite", {
          variant: "error",
          autoHideDuration: 6000,
        });
      });

    closeSaltoModal();
  }

  return (
    <>
      <Grid
        xs={10}
        item
        style={{
          minHeight: 100,
          maxHeight: 300,
          backgroundColor: "white",
          borderRadius: "5%",
        }}
        className={classes.spaceBetweenUceso}
        key={props.indexUceso}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          alignContent="center"
        >
          <Grid item style={{ textAlign: "center" }}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid
                item
                className={classes.ucesoOpenList && props.classes.horaireTopBar}
              >
                {moment
                  .tz(`${props.uceDay.date}T0000`, "Europe/Paris")
                  .format("dddd DD MMMM YYYY")}
              </Grid>
              <Grid item lg={12} className={classes.ucesoOpenList}>
                Horaire - {props.horaire.titre}
              </Grid>

              <Grid item lg={12} className={classes.ucesoOpenList}>
                {user.role.droits.uceso.editHoraire && changeHoraire && (
                  <Select
                    value={props.horaire.titre + ".json"}
                    onChange={(e) => {
                      setChangeHoraire(false);
                      props.horaireChange(e, props.indexUceso);
                    }}
                  >
                    {props.listHoraire}
                  </Select>
                )}
                {user.role.droits.uceso.editHoraire && !changeHoraire && (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      setChangeHoraire(true);
                    }}
                  >
                    Changer
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              {user.role.droits.uceso.sauvegarde && (
                <>
                  <Tooltip title="Enregistrer le graphe">
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      name={props.uceDay.date}
                      onClick={() => {
                        props.saveUceDay(props.indexUceso);
                      }}
                      style={{
                        maxWidth: "35px",
                        maxHeight: "35px",
                        minWidth: "35px",
                        minHeight: "35px",
                      }}
                      className={classes.save}
                    >
                      <Save
                        style={{
                          maxWidth: "25px",
                          maxHeight: "25px",
                          minWidth: "25px",
                          minHeight: "25px",
                        }}
                      />
                    </Button>
                  </Tooltip>
                </>
              )}
              {user.role.droits.uceso.salto && (
                <>
                  <Tooltip title="Exporter le fichier salto">
                    <Button
                      //disabled={true}
                      size="small"
                      variant="contained"
                      color="primary"
                      disabled={
                        props.uceDay.salto === "CollissionHaveHappened" ||
                        env === "DEV"
                          ? true
                          : false
                      }
                      onClick={(e) => {
                        setCurrentData(props.uceDay.salto);
                        setCurrentDate(
                          moment
                            .tz(`${props.uceDay.date}T0000`, "Europe/Paris")
                            .format("DD-MM-YYYY")
                        );
                        setCurrentTitle(
                          "UCESO_LFEE_" +
                            moment
                              .tz(`${props.uceDay.date}T0000`, "Europe/Paris")
                              .format("YYYYMMDD")
                        );
                        setSaltoModal(true);
                      }}
                      type="button"
                      style={{
                        maxWidth: "35px",
                        maxHeight: "35px",
                        minWidth: "35px",
                        minHeight: "35px",
                      }}
                      className={classes.save}
                    >
                      <ImportExportIcon
                        style={{
                          maxWidth: "25px",
                          maxHeight: "25px",
                          minWidth: "25px",
                          minHeight: "25px",
                        }}
                      />
                    </Button>
                  </Tooltip>
                </>
              )}
              <Tooltip title="Fermer le graphe">
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  name={props.uceDay.date}
                  className={classes.save}
                  style={{
                    maxWidth: "35px",
                    maxHeight: "35px",
                    minWidth: "35px",
                    minHeight: "35px",
                  }}
                  disabled={disableDelete}
                  onClick={(e) => {
                    let newCibleCharge = [...cibleCharge];
                    newCibleCharge.splice(props.indexUceso, 1);
                    setCibleCharge(newCibleCharge);
                    props.removeUceDay(props.indexUceso);
                  }}
                >
                  <Close
                    style={{
                      maxWidth: "25px",
                      maxHeight: "25px",
                      minWidth: "25px",
                      minHeight: "25px",
                    }}
                  />
                </Button>
              </Tooltip>
              {user.role.droits.uceso.reset && (
                <Tooltip title="Recalculer les uceso depuis les données Octime et horaires les plus récentes">
                  <Button
                    size="small"
                    variant="outlined"
                    name={props.uceDay.date}
                    style={{
                      maxWidth: "35px",
                      maxHeight: "35px",
                      minWidth: "35px",
                      minHeight: "35px",
                    }}
                    onClick={async () => {
                      setDisableDelete(true);
                      props.addUceDay(
                        moment(props.uceDay.date, "YYYYMMDD"),
                        false
                      );
                      setDisableDelete(false);
                    }}
                  >
                    <Calculate
                      style={{
                        maxWidth: "25px",
                        maxHeight: "25px",
                        minWidth: "25px",
                        minHeight: "25px",
                      }}
                    />
                  </Button>
                </Tooltip>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            style={{ color: "black" }}
            className={classes.spaceBetweenUceso}
          >
            Calculé le {props.uceDay.dateCalc}
          </Grid>
        </Grid>
        <ModalUploadSalto
          open={saltoModal}
          closeModal={closeSaltoModal}
          date={currentDate}
          signSalto={signSalto}
          title={currentTitle}
          data={currentData}
        />
      </Grid>
    </>
  );
};

export default UcesoCardGestion;
