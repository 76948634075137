import { useState } from "react";
import { Button, Card, Grid, Typography } from "@material-ui/core";
import styled from "@emotion/styled";
import logo from "../Images/logo3.png";
import { makeStyles } from "@material-ui/core/styles";
//import { apiCall } from "../Service/ApiService";
import FormConnection from "../Composant/BackupConnection/FormConnexion";
import FormForgotPassword from "../Composant/BackupConnection/FormForgotPassword";
import HomeIcon from "@material-ui/icons/Home";
import { useHistory } from "react-router-dom";
import { PATHS } from "../Route/routes";

//const URL_TEST_SSO = process.env.REACT_APP_URL_TEST_SSO || "";

const StyledCardBigScreen = styled(Card)`
  padding: 10px;
  margin: auto;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const useStyles = makeStyles((theme) => ({
  fond: {
    backgroundColor: "#2D3E4E",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  logo: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    marginTop: theme.spacing(2),
  },
  btn: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(4),
  },
}));

//Composant de connexion
const BackupConnection = () => {
  const classes = useStyles();
  const history = useHistory();

  //const [SSOEnable, setSSOEnable] = useState(false);
  const [lostPassword, setLostPassword] = useState(false);

  const lostPasswordClick = () => {
    setLostPassword(true);
  };
  const lostPasswordValid = () => {
    setLostPassword(false);
  };

  // const ping = async (host: string) => {
  //   const pong = await fetch(host)
  //     .then((response) => {
  //       if (response.status === 200 && response.statusText === "OK") {
  //         return "Actif";
  //       } else {
  //         console.warn("error");
  //         return "Down";
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("network error: " + error);
  //     });
  //   return pong;
  // };

  // const testSSOEnable = async () => {
  //   const apiParams = {
  //     terminaison: "SSOStatus",
  //   };
  //   return apiCall(apiParams)
  //     .then((data) => {
  //       const status = JSON.parse(data.data.response).status;
  //       setSSOEnable(status);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  // const updateSSOStatus = async (newStatus: boolean) => {
  //   const apiParams = {
  //     terminaison: "updateSSOStatus",
  //     data: JSON.stringify({ status: newStatus }),
  //   };
  //   await apiCall(apiParams)
  //     .then((data) => {
  //       setSSOEnable(newStatus);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   testSSOEnable();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div style={{ height: "100vh" }} className={classes.fond}>
      <div style={{ height: "25vh" }}></div>
      <>
        <StyledCardBigScreen>
          <Grid container alignContent="center" justifyContent="center">
            {lostPassword && (
              <Grid item xs={12} style={{ textAlign: "left" }}>
                <Button
                  color="primary"
                  onClick={() => {
                    history.push(PATHS.Home);
                  }}
                >
                  <HomeIcon />
                </Button>
              </Grid>
            )}
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              className={classes.logo}
              style={{ height: "20vh" }}
            ></Grid>
            <br />
            {!lostPassword && (
              <>
                <Grid item xs={8}>
                  <Typography variant="h5" style={{ textAlign: "center" }}>
                    Connexion de secours
                  </Typography>
                </Grid>
                <Grid item md={6} xs={10}>
                  <FormConnection lostPasswordClick={lostPasswordClick} />
                </Grid>
              </>
            )}
            {lostPassword && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5" style={{ textAlign: "center" }}>
                    Mot de passe oublié
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormForgotPassword lostPasswordValid={lostPasswordValid} />
                </Grid>
              </>
            )}
          </Grid>
        </StyledCardBigScreen>
      </>
    </div>
  );
};

export default BackupConnection;
