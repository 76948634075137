import React, { useEffect } from "react";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import App from "../Page/App";
import { SnackbarProvider } from "notistack";
import LoginCallback from "../Page/LoginCallback";
import PrivateRoute from "./PrivateRoute";
import { PATHS } from "./routes";
import ChangePassword from "../Page/ChangePassword";
import HomeLogin from "../Page/HomeLogin";

const Content: React.FC<{}> = () => {
  const history = useHistory();

  useEffect(() => {
    //récupère le code renvoyé après l'authentification via Angélique
    var code = window.location.href.match(/code=([^&]+)/);

    //Vérifie que le résultat n'est pas null et afffecte à history la valeure de code
    if (code) {
      history.push({
        pathname: PATHS.Oauth,
        state: { code: code[1] },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SnackbarProvider maxSnack={3}>
      <Switch>
        <Route exact path={PATHS.Login} component={HomeLogin} />
        <Route exact path={PATHS.Oauth} component={LoginCallback} />
        <Route exact path={PATHS.ChangePassword} component={ChangePassword} />
        <PrivateRoute exact path={PATHS.Home} component={App} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </SnackbarProvider>
  );
};

export default Content;
