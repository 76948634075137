import { Grid, Card, Typography, makeStyles } from "@material-ui/core";
import { EditableSj, AjoutPForm } from "../Form";
import AuthenticationService from "../../Service/AuthenticationService";
import deepClone from "lodash/cloneDeep";
import { useEffect, useState } from "react";
import { Periode } from "./Periode";
import { periode } from "../../Type/All";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const SousJour = (props: any) => {
  const user = AuthenticationService.user;
  const classes = useStyles();

  const [periodes, setPeriodes] = useState(props.sousJour.periodes);

  useEffect(() => {
    setPeriodes(props.sousJour.periodes);
  }, [props.sousJour.periodes]);

  //Appel la fonction ajout Sous-Jour de la classe Jour lorsqu'il y a une demande de clonage
  const newFSubday = (jour: any, sousJour: any) => {
    props.newFSubday(jour, sousJour);
  };

  //Appel la fonction delete Sous-Jour de la classe Jour
  const deleteFSubday = (jour: any, sousJour: any, positionSousJour: any) => {
    props.deleteFSubday(jour, sousJour, positionSousJour);
  };

  //Edite un sous-jour
  const editFSubday = (
    jour: any,
    sousJourIndex: any,
    sousJour: any,
    newExtension: any
  ) => {
    props.editFSubday(jour, sousJourIndex, sousJour, newExtension);
  };

  //Ajoute une période
  const newFPeriode = (jour: any, sousJourIndex: any, periode: periode) => {
    if (periode.debut.length < 5) periode.debut = "0" + periode.debut;
    if (periode.fin.length < 5) periode.fin = "0" + periode.fin;

    props.horaire.addPeriode(jour, sousJourIndex, periode);

    // On fait une copie du Jour pour déclencher un changement d'état
    const newJour = deepClone(props.horaire.jours[props.positionJour]);
    setPeriodes(newJour.sousJours[props.index].periodes);
    props.updateJour(newJour);
  };

  //Supprime une période
  const deleteFPeriode = (jour: any, sousJourIndex: any, periode: periode) => {
    if (periode.debut.length < 5) periode.debut = "0" + periode.debut;
    if (periode.fin.length < 5) periode.fin = "0" + periode.fin;

    props.horaire.removePeriode(jour, sousJourIndex, periode);

    // On fait une copie du Jour pour déclencher un changement d'état
    const newJour = deepClone(props.horaire.jours[props.positionJour]);
    setPeriodes(newJour.sousJours[props.index].periodes);
    props.updateJour(newJour);
  };

  return (
    <Grid
      item
      xs={12}
      lg={4}
      key={Math.random() + props.index}
      style={{ flex: "2 1 auto", width: "100%" }}
    >
      <Card
        style={{
          backgroundColor: props.sousJour.cds
            ? "white"
            : props.sousJour.nPExt
            ? "#b5dbeb"
            : props.jour.veille
            ? "#CFCFCF"
            : !props.jour.nomOctime
            ? "#FFCFCF"
            : "#CFCFFF",
          margin: "auto",
          padding: 5,
        }}
      >
        <Typography
          style={{ textAlign: "center" }}
          className={classes.title}
        >{`Horaire n°${props.index + 1}`}</Typography>
        <Grid
          container
          direction="row"
          style={{
            alignContent: "stretch",
            alignItems: "stretch",
          }}
        >
          <Grid item xs={6}>
            <EditableSj
              style={{
                backgroundColor: props.jour.veille
                  ? "#BFBFBF"
                  : !props.jour.nomOctime
                  ? "#FFBFBF"
                  : "#BFBFFF",
                margin: "auto",
                padding: 5,
              }}
              editFSubday={editFSubday}
              jour={props.jour}
              positionJour={props.positionJour}
              positionSousJour={props.index}
              sousJour={props.sousJour}
              nPExt={props.sousJour.nPExt}
              cds={props.sousJour.cds}
              periodes={periodes}
              newFSubday={newFSubday}
              deleteFSubday={deleteFSubday}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column" alignItems="center">
              <Grid item xs>
                {user.role.droits.horaire.ajout && (
                  <AjoutPForm
                    newFPeriode={newFPeriode}
                    jour={props.jour.nom}
                    sousJourIndex={props.index}
                    periodes={periodes}
                  />
                )}
              </Grid>
              {periodes.map((periode: any, index: number) => (
                <Periode
                  jour={props.jour}
                  indexSJ={props.index}
                  index={index}
                  etat={periode.etat}
                  key={index}
                  periode={periode}
                  debut={periode.debut}
                  fin={periode.fin}
                  deleteFPeriode={deleteFPeriode}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
