import React, { useEffect, useState } from "react";
import {
  useMediaQuery,
  Grid,
  Select,
  Button,
  MenuItem,
  Paper,
  Accordion,
  AccordionDetails,
  Tooltip,
  AccordionSummary,
  useTheme,
} from "@material-ui/core/";
import deepClone from "lodash/cloneDeep";
import FHoraire, { sendLogs } from "../Composant/Horaire/FichierHoraire";
import {
  AjoutFHoraire,
  AjoutJForm,
  EditableJ,
  ChargeFHoraire,
} from "../Composant/Form";
import moment from "moment-timezone";
import { withSnackbar } from "notistack";
import { ExpandMore, DeleteSweep, Save } from "@material-ui/icons";
import {
  ModalDeleteHoraire,
  ModalDiscontinuousSchedule,
  ModalIntervalCrossing,
} from "../Composant/modal";
import AuthenticationService from "../Service/AuthenticationService";
import { Jour } from "../Composant/Horaire/Jour";
import { HoraireInformation } from "../Composant/Horaire/HoraireInformations";
import { log } from "../Type/All";
import { apiCall } from "../Service/ApiService";
import { generateId } from "../globalFunction";

export const Horaire = (props: any) => {
  const user = AuthenticationService.user;
  const theme = useTheme();
  const petit = useMediaQuery(theme.breakpoints.down("md"));
  const mini = useMediaQuery(theme.breakpoints.down("xs"));

  const [filesLoad, setFilesLoad] = useState(false);
  const [fichierHoraire, setFichierHoraire] = useState([] as any);
  const [selected, setSelected] = useState(0);
  const [cibleCharge, setCibleCharge] = useState("");
  const [chargeList, setChargeList] = useState([] as any);
  const [listHoraire, setListHoraire] = useState([] as any);
  const [deleteModal, setDeleteModal] = useState(false);
  const [discontinuousScheduleModal, setDiscontinuousScheduleModal] =
    useState(false);
  const [discontinuousSchedule, setDiscontinuousSchedule] = useState({} as any);
  const [lastName, setLastName] = useState("");
  const [expended, setExpended] = useState(-1);
  const [intervalCrossingModal, setIntervalCrossingModal] = useState(false);
  const [intervalCrossing, setIntervalCrossing] = useState([] as string[]);

  useEffect(() => {
    getHListe(true);
    props.getCurrentHoraire(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fichierHoraire]);

  useEffect(() => {
    checkContinuiteHoraire();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.saveHoraire)
      saveHoraire().then(() => {
        props.successSaveHoraire();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.saveHoraire]);

  useEffect(() => {
    if (props.deleteHoraire) {
      openDeleteModal();
      props.successDeleteHoraire();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deleteHoraire]);

  //Gestion des modals
  const openDeleteModal = () => {
    setDeleteModal(true);
  };
  const closeModalDiscontinuousSchedule = () => {
    setDiscontinuousScheduleModal(false);
  };
  const closeModalDelete = () => {
    setDeleteModal(false);
  };

  const closeIntervalCrossignModal = () => {
    setIntervalCrossingModal(false);
  };

  const modalDelete = () => {
    supprH(fichierHoraire[selected].titre + ".json");
    getHListe();
    fichierHoraire.splice(selected, 1);
    if (fichierHoraire !== 0) {
      setDeleteModal(false);
      setSelected(0);
    } else {
      setDeleteModal(false);
      setSelected(0);
    }
  };

  // Enregistre l'ancien nom dans la variable lastName car le fichier stocké porte ce nom la
  const changeLastName = (lastName: string) => {
    setLastName(lastName);
  };

  const changeAccordion = (index: number) => {
    if (index === expended) {
      setExpended(-1);
    } else {
      setExpended(index);
    }
  };

  //Crée un nouveau fichier horaire à partir de values, values peut être un fichier xml, un objet FHoraire ou rien
  const addHoraire = (newHoraire: any) => {
    const fichierHoraires = fichierHoraire.concat([new FHoraire(newHoraire)]);
    setFichierHoraire(fichierHoraires);
    setSelected(fichierHoraire.length);
  };

  //Gére le changement de fichier horaire choisi
  const handleChange = (e: any) => {
    setSelected(
      fichierHoraire.findIndex((f: any) => f.titre === e.target.value)
    );
  };

  //Renvoie un select de la liste des fichiers chargés
  const listHoraires = () => {
    props.getCurrentHoraire(fichierHoraire[selected].titre);
    return (
      <Select value={fichierHoraire[selected].titre} onChange={handleChange}>
        {fichierHoraire.map((element: any, index: number) => (
          <MenuItem value={element.titre} key={element.titre}>
            {`${index + 1}. ${element.titre}`}
          </MenuItem>
        ))}
      </Select>
    );
  };

  //Ajoute un nouveau jour au fichier ouvert
  const newFDay = (jour: any) => {
    var currentHoraire = deepClone(fichierHoraire[selected]);
    currentHoraire.addJour({
      nom: jour.nom,
      nomOctime: jour.nomOctime,
      veille: jour.veille,
      valeur: jour.valeur,
      ExtPcMini: jour.ExtPcMini,
      id: generateId(),
    });
    setFichierHoraire(
      fichierHoraire.map((f: any, index: number) =>
        index === selected ? currentHoraire : f
      )
    );
  };

  //Ajoute un nouveau jour au fichier ouvert
  const deleteHoraireDay = (jour: any) => {
    var currentHoraire = deepClone(fichierHoraire[selected]);
    currentHoraire.removeJour(jour);
    setFichierHoraire(
      fichierHoraire.map((f: any, index: number) =>
        index === selected ? currentHoraire : f
      )
    );
  };

  //Edit un jour du fichier ouvert
  const editFDay = (jour: any, nouveauJour: any) => {
    var currentHoraire = deepClone(fichierHoraire[selected]);
    currentHoraire.editJour(jour, {
      nom: nouveauJour.nom,
      nomOctime: nouveauJour.nomOctime,
      veille: nouveauJour.veille,
      valeur: nouveauJour.valeur,
      ExtPcMini: nouveauJour.ExtPcMini,
      id: jour.id,
    });
    setFichierHoraire(
      fichierHoraire.map((horaire: any, index: number) =>
        index === selected ? deepClone(currentHoraire) : horaire
      )
    );
  };

  //Sauvegarde le fichier sur le serveur
  const saveHoraire = async () => {
    try {
      if (fichierHoraire[selected]) {
        if (lastName !== "") {
          // Si le fichier a changé de nom, on supprime l'ancien fichier
          const apiPostHoraireObject = {
            terminaison: "postHoraire",
            title: lastName + ".json",
            user:
              AuthenticationService.user.user.firstname +
              " " +
              AuthenticationService.user.user.name,
            details: "Sauvegarde " + lastName + ".json",
          };
          await apiCall(apiPostHoraireObject);
          setLastName("");
        }
        const apiListHoraireObject = {
          terminaison: "listHoraire",
        };
        // Récupère la liste des horaires
        let horaireListData = await apiCall(apiListHoraireObject);
        let horaireList = JSON.parse(horaireListData.data.response).value;

        let fileAccess: any[] = [];
        let invalid = false;
        let intervalle: any[] = [];
        let jsonregex = /.json$/;

        // On récupère uniquement les fichiers en .json
        horaireList = horaireList.filter((horaireTitle: any) =>
          jsonregex.test(horaireTitle)
        );

        // On récupère les contenus des fichiers enregistrés
        horaireList.forEach((horaireTitle: any) => {
          if (horaireTitle !== fichierHoraire[selected].titre + ".json") {
            const apiGetHoraireObject = {
              terminaison: "getHoraire",
              title: horaireTitle,
            };
            fileAccess.push(
              apiCall(apiGetHoraireObject).then((result) => {
                return new FHoraire(JSON.parse(result.data.response));
              })
            );
          }
        });

        let files = await Promise.all(fileAccess);

        // On vérifie pour chaque intervalle de validité de notre nouveau fichier qu'il ne rentre pas en interférence avec un autre intervalle de validité d'un horaire stocké
        fichierHoraire[selected].validite.forEach((validiteH: string[]) => {
          let dval = moment(validiteH[0], "DD/MM/YYYY");
          let fval = moment(validiteH[1], "DD/MM/YYYY");
          files.forEach((element) => {
            element.validite.forEach((validite: string[]) => {
              let td = moment(validite[0], "DD/MM/YYYY");
              let tf = moment(validite[1], "DD/MM/YYYY");
              if (
                dval.isBetween(td, tf, undefined, "[]") ||
                fval.isBetween(td, tf, undefined, "[]") ||
                td.isBetween(dval, fval, undefined, "[]") ||
                tf.isBetween(dval, fval, undefined, "[]")
              ) {
                invalid = true;
                intervalle.push([
                  "début : " + validite[0] + " - fin : " + validite[1],
                  " Fichier Horaire correspondant : " + element.titre,
                ]);
              }
            });
          });
        });
        // Si pas d'interférence
        if (!invalid) {
          let horaireStringify = JSON.stringify(fichierHoraire[selected]);
          let infoKey = props.enqueueSnackbar("Sauvegarde en cours", {
            variant: "info",
            autoHideDuration: 6000,
          });
          const veille = fichierHoraire[selected].jours.filter(
            (jours: any) => jours.veille === true
          );
          const apiPostHoraireObject = {
            terminaison: "postHoraire",
            title: fichierHoraire[selected].titre + ".json",
            data: horaireStringify,
            user:
              AuthenticationService.user.user.firstname +
              " " +
              AuthenticationService.user.user.name,
            details: "Sauvegarde " + fichierHoraire[selected].titre + ".json",
          };
          await apiCall(apiPostHoraireObject).then(
            (result) => {
              props.closeSnackbar(infoKey);
              if (result.data.response === "Fichier uploadé") {
                props.enqueueSnackbar("Sauvegarde accomplie", {
                  variant: "success",
                  autoHideDuration: 3000,
                });
                fichierHoraire[selected].historique.forEach((log: log) =>
                  sendLogs(log.action, log.details)
                );
                sendLogs(
                  "Save",
                  "Sauvegarde du fichier horaire : " +
                    fichierHoraire[selected].titre
                );
              } else
                props.enqueueSnackbar("Sauvegarde échouée", {
                  variant: "error",
                  autoHideDuration: 3000,
                });
              if (veille.length <= 0)
                props.enqueueSnackbar("Aucun jour de veille trouvé", {
                  variant: "warning",
                  autoHideDuration: 3000,
                });
            },
            (error) => props.closeSnackbar(infoKey)
          );
          await checkContinuiteHoraire();
        }
        // Si une interférence est trouvée
        else {
          setIntervalCrossing(intervalle);
          setIntervalCrossingModal(true);
        }
      } else {
        props.enqueueSnackbar("Pas d'horaire chargé", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error(error);
      props.enqueueSnackbar("Une erreur est survenue", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  //Charge un fichier horaire
  const loadH = async (title: string) => {
    const apiGetHoraireObject = {
      terminaison: "getHoraire",
      title: title,
    };
    const newHoraire = await apiCall(apiGetHoraireObject).then((result) => {
      return JSON.parse(result.data.response);
    });
    addHoraire(newHoraire);
  };

  // Permet de vérifier si les horaires sont continues sur 1 ans (1er janvier au 1er janvier)
  const checkContinuiteHoraire = async () => {
    const apiListHoraireObject = {
      terminaison: "listHoraire",
    };
    let horaireListData = await apiCall(apiListHoraireObject);
    try {
      const horaireListWithValidity: any[] = [];
      let horaireList = JSON.parse(horaireListData.data.response).value;
      const validiteContinuite: any[] = [];
      const promiseHoraire: Array<Promise<void>> = [];
      horaireList.forEach((titre: any) => {
        const apiGetHoraireObject = {
          terminaison: "getHoraire",
          title: titre,
        };
        promiseHoraire.push(
          apiCall(apiGetHoraireObject).then((result) => {
            const horaire = JSON.parse(result.data.response);
            const dateofYear = moment(
              moment().format("YYYY") + "/01/01",
              "YYYY/MM/DD"
            );
            const dateofPreviousYear = moment(
              moment().format("YYYY") + "/01/01",
              "YYYY/MM/DD"
            ).add(1, "year");
            horaireListWithValidity.push({
              titre: horaire.titre,
              validite: horaire.validite,
            });
            // On récupère les intervalles de validités concernant l'année en cours
            horaire.validite.forEach((validite: any) => {
              if (
                moment(validite[1], "DD/MM/YYYY").isAfter(dateofYear) &&
                moment(validite[0], "DD/MM/YYYY").isBefore(dateofPreviousYear)
              ) {
                validiteContinuite.push([
                  moment(validite[0], "DD/MM/YYYY"),
                  moment(validite[1], "DD/MM/YYYY"),
                ]);
              }
            });
          })
        );
      });
      await Promise.all(promiseHoraire);

      // On trie les intervalles
      validiteContinuite.sort((a: any, b: any) => {
        if (a[1].isAfter(b[0])) {
          return 1;
        }
        if (a[1].isBefore(b[0])) {
          return -1;
        }
        return 0;
      });

      var lastValidite = moment(
        moment().format("YYYY") + "/01/01",
        "YYYY/MM/DD"
      );
      var zoneDiscontinue: string[][] = [];
      var isContinue = true;

      validiteContinuite.forEach((validite, index: number) => {
        moment(lastValidite.format("DD-MM-YYYY"), "DD-MM-YYYY");
        if (
          !moment(lastValidite.format("DD-MM-YYYY"), "DD-MM-YYYY")
            .add(1, "day")
            .isSameOrAfter(validite[0])
        ) {
          isContinue = false;
          zoneDiscontinue.push([
            lastValidite.add(1, "day").format("DD-MM-YYYY"),
            validite[0].subtract(1, "day").format("DD-MM-YYYY"),
          ]);
        }
        lastValidite = validite[1];
      });

      if (
        !lastValidite.isSameOrAfter(
          moment(moment().format("YYYY") + "/12/31", "YYYY/MM/DD")
        )
      ) {
        zoneDiscontinue.push([
          lastValidite.add(1, "day").format("DD-MM-YYYY"),
          moment(
            moment().subtract(1, "day").format("YYYY") + "/12/31",
            "YYYY/MM/DD"
          ).format("DD-MM-YYYY"),
        ]);
        isContinue = false;
      }

      // Si les intervalles ne forment pas un ensemble continue sur l'année alors on alerte l'utilisateur
      if (!isContinue) {
        setDiscontinuousSchedule({
          discontinuousZone: zoneDiscontinue,
          horaire: horaireListWithValidity,
        });
        setDiscontinuousScheduleModal(true);
        setFilesLoad(true);
      } else {
        setFilesLoad(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //Obtient la liste des fichiers horaires du serveur, moins ceux qui sont déjà chargés
  const getHListe = async (noDupes = false) => {
    const apiListHoraireObject = {
      terminaison: "listHoraire",
    };

    // Récupère la liste des horaires
    const horaireListData = await apiCall(apiListHoraireObject);
    try {
      let horaireTitleList = JSON.parse(horaireListData.data.response).value;
      // Créé une liste avec les titres pour afficher dans le select
      let horaireListTitle = horaireTitleList.map((title: string) => (
        <MenuItem value={title} key={title}>
          {title}
        </MenuItem>
      ));

      setListHoraire(horaireListTitle);

      if (noDupes) {
        const fHoraires = [];
        for (let index = 0; index < fichierHoraire.length; index++) {
          const newHoraire = fichierHoraire[index];
          fHoraires.push(newHoraire.titre);
        }

        const newHoraireTitleList = [];
        // On retire les horaires ayant déjà était chargés
        for (let index = 0; index < horaireTitleList.length; index++) {
          const newTitle = horaireTitleList[index].replace(".json", "");
          if (!fHoraires.includes(newTitle) && newTitle !== lastName)
            newHoraireTitleList.push(newTitle + ".json");
        }
        horaireTitleList = newHoraireTitleList;
      }
      horaireListTitle = horaireTitleList.map((title: string) => (
        <MenuItem value={title} key={title}>
          {title}
        </MenuItem>
      ));
      setChargeList(horaireListTitle);
      setCibleCharge(horaireTitleList[0]);
    } catch (error) {
      console.error(error);
    }
  };

  //Supprime un fichier horaire
  const supprH = (titre: string): any => {
    let infoKey = props.enqueueSnackbar("Suppression en cours", {
      variant: "info",
      autoHideDuration: 6000,
    });
    const apiDeleteHoraireObject = {
      terminaison: "deleteHoraire",
      title: titre,
      user:
        AuthenticationService.user.user.firstname +
        " " +
        AuthenticationService.user.user.name,
      details: "Delete " + titre,
    };
    apiCall(apiDeleteHoraireObject).then(() => {
      getHListe(true);
    });
    props.closeSnackbar(infoKey);
    sendLogs("Delete", "Suppression du fichier : " + titre);
    props.enqueueSnackbar("Suppresion terminée", {
      variant: "success",
      autoHideDuration: 3000,
    });
  };

  // Permet l'actualisation du state en mettant à jour l'horaire lors d'un changement dans le fichier Jour.tsx
  const updateList = async (newHoraire: any) => {
    setFichierHoraire(
      fichierHoraire.map((x: any, index: number) =>
        index === selected ? deepClone(newHoraire) : x
      )
    );
  };

  return (
    user &&
    user.role.droits.horaire.acces && (
      <Grid container direction="column">
        {/* Horaire */}
        <Grid
          container
          style={{
            backgroundColor: "#eeeeee",
            borderColor: "black",
            borderRadius: "0px 0px 5px 5px",
            boxShadow: "0px 2px 2px #eeeeee",
            top: "0px",
            position: "sticky",
            zIndex: 1,
          }}
        >
          {user.role.droits.horaire.ajout && filesLoad && (
            <Grid
              item
              xs={3}
              md={3}
              style={{ textAlign: "center" }}
              className={props.classes.horaireTopBar}
            >
              <AjoutFHoraire
                newF={addHoraire}
                titles={chargeList.map((horaire: any) =>
                  horaire.key.replace(".json", "")
                )}
              />
            </Grid>
          )}
          <Grid
            item
            md={2}
            xs={4}
            style={{ textAlign: "center" }}
            className={props.classes.horaireTopBar}
          >
            {fichierHoraire.length > 0 && <>{listHoraires()}</>}
          </Grid>
          <Grid item md={7} xs={5} className={props.classes.horaireTopBar}>
            <Grid
              container
              alignItems="center"
              alignContent="center"
              justifyContent="center"
            >
              <Grid item md={5} style={{ textAlign: "right" }}>
                {chargeList.length !== 0 &&
                  filesLoad &&
                  (user.role.droits.horaire.lecture ||
                    user.role.droits.horaire.edition) && (
                    <ChargeFHoraire
                      chargeList={chargeList}
                      cibleCharge={cibleCharge}
                      loadH={loadH}
                    />
                  )}
              </Grid>

              {user.role.droits.horaire.sauvegarde &&
                fichierHoraire.length !== 0 &&
                !petit && (
                  <>
                    <hr />
                    <Grid item lg={4} xs={6} style={{ textAlign: "left" }}>
                      <Grid container>
                        <Grid item lg={4} xs={5}>
                          <Tooltip title="Sauvegarder le fichier">
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={saveHoraire}
                            >
                              <Save />
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid item lg={6} xs={5}>
                          <Tooltip title="Supprimer définitivement le fichier">
                            <Button
                              variant="outlined"
                              size="small"
                              color="secondary"
                              onClick={() => {
                                setDeleteModal(true);
                              }}
                            >
                              <DeleteSweep />
                            </Button>
                          </Tooltip>
                          <ModalDeleteHoraire
                            open={deleteModal}
                            deleteHoraire={modalDelete}
                            closeModal={closeModalDelete}
                            nom={fichierHoraire[selected].titre}
                          ></ModalDeleteHoraire>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
            </Grid>
          </Grid>
        </Grid>
        {user.role.droits.horaire.lecture && (
          <Grid item>
            {fichierHoraire.length > 0 && fichierHoraire[selected] ? (
              <Paper
                style={{ padding: "5px", minHeight: mini ? "150vh" : "95vh" }}
              >
                <Grid container spacing={1} direction="column">
                  <HoraireInformation
                    horaire={fichierHoraire[selected]}
                    updateList={updateList}
                    changeLastName={changeLastName}
                    horaires={fichierHoraire}
                    deleteModal={deleteModal}
                    deleteHoraire={modalDelete}
                    closeModal={closeModalDelete}
                    classes={props.classes}
                    listHoraire={listHoraire}
                    cibleCharge={cibleCharge}
                    setCibleCharge={setCibleCharge}
                  />
                  {user.role.droits.horaire.ajout && (
                    <Grid item>
                      <AjoutJForm
                        newFDay={newFDay}
                        deleteHoraireDay={deleteHoraireDay}
                        fHoraire={fichierHoraire[selected]}
                      />
                    </Grid>
                  )}
                  {fichierHoraire[selected].jours.map(
                    (jour: any, index: number) => (
                      <Grid item key={Math.random() + index} xs={12}>
                        <Accordion
                          key={index}
                          expanded={expended === index}
                          TransitionProps={{ unmountOnExit: true }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-label="expand"
                            style={{
                              backgroundColor: jour.veille
                                ? "#DFDFDF"
                                : !jour.nomOctime
                                ? "#FFDFDF"
                                : "#DFDFFF",
                            }}
                            onClick={() => changeAccordion(index)}
                          >
                            <EditableJ
                              expended={expended === index}
                              editFDay={editFDay}
                              fHoraire={fichierHoraire[selected]}
                              jour={jour}
                              newFDay={newFDay}
                              deleteHoraireDay={deleteHoraireDay}
                              classes={props.classes}
                            />
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              backgroundColor: jour.veille
                                ? "#DFDFDF"
                                : !jour.nomOctime
                                ? "#FFDFDF"
                                : "#DFDFFF",
                            }}
                          >
                            <Jour
                              updateList={updateList}
                              horaire={fichierHoraire[selected]}
                              jour={jour}
                              position={index}
                            ></Jour>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )
                  )}
                </Grid>
              </Paper>
            ) : (
              <Grid
                container
                alignContent="center"
                justifyContent="center"
                style={{ height: `80vh` }}
              >
                <p>Aucun horaire choisi</p>
              </Grid>
            )}
          </Grid>
        )}
        {(user.role.droits.horaire.lecture ||
          user.role.droits.horaire.edition) &&
          fichierHoraire.length > 0 && (
            <Grid item>
              <Paper>{fichierHoraire[selected].printHoraire()}</Paper>
              <br />
            </Grid>
          )}
        <ModalDiscontinuousSchedule
          open={discontinuousScheduleModal}
          closeModal={closeModalDiscontinuousSchedule}
          discontinuousSchedule={discontinuousSchedule}
        />
        <ModalIntervalCrossing
          open={intervalCrossingModal}
          closeModal={closeIntervalCrossignModal}
          intervalles={intervalCrossing}
        />
      </Grid>
    )
  );
};

export default withSnackbar(Horaire);
