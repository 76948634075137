import {
  Grid,
  Button,
  Tooltip,
  Select,
  useMediaQuery,
  useTheme,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { Edit, Delete, InsertDriveFile } from "@material-ui/icons";
import { useEffect, useState } from "react";
import AuthenticationService from "../../Service/AuthenticationService";
import { EditableH, EditableValidite, AddValidite } from "../Form";
import { ModalDeleteHoraire, ModalError } from "../modal";
import FHoraire from "./FichierHoraire";
import XMLParser from "xml-js";
import deepClone from "lodash/cloneDeep";
import Download from "@material-ui/icons/GetApp";
import { apiCall } from "../../Service/ApiService";

const useStyles = makeStyles((theme) => ({
  delteUpload: {
    marginLeft: theme.spacing(2),
  },
}));

function isObjEmpty(obj: any) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }

  return true;
}

export const HoraireInformation = (props: any) => {
  const user = AuthenticationService.user;
  const [listHoraire, setListHoraire] = useState(props.listHoraire);
  const [uploadFile, setUploadFile] = useState({} as any);
  const [importer, setImport] = useState(false);
  const [editValidite, setEditValidite] = useState(-1);
  const [addValidite, setAddValidite] = useState(false);
  const [textError, setTextError] = useState("");
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [edittitre, setEditTitre] = useState(false);

  const theme = useTheme();
  const classes = useStyles();
  const petit = useMediaQuery(theme.breakpoints.down("md"));

  const openModalError = () => {
    setOpenErrorModal(true);
  };

  const closeModalError = () => {
    setOpenErrorModal(false);
  };

  const getListHoraireSave = async () => {
    const apiObject = {
      terminaison: "listHoraire",
    };
    const hListeData = await apiCall(apiObject);
    try {
      const hListe = JSON.parse(hListeData.data.response).value;
      const hListeItems = hListe.map((x: any) => (
        <MenuItem value={x} key={x}>
          {x}
        </MenuItem>
      ));
      setListHoraire(hListeItems);
      return hListeItems;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getListHoraireSave();
  }, []);

  //Edite le titre de l'horaire et si c'est un jour de grève ou non
  const editVal = (titre: any, lastTitle: any, strike: boolean) => {
    props.horaire.editVal(titre);
    props.horaire.titre = titre;
    props.horaire.strike = strike;
    const newHoraire = deepClone(props.horaire);
    setEditTitre(false);
    props.changeLastName(lastTitle);
    props.updateList(newHoraire);
  };

  const editValiditeH = (index: number, debut: string, fin: string) => {
    props.horaire.editValidite(index, [debut, fin]);
    props.horaire.validite[index] = [debut, fin];
    const newHoraire = deepClone(props.horaire);
    props.updateList(newHoraire);
    setEditValidite(-1);
  };

  const addValiditeH = (debut: string, fin: string) => {
    props.horaire.editValidite([debut, fin]);
    props.horaire.validite.push([debut, fin]);
    const newHoraire = deepClone(props.horaire);
    props.updateList(newHoraire);
    setAddValidite(false);
  };

  const deleteValiditeH = (index: number) => {
    props.horaire.deleteValidite(index);
    const newHoraire = deepClone(props.horaire);
    props.updateList(newHoraire);
    setAddValidite(false);
  };

  const exitEditTV = () => {
    setEditTitre(false);
  };
  const exitValidite = () => {
    setEditValidite(-1);
  };

  const exitAddValidite = () => {
    setAddValidite(false);
  };

  const loadFile = (newFile: any) => {
    try {
      const fileReader = new FileReader();
      fileReader.readAsText(newFile.target.files[0], "UTF-8");
      fileReader.onload = (newFile) => {
        if (typeof newFile.target?.result === "string") {
          try {
            var json = JSON.parse(
              XMLParser.xml2json(newFile.target?.result, {
                compact: true,
                spaces: 4,
              })
            );
            var finalJSON: any = {};
            try {
              finalJSON = {
                titre: json.horaire._attributes.Titre,
                version: "A",
                strike:
                  json.horaire._attributes.Greve === "false" ? false : true,
                validite: [
                  [
                    json.horaire.Validite.Dates._attributes.debut,
                    json.horaire.Validite.Dates._attributes.fin,
                  ],
                ],
                jours: [],
              };
            } catch (e) {
              const validiteH: any[] = [];

              json.horaire.Validite.Dates.forEach((validite: any) => {
                validiteH.push([
                  validite._attributes.debut,
                  validite._attributes.fin,
                ]);
              });

              finalJSON = {
                titre: json.horaire._attributes.Titre,
                version: "A",
                validite: validiteH,
                strike:
                  json.horaire._attributes.Greve === "false" ? false : true,
                jours: [],
              };
            }

            json.horaire.jour.forEach((jour: any) => {
              var newJour: any = {
                nom: jour._attributes.nom,
                nomOctime: jour._attributes.nomOctime,
                valeur: parseInt(jour._attributes.ValeurParDefaut),
                veille:
                  jour._attributes.nom.indexOf("Veille") !== -1 ? true : false,
                ExtPcMini: isNaN(parseInt(jour._attributes.ExtPcMini))
                  ? 0
                  : parseInt(jour._attributes.ExtPcMini),
                sousJours: [],
              };

              if (jour.SousJour.length !== undefined) {
                jour.SousJour.forEach((sousJour: any) => {
                  var nbrExtension = 0;
                  if (sousJour._attributes.extension === "true") {
                    nbrExtension = parseInt(
                      sousJour._attributes.nbrePCExtension
                    );
                  }
                  var newSousJour: any = {
                    nPExt: nbrExtension,
                    periodes: [],
                    cds: sousJour._attributes.cds === "true" ? true : false,
                  };
                  if (sousJour.periodes.periode.length !== undefined) {
                    sousJour.periodes.periode.forEach((periode: any) => {
                      if (
                        periode._attributes.etatAvance === "W" ||
                        periode._attributes.etatAvance === "C"
                      ) {
                        const newPeriode = {
                          debut: periode._attributes.debut,
                          fin: periode._attributes.fin,
                          etat: periode._attributes.etatAvance,
                        };
                        newSousJour.periodes.push(newPeriode);
                      }
                    });
                  } else {
                    if (
                      sousJour.periodes.periode._attributes.etatAvance ===
                        "W" ||
                      sousJour.periodes.periode._attributes.etatAvance === "C"
                    ) {
                      const newPeriode = {
                        debut: sousJour.periodes.periode._attributes.debut,
                        fin: sousJour.periodes.periode._attributes.fin,
                        etat: sousJour.periodes.periode._attributes.etatAvance,
                      };
                      newSousJour.periodes.push(newPeriode);
                    }
                  }
                  newJour.sousJours.push(newSousJour);
                });
              } else {
                var nbrExtension = 0;
                if (jour.SousJour._attributes.extension === "true") {
                  nbrExtension = parseInt(
                    jour.SousJour._attributes.nbrePCExtension
                  );
                }
                const newSousJour: any = {
                  nPExt: nbrExtension,
                  periodes: [],
                  cds: jour.SousJour._attributes.cds === "true" ? true : false,
                };
                if (jour.SousJour.periodes.periode.length !== undefined) {
                  jour.SousJour.periodes.periode.forEach((periode: any) => {
                    if (
                      periode._attributes.etatAvance === "W" ||
                      periode._attributes.etatAvance === "C"
                    ) {
                      const newPeriode = {
                        debut: periode._attributes.debut,
                        fin: periode._attributes.fin,
                        etat: periode._attributes.etatAvance,
                      };
                      newSousJour.periodes.push(newPeriode);
                    }
                  });
                } else {
                  if (
                    jour.SousJour.periodes.periode._attributes.etatAvance ===
                      "W" ||
                    jour.SousJour.periodes.periode._attributes.etatAvance ===
                      "C"
                  ) {
                    const newPeriode = {
                      debut: jour.SousJour.periodes.periode._attributes.debut,
                      fin: jour.SousJour.periodes.periode._attributes.fin,
                      etat: jour.SousJour.periodes.periode._attributes
                        .etatAvance,
                    };
                    newSousJour.periodes.push(newPeriode);
                  }
                }
                newJour.sousJours.push(newSousJour);
              }
              finalJSON.jours.push(newJour);
            });
            setUploadFile(finalJSON);
          } catch (error) {
            console.error(error);
            setTextError("Invalid xml file");
            openModalError();
          }
        }
      };
    } catch (e) {}
  };

  //Importe les jours d'un autre fichier horaire
  const importH = async (file: any) => {
    const apiObject = {
      terminaison: "getHoraire",
      title: file,
    };
    let tempHoraire = await apiCall(apiObject).then((result) => {
      return JSON.parse(result.data.response);
    });
    tempHoraire = new FHoraire(tempHoraire);
    let tHoraire = deepClone(props.horaire);
    tHoraire.jours = tempHoraire.jours;
    tHoraire.strike = tempHoraire.strike;
    props.updateList(tHoraire);
  };

  return (
    <Grid item>
      {props.horaire.strike && (
        <h2 style={{ textAlign: "center", color: "red" }}>
          {props.horaire.titre}
        </h2>
      )}
      {!props.horaire.strike && (
        <h2 style={{ textAlign: "center" }}>{props.horaire.titre}</h2>
      )}

      <>
        {user.role.droits.horaire.edition && edittitre && !addValidite ? (
          <EditableH
            val={props.horaire.validite}
            editVal={editVal}
            titre={props.horaire.titre}
            strike={props.horaire.strike}
            exit={exitEditTV}
          />
        ) : (
          <>
            <div style={{ textAlign: "center" }}>
              {user.role.droits.horaire.edition &&
                !edittitre &&
                editValidite === -1 &&
                props.horaire.validite.map((validite: any, index: number) => {
                  return (
                    <div
                      className={props.classes.horaireValiditeSpace}
                      key={index}
                    >
                      {`Valide du ${validite[0]} au ${validite[1]}`}
                      {!addValidite && (
                        <Button
                          variant="outlined"
                          size="small"
                          className={props.classes.horaireValidite}
                          onClick={() => {
                            setEditValidite(index);
                          }}
                          style={{
                            maxWidth: "25px",
                            maxHeight: "25px",
                            minWidth: "25px",
                            minHeight: "25px",
                          }}
                        >
                          <Edit
                            style={{
                              maxWidth: "15px",
                              maxHeight: "15px",
                              minWidth: "15px",
                              minHeight: "15px",
                            }}
                          />
                        </Button>
                      )}

                      {props.horaire.validite.length > 1 && !addValidite && (
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          className={props.classes.horaireValidite}
                          onClick={() => {
                            deleteValiditeH(index);
                          }}
                          style={{
                            maxWidth: "25px",
                            maxHeight: "25px",
                            minWidth: "25px",
                            minHeight: "25px",
                          }}
                        >
                          <Delete
                            style={{
                              maxWidth: "15px",
                              maxHeight: "15px",
                              minWidth: "15px",
                              minHeight: "15px",
                            }}
                          />
                        </Button>
                      )}
                      <br />
                    </div>
                  );
                })}
              {user.role.droits.horaire.edition &&
                !edittitre &&
                editValidite !== -1 && (
                  <EditableValidite
                    debut={props.horaire.validite[editValidite][0]}
                    fin={props.horaire.validite[editValidite][1]}
                    editValidite={editValiditeH}
                    index={editValidite}
                    exit={exitValidite}
                  />
                )}

              {user.role.droits.horaire.edition &&
                !edittitre &&
                editValidite === -1 &&
                addValidite && (
                  <AddValidite
                    exit={exitAddValidite}
                    addValiditeH={addValiditeH}
                  />
                )}

              <br />
              <br />
              {user.role.droits.horaire.edition &&
                !edittitre &&
                editValidite === -1 &&
                !addValidite && (
                  <>
                    {importer ? (
                      <Grid
                        container
                        alignItems="center"
                        alignContent="center"
                        justifyContent="center"
                      >
                        <Grid item md={2} xs={1} lg={3}></Grid>
                        <Grid item md={1} xs={2} lg={1}>
                          <Tooltip
                            title={"Uploader un fichier horaire (en xml)"}
                          >
                            <Button
                              variant="contained"
                              size="small"
                              component="label"
                              style={{
                                maxWidth: "35px",
                                maxHeight: "35px",
                                minWidth: "35px",
                                minHeight: "35px",
                              }}
                            >
                              <form>
                                <input
                                  accept=".xml"
                                  id="contained-button-file"
                                  multiple
                                  type="file"
                                  hidden
                                  onChange={(newFile: any) => {
                                    loadFile(newFile);
                                    newFile.target.value = "";
                                  }}
                                />
                              </form>
                              <InsertDriveFile />
                            </Button>
                          </Tooltip>
                        </Grid>

                        {isObjEmpty(uploadFile) && (
                          <Grid item md={2} xs={3} lg={2}>
                            {listHoraire && listHoraire.length !== 0 && (
                              <Select
                                value={props.cibleCharge}
                                onChange={(e: any) =>
                                  props.setCibleCharge(e.target.value)
                                }
                              >
                                {listHoraire}
                              </Select>
                            )}
                          </Grid>
                        )}
                        {!isObjEmpty(uploadFile) && (
                          <Grid item md={3} xs={3} lg={3}>
                            <span>{uploadFile.titre}</span>
                            <Button
                              variant="contained"
                              size="small"
                              color="secondary"
                              onClick={() => {
                                setUploadFile({});
                              }}
                              style={{
                                maxWidth: "25px",
                                maxHeight: "25px",
                                minWidth: "25px",
                                minHeight: "25px",
                              }}
                              className={classes.delteUpload}
                            >
                              X
                            </Button>
                          </Grid>
                        )}

                        <Grid item md={6} sm={6} lg={3}>
                          <Grid container>
                            <Grid item sm={3} xs={6}>
                              <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={() => {
                                  if (isObjEmpty(uploadFile)) {
                                    importH(props.cibleCharge);
                                  } else {
                                    var tempHoraire = new FHoraire(uploadFile);
                                    let tHoraire = deepClone(props.horaire);
                                    tHoraire.jours = tempHoraire.jours;
                                    tHoraire.strike = tempHoraire.strike;
                                    tHoraire.validite = tempHoraire.validite;
                                    const newHoraire = deepClone(tHoraire);
                                    props.updateList(newHoraire);
                                  }
                                  setImport(false);
                                  setUploadFile({});
                                }}
                              >
                                OK
                              </Button>
                            </Grid>
                            <Grid item sm={3} xs={2}>
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                  setUploadFile({});
                                  setImport(false);
                                }}
                              >
                                Annuler
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={2} xs={1} lg={2}></Grid>
                      </Grid>
                    ) : (
                      <Grid
                        container
                        alignItems="center"
                        alignContent="center"
                        justifyContent="center"
                      >
                        <Grid item lg={1} sm={2} xs={3}>
                          <Tooltip
                            title={
                              "Importer les jours, sous-jours et périodes d'un horaire"
                            }
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={async () => {
                                setImport(true);
                                const tab = listHoraire.filter(
                                  (element: any) =>
                                    element.key !==
                                    props.horaire.titre + ".json"
                                );
                                setListHoraire(tab);
                              }}
                            >
                              Importer
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid item lg={1} sm={2} xs={3}>
                          <Tooltip title="Editer nom de l'horaire">
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={() => setEditTitre(true)}
                            >
                              Editer
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid item lg={1} sm={2} xs={3}>
                          <Tooltip title="Ajouter un nouvel intervalle de validité">
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={() => setAddValidite(true)}
                            >
                              +
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid item lg={1} sm={2} xs={3}>
                          <Tooltip title="Télécharger l'horaire (en format json)">
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              type="button"
                              href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                JSON.stringify(props.horaire)
                              )}`}
                              download={`${props.horaire.titre}.json`}
                            >
                              <Download
                                style={{
                                  width: "30px",
                                  height: "20px",
                                }}
                              />
                            </Button>
                          </Tooltip>
                        </Grid>

                        {user.role.droits.horaire.sauvegarde &&
                          props.horaires.length !== 0 &&
                          petit && (
                            <ModalDeleteHoraire
                              open={props.deleteModal}
                              deleteHoraire={props.modalDelete}
                              closeModal={props.closeModalDelete}
                              nom={props.horaire.titre}
                            ></ModalDeleteHoraire>
                          )}
                      </Grid>
                    )}
                  </>
                )}
            </div>
          </>
        )}
      </>
      {openErrorModal && (
        <ModalError
          open={openErrorModal}
          closeModal={closeModalError}
          textError={textError}
        />
      )}
    </Grid>
  );
};
