import { Grid, Button, Divider, Paper, useMediaQuery } from "@material-ui/core";
import { useState } from "react";
import icon from "../Images/logo3.png";
import { Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import ManageAccounts from "@material-ui/icons/Group";
import Calendar from "@material-ui/icons/CalendarToday";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Uceso from "@material-ui/icons/ViewCompact";
import AuthenticationService from "../Service/AuthenticationService";
import GestionUceso from "./Uceso/GestionUceso";
import BarChart from "@material-ui/icons/BarChart";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import DeleteIcon from "@material-ui/icons/DeleteSweep";
import TestHoraire from "@material-ui/icons/Layers";
import Save from "@material-ui/icons/Save";
import { apiCall } from "../Service/ApiService";

const environnement = process.env.REACT_APP_DEV || "";
const version = process.env.REACT_APP_VERSION_TAG || "";

const NavBar = (props: any) => {
  //Menu de navigation sur la gauche de la fenêtre

  const user = AuthenticationService.user;
  const theme = useTheme();
  const petit = useMediaQuery(theme.breakpoints.down("md"));

  const [nouveauGraph, setNouveauGraph] = useState(false);
  const [graphOuvert, setGraphOuvert] = useState(false);

  const closeNouveauGraph = () => {
    setNouveauGraph(false);
  };

  const closeGraphOuvert = () => {
    setGraphOuvert(false);
  };

  return (
    <>
      {user && !petit && (
        <Grid
          style={{
            minHeight: "100vh",
            backgroundColor: "#2D3E4E",
            height: "100%",
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <i>
                <Typography style={{ color: "white" }}>{version}</Typography>
              </i>
            </Grid>
            <Grid item xs={12} className={props.classes.horaireTopBar}>
              <img
                className={props.classes.img}
                src={icon}
                alt="Uceso RH"
                style={{ borderRadius: "5%" }}
              />
            </Grid>
            <Grid
              item
              style={{ textAlign: "center", color: "white" }}
              className={props.classes.horaireTopBar}
            >
              Connecté en tant que : <br />
              {user.user.firstname} - {user.role.nom}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                onClick={async () => {
                  if (window.location.hostname !== "localhost") {
                    const apiObject = {
                      terminaison: "logout",
                      clientId: environnement,
                      idToken: AuthenticationService.user.keyCloakToken,
                    };
                    const response = await apiCall(apiObject);

                    const url = JSON.parse(response.data.response).url;
                    window.location.assign(url);
                    AuthenticationService.isAuthenticated = false;
                  } else {
                    window.location.assign("http://localhost:3000/login");
                    AuthenticationService.isAuthenticated = false;
                  }
                }}
                className={props.classes.horaireTopBar}
              >
                <LogoutIcon />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                <Grid item xs={10}>
                  <Divider style={{ backgroundColor: "white" }} />
                </Grid>
                <Grid item lg={7} md={10} style={{ textAlign: "center" }}>
                  <Button
                    disabled={props.mode === "U"}
                    size="medium"
                    fullWidth={true}
                    variant="contained"
                    onClick={() => props.handleModeChange("U")}
                    className={props.classes.horaireTopBar}
                  >
                    <Typography>Uceso</Typography>
                  </Button>
                </Grid>
                {user.role.droits.horaire.acces && (
                  <Grid item lg={7} md={10} style={{ textAlign: "center" }}>
                    <Button
                      size="medium"
                      disabled={props.mode === "H"}
                      fullWidth={true}
                      variant="contained"
                      onClick={() => props.handleModeChange("H")}
                      className={
                        props.classes.change && props.classes.horaireTopBar
                      }
                    >
                      <Typography>Horaire</Typography>
                    </Button>
                  </Grid>
                )}
                {user.role.droits.droits.acces && (
                  <Grid item lg={7} md={10} style={{ textAlign: "center" }}>
                    <Button
                      size="medium"
                      variant="contained"
                      disabled={props.mode === "D"}
                      fullWidth={true}
                      onClick={() => props.handleModeChange("D")}
                      className={
                        props.classes.change && props.classes.horaireTopBar
                      }
                    >
                      <Typography>Droits</Typography>
                    </Button>
                  </Grid>
                )}
                {user.role.droits.logs.acces && (
                  <Grid item lg={7} md={10} style={{ textAlign: "center" }}>
                    <Button
                      size="medium"
                      disabled={props.mode === "L"}
                      fullWidth={true}
                      variant="contained"
                      onClick={() => props.handleModeChange("L")}
                      className={
                        props.classes.change && props.classes.horaireTopBar
                      }
                    >
                      <Typography>Logs</Typography>
                    </Button>
                  </Grid>
                )}
                {user.role.droits.Testhoraire.acces && (
                  <Grid item lg={7} md={10} style={{ textAlign: "center" }}>
                    <Button
                      size="medium"
                      fullWidth={true}
                      disabled={props.mode === "TH"}
                      variant="contained"
                      onClick={() => props.handleModeChange("TH")}
                      className={
                        props.classes.change && props.classes.horaireTopBar
                      }
                    >
                      <Typography>Test Horaire</Typography>
                    </Button>
                  </Grid>
                )}
                <Grid item xs={10}>
                  <Divider
                    className={props.classes.horaireTopBar}
                    style={{ backgroundColor: "white" }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {props.mode === "U" && user.role.droits.uceso.acces && (
              <Grid container>
                <GestionUceso
                  mode={props.mode}
                  horaireChange={props.horaireChange}
                  date={props.date}
                  horaire={props.horaire}
                  dateCalc={props.dateCalc}
                  addUceDay={props.addUceDay}
                  removeUceDay={props.removeUceDay}
                  saveUceDay={props.saveUceDay}
                  uceDays={props.uceDays}
                  ucesoSalto={props.ucesoSalto}
                  handleModeChange={props.handleModeChange}
                  classes={props.classes}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {user && petit && (
        <Paper
          style={{
            position: "fixed",
            zIndex: 0,
            bottom: 0,
            minWidth: "100vw",
            overflowY: "scroll",
          }}
          elevation={0}
        >
          {(props.mode === "U" ||
            (props.mode === "H" && props.currentHoraire !== null)) && (
            <BottomNavigation showLabels style={{ backgroundColor: "#2D3E4E" }}>
              {props.mode === "U" && (
                <GestionUceso
                  mode={props.mode}
                  horaireChange={props.horaireChange}
                  date={props.date}
                  horaire={props.horaire}
                  dateCalc={props.dateCalc}
                  addUceDay={props.addUceDay}
                  removeUceDay={props.removeUceDay}
                  saveUceDay={props.saveUceDay}
                  handleModeChange={props.handleModeChange}
                  ucesoSalto={props.ucesoSalto}
                  classes={props.classes}
                  uceDays={props.uceDays}
                  nouveauGraph={nouveauGraph}
                  graphOuvert={graphOuvert}
                  closeGraphOuvert={closeGraphOuvert}
                  closeNouveauGraph={closeNouveauGraph}
                />
              )}

              {user.role.droits.uceso.lecture && props.mode === "U" && (
                <BottomNavigationAction
                  style={{ color: "white" }}
                  label="Nouveau graphe"
                  icon={<BarChart />}
                  onClick={() => {
                    setNouveauGraph(true);
                  }}
                />
              )}
              {user.role.droits.uceso.lecture && props.mode === "U" && (
                <BottomNavigationAction
                  style={{ color: "white" }}
                  label="Graphes ouverts"
                  icon={<FormatListBulleted />}
                  onClick={() => {
                    setGraphOuvert(true);
                  }}
                />
              )}

              {user.role.droits.horaire.sauvegarde &&
                props.mode === "H" &&
                props.currentHoraire !== null && (
                  <BottomNavigationAction
                    style={{ color: "white" }}
                    label="Enregistrer l'horaire"
                    icon={<Save color="primary" />}
                    onClick={() => {
                      props.execSaveHoraire();
                    }}
                  />
                )}
              {user.role.droits.horaire.suppr &&
                props.mode === "H" &&
                props.currentHoraire !== null && (
                  <BottomNavigationAction
                    style={{ color: "white", fontSize: 3 }}
                    label="Supprimer l'horaire"
                    icon={<DeleteIcon color="secondary" />}
                    onClick={() => {
                      props.execDeleteHoraire();
                    }}
                  />
                )}
            </BottomNavigation>
          )}
          <BottomNavigation showLabels style={{ backgroundColor: "#2D3E4E" }}>
            <BottomNavigationAction
              style={{ color: "white" }}
              label="Déconnexion"
              icon={<LogoutIcon />}
              onClick={async () => {
                if (window.location.hostname !== "localhost") {
                  const apiObject = {
                    terminaison: "logout",
                    clientId: environnement,
                    idToken: AuthenticationService.user.keyCloakToken,
                  };
                  const response = await apiCall(apiObject);

                  const url = JSON.parse(response.data.response).url;
                  window.location.assign(url);
                  AuthenticationService.isAuthenticated = false;
                } else {
                  window.location.assign("http://localhost:3000/login");
                  AuthenticationService.isAuthenticated = false;
                }
              }}
            />

            {(user.role.droits.horaire.acces ||
              user.role.droits.droits.acces) &&
              props.mode !== "U" && (
                <BottomNavigationAction
                  style={{ color: "white" }}
                  label="Uceso"
                  onClick={() => props.handleModeChange("U")}
                  icon={<Uceso />}
                />
              )}

            {user.role.droits.horaire.acces && props.mode !== "H" && (
              <BottomNavigationAction
                style={{ color: "white" }}
                onClick={() => props.handleModeChange("H")}
                label="Horaire"
                icon={<Calendar />}
              />
            )}
            {user.role.droits.droits.acces && props.mode !== "D" && (
              <BottomNavigationAction
                style={{ color: "white" }}
                onClick={() => props.handleModeChange("D")}
                label="Droits"
                icon={<ManageAccounts />}
              />
            )}

            {user.role.droits.Testhoraire.acces && props.mode !== "TH" && (
              <BottomNavigationAction
                style={{ color: "white" }}
                onClick={() => props.handleModeChange("TH")}
                label="Test Horaire"
                icon={<TestHoraire />}
              />
            )}

            {user.role.droits.logs.acces && props.mode !== "L" && (
              <BottomNavigationAction
                style={{ color: "white" }}
                onClick={() => props.handleModeChange("L")}
                label="Logs"
                icon={<TestHoraire />}
              />
            )}
          </BottomNavigation>
        </Paper>
      )}
    </>
  );
};

export default NavBar;
