import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
} from "@material-ui/core";

export const ErrorModal = (props: any) => {
  return (
    props.open && (
      <Dialog
        open={props.open}
        onClose={props.closeModal}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ textAlign: "center" }}>
          Une erreur est survenue
          <Divider />
        </DialogTitle>

        <DialogContent>
          <DialogContentText style={{ textAlign: "left" }}>
            {props.texte}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                props.closeModal();
              }}
              color="secondary"
            >
              Fermer
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  );
};
