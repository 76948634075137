import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { ModalChoiceOpenHoraire, ModalChangeDate } from "../Composant/modal";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Minus from "@material-ui/icons/Remove";
import More from "@material-ui/icons/Add";
import moment from "moment";
import UcesoGraph from "../Composant/Uceso/UcesoGraph";
import { useSnackbar } from "notistack";
import { calculUceso } from "../Composant/Uceso/UcesoTestShareFonction";
import { apiCall } from "../Service/ApiService";
//Composant affichant le graphique des uceso d'une journée

const TestHoraire = (props: any) => {
  const [horaire, setHoraire] = useState({} as any);
  const [choiceHoraire, setChoiceHoraire] = useState(false);
  const [changeDate, setChangeDate] = useState(false);
  const [repartition, setRepartition] = useState([] as any);

  const [repartitionMax] = useState([] as any);

  const [uceso, setUceso] = useState([] as any);
  const [besoinFMP, setBesoinFMP] = useState([] as any);
  const [nop, setNop] = useState([] as any);
  const [date, setDate] = useState(moment());

  const { enqueueSnackbar } = useSnackbar();

  const closeModalChoiceHoraire = () => {
    setChoiceHoraire(false);
  };

  const closeModalChangeDate = () => {
    setChangeDate(false);
  };

  const getNewDate = (newDate: any) => {
    setDate(newDate);
    getCsvFile(newDate);
    setChangeDate(false);
  };

  const getCsvFile = async (newDate: any = moment()) => {
    try {
      const apiNOPObject = {
        terminaison: "nop",
        date: newDate.format("M/D/YYYY"),
      };
      let nop = apiCall(apiNOPObject);
      setNop(JSON.parse((await nop).data.response));
    } catch (error) {
      setNop([]);
    }
    try {
      const apiBesoinFMPObject = {
        terminaison: "besoinFMP",
        date: newDate.format("M/D/YYYY"),
      };
      let besoin_FMP = apiCall(apiBesoinFMPObject);
      setBesoinFMP(JSON.parse((await besoin_FMP).data.response));
    } catch (error) {
      setBesoinFMP([]);
    }
  };

  const getHoraire = async (newHoraire: any) => {
    setHoraire({});
    repartitionMax.length = 0;
    await getCsvFile();
    const newReparition: any = [];
    newHoraire.jours.forEach((jours: any) => {
      newReparition.push(0);
      const nbrExtension = jours.sousJours.filter(
        (element: any) => element.nPExt >= 1
      );
      if (jours.sousJours.length === nbrExtension.length) {
        repartitionMax.push(nbrExtension.length);
      } else {
        repartitionMax.push(0);
      }
    });

    setRepartition(newReparition);
    setHoraire(newHoraire);
    setUceso([]);
    closeModalChoiceHoraire();
  };

  const repartitionAgent = async (newRepartition: any) => {
    const newPersonnel: any[] = [];
    const newPrecPersonnel: any[] = [];

    newRepartition.forEach((nombre: number, i: number) => {
      const tab = [];
      var compte = 0;
      let cds = -1;
      horaire.jours[i].sousJours.forEach((sJ: any, index: number) => {
        if (sJ.cds === true) cds = index;
      });

      if (cds !== -1 && nombre > 0) {
        tab.push({
          horaire: horaire.jours[i].veille
            ? horaire.jours[i].nom
            : horaire.jours[i].nomOctime,
          horaireNum: cds,
        });
        nombre--;
      }

      if (!horaire.jours[i].veille) {
        if (nombre !== 0) {
          if (nombre > horaire.jours[i].ExtPcMini) {
            for (let index = 0; index < nombre; index++) {
              tab.push({
                horaire: horaire.jours[i].nomOctime,
                horaireNum: compte,
              });
              compte++;
              if (cds !== -1 && compte === cds) compte++;
              if (compte >= horaire.jours[i].sousJours.length) {
                const total = horaire.jours[i].sousJours.filter(
                  (element: any) => element.nPExt >= 1
                );
                compte = total.length;
              }
            }
          } else {
            const total = horaire.jours[i].sousJours.filter(
              (element: any) => element.nPExt >= 1
            );
            compte = total.length;
            for (let index = 0; index < nombre; index++) {
              tab.push({
                horaire: horaire.jours[i].nomOctime,
                horaireNum: compte,
              });
              compte++;
              if (cds !== -1 && compte === cds) compte++;
              if (compte >= horaire.jours[i].sousJours.length) {
                const total = horaire.jours[i].sousJours.filter(
                  (element: any) => element.nPExt >= 1
                );
                compte = total.length;
              }
            }
          }
        }

        newPersonnel.push(tab);
      } else {
        for (let index = 0; index < nombre; index++) {
          tab.push({ horaire: horaire.jours[i].nom, horaireNum: compte });
          compte++;
          if (cds !== -1 && compte === cds) compte++;
          if (compte >= horaire.jours[i].sousJours.length) {
            const total = horaire.jours[i].sousJours.filter(
              (element: any) => element.nPExt >= 1
            );
            compte = total.length;
          }
        }
        newPrecPersonnel.push(tab);
      }
    });

    setUceso(
      calculUceso(
        horaire.jours,
        newPersonnel,
        newPrecPersonnel,
        moment().format("YYYYMMDD")
      )
    );
  };

  return (
    <>
      {!choiceHoraire && Object.keys(horaire).length !== 0 && (
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item sm={4}></Grid>
          <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
            <h1 style={{ textAlign: "center" }}>{horaire.titre}</h1>
          </Grid>
          <Grid item xs={12} sm={4} md={3} style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setChoiceHoraire(true);
              }}
            >
              Horaire
            </Button>
            <Button
              variant="contained"
              size="small"
              className={props.classes.horaireValidite}
              onClick={() => {
                setChangeDate(true);
              }}
            >
              Date
            </Button>
          </Grid>
          {repartition.map((jour: any, index: number) => {
            if (horaire.jours[index].valeur !== -1) {
              return (
                <Grid
                  item
                  xs={6}
                  md={3}
                  key={
                    index +
                    horaire.jours[index].nom +
                    horaire.jours[index].nomOctime +
                    horaire.jours[index].veille
                  }
                >
                  <Grid
                    container
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                    key={
                      index +
                      horaire.jours[index].nom +
                      horaire.jours[index].nomOctime +
                      horaire.jours[index].veille
                    }
                  >
                    <Grid
                      item
                      key={
                        index +
                        horaire.jours[index].nom +
                        horaire.jours[index].nomOctime +
                        horaire.jours[index].veille
                      }
                    >
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        style={{
                          maxWidth: "20px",
                          maxHeight: "20px",
                          minWidth: "20px",
                          minHeight: "20px",
                        }}
                        className={props.classes.jour}
                        onClick={async () => {
                          let newRepartitionTab: any = [].concat(repartition);

                          let newValue = newRepartitionTab[index] - 1;
                          if (newValue < 0) {
                            newValue = 0;
                          }

                          newRepartitionTab[index] = newValue;

                          try {
                            setRepartition(newRepartitionTab);
                            repartitionAgent(newRepartitionTab);
                          } catch (error) {
                            console.error(error);
                          }
                        }}
                      >
                        <Minus
                          style={{
                            maxWidth: "15px",
                            maxHeight: "15px",
                            minWidth: "15px",
                            minHeight: "15px",
                          }}
                        />
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          maxWidth: "20px",
                          maxHeight: "20px",
                          minWidth: "20px",
                          minHeight: "20px",
                        }}
                        className={props.classes.jour}
                        onClick={async () => {
                          let newRepartitionTab: any = [].concat(repartition);

                          if (
                            repartitionMax[index] !== 0 &&
                            newRepartitionTab[index] + 1 > repartitionMax[index]
                          ) {
                            enqueueSnackbar(
                              "Impossible d'ajouter une personne",
                              { variant: "error", autoHideDuration: 2000 }
                            );
                          } else {
                            let newValue = newRepartitionTab[index] + 1;

                            newRepartitionTab[index] = newValue;

                            try {
                              setRepartition(newRepartitionTab);
                              repartitionAgent(newRepartitionTab);
                            } catch (error) {
                              console.error(error);
                            }
                          }
                        }}
                      >
                        <More
                          style={{
                            maxWidth: "15px",
                            maxHeight: "15px",
                            minWidth: "15px",
                            minHeight: "15px",
                          }}
                        />
                      </Button>
                      <TextField
                        value={repartition[index]}
                        style={{
                          maxWidth: "60px",
                          maxHeight: "60px",
                          minWidth: "60px",
                          minHeight: "60px",
                          textAlign: "center",
                        }}
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          min: 0,
                        }}
                        onChange={(e) => {
                          let newRepartitionTab: any = [].concat(repartition);
                          let newValue = e.target.value;

                          newRepartitionTab[index] = newValue;

                          try {
                            setRepartition(newRepartitionTab);
                            repartitionAgent(newRepartitionTab);
                          } catch (error) {
                            console.error(error);
                          }
                        }}
                        className={props.classes.jour}
                      ></TextField>
                    </Grid>
                    <Grid item>{horaire.jours[index].nom}</Grid>
                  </Grid>
                </Grid>
              );
            } else {
              return (
                <div
                  key={
                    index +
                    horaire.jours[index].nom +
                    horaire.jours[index].nomOctime +
                    horaire.jours[index].veille
                  }
                ></div>
              );
            }
          })}
          {uceso.length !== 0 && (
            <UcesoGraph
              uceso={uceso}
              nop={nop}
              besoinFMP={besoinFMP}
              date={date}
            />
          )}
        </Grid>
      )}

      {Object.keys(horaire).length === 0 && (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          style={{ minHeight: "80vh" }}
        >
          <Grid item>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                setChoiceHoraire(true);
              }}
            >
              Choisir un fichier horaire
            </Button>
          </Grid>
        </Grid>
      )}

      <ModalChoiceOpenHoraire
        open={choiceHoraire}
        closeModal={closeModalChoiceHoraire}
        classes={props.classes}
        getHoraire={getHoraire}
      />
      <ModalChangeDate
        open={changeDate}
        closeModal={closeModalChangeDate}
        classes={props.classes}
        changeDate={getNewDate}
      />
    </>
  );
};

export default TestHoraire;
