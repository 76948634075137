import { TextField, Button, Grid } from "@mui/material";
import { Formik, FormikProps, Form } from "formik";
import * as Yup from "yup";
import { apiCall } from "../../Service/ApiService";
import * as sha512 from "js-sha512";
import { useState } from "react";
import { ErrorModal } from "./Modal/ErrorModal";
import { SuccessModal } from "./Modal/SuccessModal";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../Route/routes";

interface LostPasswordForm {
  password: string;
  checkPassword: string;
}

const FormChangePassword = (props: any) => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [texte, setTexte] = useState("");

  const closeErrorModal = () => {
    setError(false);
  };
  const closeSuccessModal = () => {
    setSuccess(false);
    history.push(PATHS.Login);
  };
  return (
    <>
      <Formik
        initialValues={{
          password: "",
          checkPassword: "",
        }}
        onSubmit={(values: LostPasswordForm, actions) => {
          const queryParams = new URLSearchParams(window.location.search);
          const token = queryParams.get("token") || "";
          const hashPassword = sha512.sha512(values.password);
          const apiLostPasswordObject = {
            terminaison: "newPasswordWithToken",
            token: token,
            password: hashPassword,
          };
          apiCall(apiLostPasswordObject)
            .then((result) => {
              try {
                setTexte("Votre mot de passe a bien été modifié !");
                setSuccess(true);
                actions.resetForm();
              } catch (error) {
                console.log(error);
                alert("Une error s'est produite !");
              }
            })
            .catch((error) => {
              try {
                if (
                  error.response.data.response ===
                  "The link has already been used or another more recent renewal email has been sent to you"
                ) {
                  setTexte(
                    "Le changement n'a pas pu être effectué. Le lien a déjà été utilisé ou un autre courriel de renouvellement plus récent vous a été envoyé."
                  );
                  setError(true);
                } else if (
                  error.response.data.response ===
                  "You have exceeded the one-day deadline to change your password. Please make an other request"
                ) {
                  setTexte(
                    "Vous avez dépassé les 24 heures de validité du lien. Merci de refaire une demande de perte de mot de passe."
                  );
                  setError(true);
                }
              } catch (error) {
                setTexte("Merci de rééssayer une erreur s'est produite. ");
                setError(true);
              }
            });
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .matches(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()/+-])/,
              "Minimum 1 majuscule, 1 minuscule, 1 nombre et 1 caractère spécial"
            )
            .min(8, "8 caractères minimum")
            .required("Entrez un mot de passe valide"),
          checkPassword: Yup.string()
            .oneOf(
              [Yup.ref("password"), null],
              "Les mots de passes doivent être identique"
            )
            .required("Entre un mot de passe de vérification valide"),
        })}
      >
        {(props: FormikProps<LostPasswordForm>) => {
          const { values, touched, errors, handleBlur, handleChange } = props;
          return (
            <Form noValidate>
              <Grid
                container
                alignContent="center"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    style={{ color: "white" }}
                    value={values.password}
                    helperText={
                      errors.password && touched.password ? errors.password : ""
                    }
                    error={errors.password && touched.password ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="checkPassword"
                    label="Confirm password"
                    name="checkPassword"
                    type="password"
                    style={{ color: "white" }}
                    value={values.checkPassword}
                    helperText={
                      errors.checkPassword && touched.checkPassword
                        ? errors.checkPassword
                        : ""
                    }
                    error={
                      errors.checkPassword && touched.checkPassword
                        ? true
                        : false
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} md={8} style={{ marginTop: "8px" }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <ErrorModal open={error} closeModal={closeErrorModal} texte={texte} />
      <SuccessModal
        open={success}
        closeModal={closeSuccessModal}
        texte={texte}
      />
    </>
  );
};

export default FormChangePassword;
