import React, { useEffect, useState } from "react";

import { apiCall } from "../Service/ApiService";
import Home from "./Home";
import BackupConnection from "./BackupConnection";

//const URL_TEST_SSO = process.env.REACT_APP_URL_TEST_SSO || "";

//Composant de switch entre angélique et la solution de connexion de secours
const HomeLogin = () => {
  const [SSOEnable, setSSOEnable] = useState(true);

  

  const testSSOEnable = async () => {
    const apiParams = {
      terminaison: "SSOStatus",
    };
    return apiCall(apiParams)
      .then((data) => {
        const status = JSON.parse(data.data.response).status;
        return status;
      })
      .catch((error) => {
        console.error(error);
      });
  };
  

  useEffect(() => {
    if (window.location.hostname === "localhost") setSSOEnable(false);
    else
      testSSOEnable().then((status) => {
        setSSOEnable(status);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {SSOEnable && <Home />}
      {!SSOEnable && <BackupConnection />}
    </>
  );
};

export default HomeLogin;
