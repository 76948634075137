import { useEffect } from "react";
import { Table, useMediaQuery } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Reinitialiser from "@material-ui/icons/SettingsBackupRestore";
import Absent from "@material-ui/icons/TransferWithinAStation";
import Checkbox from "@material-ui/core/Checkbox";
import Edit from "@material-ui/icons/Edit";
import { withSnackbar } from "notistack";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useState } from "react";
import { ModalAddAttribution, ModalEditAttribution } from "../modal";

const useStyles = makeStyles((theme) => ({
  bouton_ajout: {
    marginRight: theme.spacing(2),
  },
}));

export const AttributionPersonnel = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const petit = useMediaQuery(theme.breakpoints.down("md"));

  const [selected, setSelected] = useState([] as any);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [personnel, setPersonnel] = useState([] as any);
  const [listPersonnelAvailable, setListPersonnelAvailable] = useState(
    props.listOfUnassignedAgents
  );

  useEffect(() => {
    updateAttribution();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.uceDay.personnel, props.uceDay.precPersonnel]);

  useEffect(() => {
    setListPersonnelAvailable(props.listOfUnassignedAgents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listOfUnassignedAgents]);

  const updateAttribution = () => {
    const newPersonnel: any = [];
    props.uceDay.personnel.forEach((jour: any) => {
      jour.forEach((personne: any) => {
        newPersonnel.push(personne);
      });
    });
    props.uceDay.precPersonnel[0].forEach((personne: any) => {
      personne.veille = true;
      newPersonnel.push(personne);
    });
    setPersonnel(newPersonnel);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const closeModalEdit = () => {
    setOpenEdit(false);
  };

  //Réinitialise les utilisateurs sélectionnés
  const resetSelected = () => {
    setSelected([]);
    return;
  };

  //Permet de sélectionner un utilisateur lorsqu'on clique dessus
  const handleClick = (event: any, name: any) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  const onclickAbsent = async () => {
    if (selected.length !== 0) {
      let infoKey = props.enqueueSnackbar("Modification en cours", {
        variant: "info",
        autoHideDuration: 8000,
      });
      props.handleAbsence(selected, props.position, infoKey);
      resetSelected();
    } else {
      props.enqueueSnackbar("Aucune selection", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const getNewPersonnel = (
    personnes: any,
    matricules: any,
    positionHoraire: any
  ) => {
    setOpen(false);
    let infoKey = props.enqueueSnackbar("Ajout en cours", {
      variant: "info",
      autoHideDuration: 8000,
    });
    props.addPerson(
      personnes,
      matricules,
      positionHoraire,
      props.position,
      infoKey
    );
  };
  const changeAttribution = (jour: string, sousJour: string) => {
    setOpenEdit(false);
    let infoKey = props.enqueueSnackbar("Modification en cours", {
      variant: "info",
      autoHideDuration: 8000,
    });
    props.changeAttribution(selected, infoKey, jour, sousJour, props.position);
    resetSelected();
  };

  const sortData = (sortBy: any, sortOrder: any) => {
    var itemsToSort = JSON.parse(JSON.stringify(personnel));
    var sortedItems: any[] = [];
    var compare = null;
    switch (sortBy) {
      case "name":
        compare = (i: any, j: any) => {
          if (i.nom < j.nom) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (i.nom > j.nom) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };
        break;
      case "lastName":
        compare = (i: any, j: any) => {
          if (i.prenom < j.prenom) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (i.prenom > j.prenom) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };
        break;
      case "horaire":
        compare = (i: any, j: any) => {
          if (i.horaire < j.horaire) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (i.horaire > j.horaire) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };
        break;
      case "sous-jour":
        compare = (i: any, j: any) => {
          if (i.horaireNum < j.horaireNum) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (i.horaireNum > j.horaireNum) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };
        break;
      case "poste":
        compare = (i: any, j: any) => {
          if (i.poste < j.poste) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (i.poste > j.poste) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };
        break;
      default:
        break;
    }

    sortedItems = itemsToSort.sort(compare);
    return sortedItems;
  };

  const requestSort = (pSortBy: any) => {
    let newSortBy = sortBy;
    let newsortOrder = sortOrder;
    if (pSortBy === sortBy) {
      newsortOrder = newsortOrder === "asc" ? "desc" : "asc";
    } else {
      newSortBy = pSortBy;
      setSortBy(pSortBy);
      newsortOrder = "asc";
    }
    setSortOrder(newsortOrder);
    var sortedItems = sortData(newSortBy, newsortOrder);
    setPersonnel(sortedItems);
  };

  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Typography
          className={props.classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Personnel et horaires attribués
        </Typography>
      </Grid>

      <Grid
        item
        xs={2}
        sm={4}
        className={props.classes.horaireTopBar}
        style={{ textAlign: "left" }}
      >
        <Tooltip
          title={`Réinitialiser la sélection : ${selected.length} selection(s)`}
        >
          <Button
            variant="outlined"
            onClick={resetSelected}
            style={{
              maxWidth: "35px",
              maxHeight: "35px",
              minWidth: "35px",
              minHeight: "35px",
            }}
          >
            <Reinitialiser />
          </Button>
        </Tooltip>
      </Grid>

      <Grid item sm={5}></Grid>
      <Grid
        item
        xs={2}
        sm={1}
        className={props.classes.horaireTopBar}
        style={{ textAlign: "center" }}
      >
        {petit && (
          <Tooltip title="Ajouter une personne">
            <Button
              variant="contained"
              color="primary"
              style={{
                maxWidth: "35px",
                maxHeight: "35px",
                minWidth: "35px",
                minHeight: "35px",
              }}
              onClick={() => {
                setOpen(true);
              }}
              className={classes.bouton_ajout}
            >
              +
            </Button>
          </Tooltip>
        )}
        {!petit && (
          <Tooltip title="Ajouter une personne">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpen(true);
              }}
              className={classes.bouton_ajout}
            >
              +
            </Button>
          </Tooltip>
        )}
      </Grid>
      <Grid
        item
        xs={2}
        sm={1}
        className={props.classes.horaireTopBar}
        style={{ textAlign: "center" }}
      >
        {petit && (
          <Tooltip title="Modifier une personne">
            <Button
              variant="contained"
              color="primary"
              style={{
                maxWidth: "35px",
                maxHeight: "35px",
                minWidth: "35px",
                minHeight: "35px",
              }}
              onClick={() => {
                if (selected.length !== 0) {
                  setOpenEdit(true);
                } else {
                  props.enqueueSnackbar("Aucune selection", {
                    variant: "error",
                    autoHideDuration: 3000,
                  });
                }
              }}
              className={classes.bouton_ajout}
            >
              <Edit />
            </Button>
          </Tooltip>
        )}
        {!petit && (
          <Tooltip title="Modifier une personne">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (selected.length !== 0) {
                  setOpenEdit(true);
                } else {
                  props.enqueueSnackbar("Aucune selection", {
                    variant: "error",
                    autoHideDuration: 3000,
                  });
                }
              }}
              className={classes.bouton_ajout}
            >
              <Edit />
            </Button>
          </Tooltip>
        )}
      </Grid>
      <Grid
        item
        xs={2}
        sm={1}
        className={props.classes.horaireTopBar}
        style={{ textAlign: "center" }}
      >
        {petit && (
          <Tooltip title="Mettre Absent/Présent">
            <Button
              variant="contained"
              color="secondary"
              style={{
                maxWidth: "35px",
                maxHeight: "35px",
                minWidth: "35px",
                minHeight: "35px",
              }}
              onClick={onclickAbsent}
            >
              <Absent />
            </Button>
          </Tooltip>
        )}
        {!petit && (
          <Tooltip title="Mettre Absent/Présent">
            <Button
              variant="contained"
              color="secondary"
              onClick={onclickAbsent}
            >
              <Absent />
            </Button>
          </Tooltip>
        )}
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <TableContainer component={Paper} className={props.classes.container}>
          <Table stickyHeader size="medium" style={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={sortBy === "name"}
                    direction={sortOrder === "asc" ? "asc" : "desc"}
                    onClick={() => {
                      requestSort("name");
                    }}
                  >
                    Nom
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={sortBy === "lastName"}
                    direction={sortOrder === "asc" ? "asc" : "desc"}
                    onClick={() => {
                      requestSort("lastName");
                    }}
                  >
                    Prénom
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={sortBy === "horaire"}
                    direction={sortOrder === "asc" ? "asc" : "desc"}
                    onClick={() => {
                      requestSort("horaire");
                    }}
                  >
                    Horaire
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={sortBy === "sous-jour"}
                    direction={sortOrder === "asc" ? "asc" : "desc"}
                    onClick={() => {
                      requestSort("sous-jour");
                    }}
                  >
                    Sous-Jour
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={sortBy === "poste"}
                    direction={sortOrder === "asc" ? "asc" : "desc"}
                    onClick={() => {
                      requestSort("poste");
                    }}
                  >
                    Poste
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">Absence</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {personnel.map((personne: any) => {
                //Lignes du tableau correspondant au personnel du jour
                const isItemSelected = isSelected(personne.matricule);
                const labelId = `checkbox-${personne.matricule}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, personne.matricule)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    key={
                      personne.matricule +
                      personne.horaireNum +
                      personne.horaire +
                      personne.veille
                    }
                    style={{
                      background: personne.absence ? "#F7D7D2" : "white",
                    }}
                    tabIndex={-1}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>
                    <TableCell align="center">{personne.nom}</TableCell>
                    <TableCell align="center">{personne.prenom}</TableCell>
                    <TableCell align="center">
                      {personne.veille && personne.horaire + "Veille"}
                      {!personne.veille && personne.horaire}
                    </TableCell>

                    <TableCell align="center">
                      {!personne.absence &&
                        !isNaN(personne.horaireNum) &&
                        personne.isExtension &&
                        `Ext_${personne.horaireNum + 1}`}
                      {!personne.absence &&
                        !isNaN(personne.horaireNum) &&
                        !personne.isExtension &&
                        !personne.cds &&
                        `${personne.horaireNum + 1}`}
                      {!personne.absence &&
                        !isNaN(personne.horaireNum) &&
                        !personne.isExtension &&
                        personne.cds &&
                        `CDS`}
                      {personne.absence && "Absent"}
                    </TableCell>
                    <TableCell align="center">{personne.poste}</TableCell>
                    <TableCell align="center">{personne.absence}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <ModalAddAttribution
        open={open}
        closeModal={closeModal}
        classes={props.classes}
        listOfUnassignedAgents={listPersonnelAvailable}
        uceDay={props.uceDay.horaire}
        getNewPersonnel={getNewPersonnel}
      ></ModalAddAttribution>
      <ModalEditAttribution
        open={openEdit}
        closeModal={closeModalEdit}
        classes={props.classes}
        uceDay={props.uceDay.horaire}
        changeAttribution={changeAttribution}
        position={props.position}
      ></ModalEditAttribution>
    </Grid>
  );
};

export default withSnackbar(AttributionPersonnel);
