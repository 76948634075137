import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import Tooltip from "@material-ui/core/Tooltip";
import deepClone from "lodash/cloneDeep";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Grid, useMediaQuery } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment-timezone";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Clone from "@material-ui/icons/AddToPhotos";
import Delete from "@material-ui/icons/Delete";
import { ModalAddHoraire, ModalChargeHoraire } from "./modal";
import AuthenticationService from "../Service/AuthenticationService";
import { useSnackbar } from "notistack";
import Reinitialiser from "@material-ui/icons/Autorenew";
import { generateId } from "../globalFunction";

const useStyles = makeStyles((theme) => ({
  btn_horaire: {
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
  input: {
    marginLeft: theme.spacing(3),
  },
  editUser: {
    marginBottom: theme.spacing(5),
  },
  btneditUser: {
    marginRight: theme.spacing(4),
  },
  addUser: {
    marginTop: theme.spacing(2),
  },
  editJ_button: {
    marginRight: theme.spacing(2),
  },
  addJ_button: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  editJ_buttonGroup: {
    marginTop: theme.spacing(2),
  },
  AjoutPForm: {
    marginBottom: theme.spacing(3),
  },
  AjoutPForm_fin: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  AjoutPForm_DoublePoint: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  AjoutPForm_button: {
    marginRight: theme.spacing(2),
  },
  addSJ: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  trie: {
    margin: theme.spacing(1),
  },
}));

//
const TypeAction = [
  {
    value: "",
    label: " ",
  },
  {
    value: "Update",
    label: "Update",
  },
  {
    value: "Delete",
    label: "Delete",
  },
  {
    value: "Sign",
    label: "Sign",
  },
  {
    value: "Save",
    label: "Save",
  },
  {
    value: "Add",
    label: "Add",
  },
  {
    value: "Recalculate",
    label: "Recalculate",
  },
  {
    value: "Change",
    label: "Change",
  },
];

//
const TypeLogs = [
  {
    value: "",
    label: " ",
  },
  {
    value: "Horaire",
    label: "Horaire",
  },
  {
    value: "Uceday",
    label: "Uceday",
  },
];

//Formulaires pour Horaire.js permet la gestion de l'ajout d'un horaire
export const AjoutFHoraire = (props: any) => {
  const [ajout, setAjout] = useState(false);

  const closeModal = () => {
    setAjout(false);
  };
  const add = (values: any) => {
    props.newF(values);
    setAjout(false);
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => setAjout(true)}
      >
        {" "}
        +
      </Button>
      {ajout && (
        <ModalAddHoraire
          open={ajout}
          titles={props.titles}
          closeModal={closeModal}
          add={add}
        />
      )}
    </>
  );
};

//Formulaires pour Horaire.js et Droits.js, permet la gestion du chargement d'un horaire
export const ChargeFHoraire = (props: any) => {
  const [charger, setCharger] = useState(false);

  const closeModalCharger = () => {
    setCharger(false);
  };

  const modalChargerCharge = async (cible: any) => {
    await props.loadH(cible);
    setCharger(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        style={{ backgroundColor: "white" }}
        onClick={() => {
          setCharger(true);
        }}
      >
        Charger
      </Button>

      {charger && (
        <ModalChargeHoraire
          open={charger}
          chargeList={props.chargeList}
          cibleCharge={props.cibleCharge}
          closeModal={closeModalCharger}
          charge={modalChargerCharge}
        />
      )}
    </>
  );
};

export const AjoutSjForm = (props: any) => {
  const classes = useStyles();

  const [ajout, setAjout] = useState(false);

  return ajout ? (
    <Formik
      initialValues={{ nPExt: 0, cds: false }}
      onSubmit={(values, { resetForm }) => {
        props.newFSubday(props.jour, {
          id: generateId(),
          nPExt: values.nPExt,
          periodes: [],
          cds: values.cds,
        });
        resetForm();
        setAjout(false);
      }}
      validationSchema={Yup.object().shape({
        nPExt: Yup.number()
          .required("Un nombre est nécessaire, 0 si il n'y a pas d'extension")
          .integer("Doit être entier")
          .min(0, "Doit être positif ou nul"),
      })}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <Form>
          <Grid container alignContent="center" justifyContent="center">
            {!values.cds && (
              <Grid item className={classes.AjoutPForm_button}>
                <TextField
                  label="Nbr P Extension"
                  name="nPExt"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nPExt}
                  helperText={errors.nPExt && touched.nPExt ? errors.nPExt : ""}
                  error={errors.nPExt && touched.nPExt ? true : false}
                />
              </Grid>
            )}
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={values.cds} />}
                  onChange={(e: any) => {
                    if (e.target.checked) setFieldValue("nPExt", 0);

                    setFieldValue("cds", e.target.checked);
                  }}
                  label="CDS"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid
            container
            alignContent="center"
            justifyContent="center"
            className={classes.btn_horaire}
          >
            <Grid item xs>
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
                className={classes.AjoutPForm_button}
              >
                Ok
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setAjout(false);
                }}
                style={{ backgroundColor: "white" }}
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  ) : (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={() => {
        setAjout(true);
      }}
    >
      +
    </Button>
  );
};

export const FiltreLogs = (props: any) => {
  const classes = useStyles();

  const [action, setAction] = useState("");
  const [fichier, setFichier] = useState("");
  const [startDateLogs, setStartDateLogs] = useState(moment().startOf("day"));
  const [endDateLogs, setEndDateLogs] = useState(moment().startOf("day"));
  const { enqueueSnackbar } = useSnackbar();

  const handleActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAction(event.target.value);
  };
  const handleStartDateChange = (date: any) => {
    if (date && date.isValid()) {
      setStartDateLogs(date);
    }
  };
  const handleEndDateChange = (date: any) => {
    if (date && date.isValid() && date.isAfter(startDateLogs)) {
      setEndDateLogs(date);
    } else {
      enqueueSnackbar(
        "La date voulue n'est pas valide ou est inférieure à la date de début ",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    }
  };

  const handleFichierChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFichier(event.target.value);
  };

  // const clearFiltrage = () => {
  //   setFichier("");
  //   setStartDateLogs(moment().startOf("day"));
  //   setEndDateLogs(moment().startOf("day"));
  // };

  return (
    <Formik
      initialValues={{
        startDate: startDateLogs,
        endDate: endDateLogs,
        user: "",
        details: "",
        action: action,
        fileType: fichier,
      }}
      onSubmit={(values, { resetForm }) => {
        props.filtrer(
          startDateLogs,
          endDateLogs,
          values.user,
          values.details,
          action,
          fichier
        );
      }}
      validationSchema={Yup.object().shape({
        all: Yup.string(),
      })}
    >
      {({ values, errors, touched, handleChange, handleBlur, resetForm }) => (
        <Form>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={4} md={3} className={classes.trie}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  format="DD/MM/yyyy"
                  id="startDate"
                  label="Début logs"
                  value={startDateLogs}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4} md={3} className={classes.trie}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  format="DD/MM/yyyy"
                  error={false}
                  helperText={false ? "Date déjà choisie" : ""}
                  id="endDate"
                  label="Fin logs"
                  value={endDateLogs}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3} md={2} className={classes.trie}>
              <TextField
                label="Action"
                select
                fullWidth
                id="action"
                SelectProps={{
                  native: true,
                }}
                value={action}
                onChange={handleActionChange}
              >
                {TypeAction.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3} md={2} className={classes.trie}>
              <TextField
                label="Type de fichier"
                select
                fullWidth
                id="fichier"
                SelectProps={{
                  native: true,
                }}
                value={fichier}
                onChange={handleFichierChange}
              >
                {TypeLogs.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4} md={5} className={classes.trie}>
              <TextField
                label="Utilisateur"
                id="user"
                fullWidth
                value={values.user}
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={4} md={5} className={classes.trie}>
              <TextField
                label="Détails"
                id="details"
                fullWidth
                value={values.details}
                onChange={handleChange}
                onBlur={handleBlur}
              ></TextField>
            </Grid>

            <Grid item xs={2} md={2} className={classes.trie}>
              <Button type="submit" variant="contained" color="primary">
                Filtrer
              </Button>
            </Grid>
            <Grid item xs={2} md={2} className={classes.trie}>
              <Button
                variant="contained"
                onClick={() => {
                  setAction("");
                  setFichier("");
                  setStartDateLogs(moment().startOf("day"));
                  setEndDateLogs(moment().startOf("day"));
                  resetForm();
                  props.reset();
                }}
              >
                <Reinitialiser />
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export const EditableSj = (props: any) => {
  const user = AuthenticationService.user;
  const classes = useStyles();

  const [edit, setEdit] = useState(false);

  return edit ? (
    <Card style={props.style}>
      <Formik
        initialValues={{
          nPExt: props.nPExt,
          cds: props.cds !== undefined ? props.cds : false,
        }}
        onSubmit={(values, { resetForm }) => {
          props.editFSubday(
            props.jour,
            props.positionSousJour,
            props.sousJour,
            {
              nPExt: values.nPExt,
              cds: values.cds,
            }
          );
        }}
        validationSchema={Yup.object().shape({
          nPExt: Yup.number()
            .required("Un nombre est nécessaire, 0 si il n'y a pas d'extension")
            .integer("Doit être entier")
            .min(0, "Doit être positif ou nul"),
        })}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form>
            <Grid
              container
              alignItems="center"
              alignContent="center"
              justifyContent="center"
            >
              {!values.cds && (
                <Grid item>
                  <TextField
                    label="Nombre P Extension"
                    name="nPExt"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nPExt}
                    helperText={errors.nPExt}
                    error={errors.nPExt && touched.nPExt ? true : false}
                  />
                </Grid>
              )}
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={values.cds} />}
                    onChange={(e: any) => {
                      if (e.target.checked) setFieldValue("nPExt", 0);
                      setFieldValue("cds", e.target.checked);
                    }}
                    label="CDS"
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems="center"
              alignContent="center"
              justifyContent="center"
              className={classes.addUser}
            >
              <Grid item sm={3} lg={4}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Ok
                </Button>
              </Grid>
              <Grid item sm={1} lg={1}></Grid>
              <Grid item sm={3} lg={6}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setEdit(false)}
                  style={{ backgroundColor: "white" }}
                >
                  Annuler
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Card>
  ) : (
    <Grid
      container
      item
      direction="column"
      style={{ justifyContent: "space-between" }}
    >
      <Grid item style={{ alignSelf: "center" }}>
        <Typography>
          {props.cds ? "CDS" : "Nombre extension : " + props.nPExt}
        </Typography>
      </Grid>
      {user.role.droits.horaire.edition && (
        <Grid item style={{ alignSelf: "center" }}>
          <Grid
            container
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            className={classes.addUser}
          >
            <Tooltip title="Editer le nombre extension">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => setEdit(true)}
                style={{
                  maxWidth: "35px",
                  maxHeight: "35px",
                  minWidth: "35px",
                  minHeight: "35px",
                }}
                className={classes.editJ_button}
              >
                <Edit />
              </Button>
            </Tooltip>
            <Tooltip title="Dupliquer le sous-jour">
              <Button
                size="small"
                variant="outlined"
                color="primary"
                style={{
                  maxWidth: "35px",
                  maxHeight: "35px",
                  minWidth: "35px",
                  minHeight: "35px",
                }}
                onClick={(e) => {
                  props.newFSubday(
                    props.jour,
                    {
                      id: props.id,
                      nPExt: props.nPExt,
                      periodes: deepClone(props.periodes),
                    },
                    e
                  );
                }}
                className={classes.editJ_button}
              >
                <Clone />
              </Button>
            </Tooltip>
            <Tooltip title="Supprimer le sous-jours">
              <Button
                size="small"
                style={{
                  maxWidth: "35px",
                  maxHeight: "35px",
                  minWidth: "35px",
                  minHeight: "35px",
                }}
                variant="outlined"
                color="secondary"
                onClick={(e) =>
                  props.deleteFSubday(
                    props.jour,
                    props.sousJour,
                    props.positionSousJour
                  )
                }
              >
                <Delete />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export const AjoutPForm = (props: any) => {
  const classes = useStyles();

  const [ajout, setAjout] = useState(false);
  const [debutH, setDebutH] = useState("00");
  const [debutMin, setDebutMin] = useState("00");
  const [finH, setFinH] = useState("00");
  const [finMin, setFinMin] = useState("00");
  const [etat, setEtat] = useState("W");

  return ajout ? (
    <div>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        alignContent="center"
      >
        <Grid item xs={4} sm={2} md={4} className={classes.AjoutPForm_fin}>
          <TextField
            label="Début"
            name="debut_heure"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1,
              min: 0,
              max: 26,
            }}
            onChange={(event: any) => {
              if (
                parseInt(event.target.value) < 0 ||
                parseInt(event.target.value) > 26
              ) {
                setDebutH("00");
              } else if (event.target.value < 10) {
                setDebutH("0" + parseInt(event.target.value));
              } else {
                setDebutH(parseInt(event.target.value).toString());
              }
            }}
            value={debutH}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sm={1}
          md={2}
          className={classes.AjoutPForm_DoublePoint}
        >
          :
        </Grid>
        <Grid item xs={4} sm={2} md={4} className={classes.AjoutPForm_fin}>
          <TextField
            label=" "
            name="debut_min"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1,
              min: 0,
              max: 59,
            }}
            onChange={(event: any) => {
              if (
                parseInt(event.target.value) < 0 ||
                parseInt(event.target.value) > 60
              ) {
                setDebutMin("00");
              } else if (event.target.value < 10) {
                setDebutMin("0" + parseInt(event.target.value));
              } else {
                setDebutMin(parseInt(event.target.value).toString());
              }
            }}
            value={debutMin}
          />
        </Grid>
        <Grid item xs={4} sm={2} md={4} className={classes.AjoutPForm_fin}>
          <TextField
            label="Fin"
            name="fin_heure"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1,
              min: 0,
              max: 26,
            }}
            onChange={(event: any) => {
              if (
                parseInt(event.target.value) < 0 ||
                parseInt(event.target.value) > 26
              ) {
                setFinH("00");
              } else if (event.target.value < 10) {
                setFinH("0" + parseInt(event.target.value));
              } else {
                setFinH(parseInt(event.target.value).toString());
              }
            }}
            value={finH}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sm={1}
          md={2}
          className={classes.AjoutPForm_DoublePoint}
        >
          :
        </Grid>
        <Grid item xs={4} sm={2} md={4} className={classes.AjoutPForm_fin}>
          <TextField
            label=" "
            name="fin_min"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1,
              min: 0,
              max: 59,
            }}
            onChange={(event: any) => {
              if (
                parseInt(event.target.value) < 0 ||
                parseInt(event.target.value) > 60
              ) {
                setFinMin("00");
              } else if (event.target.value < 10) {
                setFinMin("0" + parseInt(event.target.value));
              } else {
                setFinMin(parseInt(event.target.value).toString());
              }
            }}
            value={finMin}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Etat</FormLabel>
            <RadioGroup
              row
              aria-label="gender"
              name="etat"
              value={etat}
              onChange={(e) => {
                setEtat(e.target.value);
              }}
            >
              <FormControlLabel value="W" control={<Radio />} label="W" />
              <FormControlLabel value="C" control={<Radio />} label="C" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs className={classes.AjoutPForm}>
          <Grid container>
            <Grid item sm={2} lg={1}></Grid>
            <Grid item xs={6} sm={4} lg={5}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  const newPeriode = {
                    debut: debutH + ":" + debutMin,
                    fin: finH + ":" + finMin,
                    etat: etat,
                  };
                  props.newFPeriode(
                    props.jour,
                    props.sousJourIndex,
                    newPeriode
                  );
                  setDebutH("00");
                  setDebutMin("00");
                  setFinH("00");
                  setFinMin("00");
                  setAjout(false);
                }}
              >
                Ok
              </Button>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Button
                size="small"
                variant="outlined"
                onClick={() => setAjout(false)}
                style={{ backgroundColor: "white" }}
                type="button"
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  ) : (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={() => setAjout(true)}
      className={classes.AjoutPForm}
    >
      +
    </Button>
  );
};

export const AjoutJForm = (props: any) => {
  const classes = useStyles();

  const [ajout, setAjout] = useState(false);

  return ajout ? (
    <Formik
      initialValues={{
        nom: "",
        nomOctime: "",
        veille: false,
        valeur: 0,
        ExtPcMini: 0,
      }}
      onSubmit={(values, { resetForm }) => {
        props.newFDay({
          nom: values.nom,
          nomOctime: values.nomOctime,
          veille: values.veille,
          valeur: values.valeur,
          ExtPcMini: values.ExtPcMini,
        });
        resetForm();
        setAjout(false);
      }}
      validationSchema={Yup.object().shape({
        nom: Yup.string()
          .notOneOf(
            props.fHoraire.jours.map((x: any) => x.nom),
            "Nom déjà utilisé"
          )
          .required("Merci d'indiquer un nom de jour"),
        nomOctime: Yup.string(),
        veille: Yup.boolean(),
        valeur: Yup.number().integer("Doit être entier"),
        ExtPcMini: Yup.number().integer("Doit être entier"),
      })}
    >
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Form>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            className={classes.addSJ}
          >
            <Grid item xs={5} md={2}>
              <TextField
                label="nom"
                name="nom"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nom}
                helperText={errors.nom && touched.nom ? errors.nom : ""}
                error={errors.nom && touched.nom ? true : false}
                className={classes.addJ_button}
              />
            </Grid>
            <Grid item xs={5} md={2}>
              <TextField
                label="nomOctime"
                name="nomOctime"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nomOctime}
                helperText={
                  errors.nomOctime && touched.nomOctime ? errors.nomOctime : ""
                }
                error={errors.nomOctime && touched.nomOctime ? true : false}
                className={classes.addJ_button}
              />
            </Grid>
            <Grid
              item
              xs={4}
              md={2}
              className={classes.editJ_buttonGroup}
              style={{ textAlign: "center" }}
            >
              Veille :
              <Checkbox
                checked={values.veille}
                name="veille"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={3} md={1}>
              <TextField
                label="valeur"
                name="valeur"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.valeur}
                helperText={
                  errors.valeur && touched.valeur ? errors.valeur : ""
                }
                error={errors.valeur && touched.valeur ? true : false}
                className={classes.addJ_button}
              />
            </Grid>
            <Grid item xs={3} md={1}>
              <TextField
                label="ExtPcMini"
                name="ExtPcMini"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ExtPcMini}
                helperText={
                  errors.ExtPcMini && touched.ExtPcMini ? errors.ExtPcMini : ""
                }
                error={errors.ExtPcMini && touched.ExtPcMini ? true : false}
                className={classes.addJ_button}
              />
            </Grid>
            <Grid item xs={8} sm={4} md={2}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
                className={classes.addJ_button}
              >
                Ok
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={() => setAjout(false)}
                style={{ backgroundColor: "white" }}
                className={classes.editJ_buttonGroup}
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  ) : (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={() => setAjout(true)}
    >
      +
    </Button>
  );
};

export const EditableJ = (props: any) => {
  const user = AuthenticationService.user;
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const [edit, setEdit] = useState(false);

  return (
    <>
      {edit ? (
        <div
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          style={{ width: "100%" }}
        >
          <Formik
            initialValues={{
              nom: props.jour.nom,
              nomOctime: props.jour.nomOctime,
              veille: props.jour.veille,
              valeur: props.jour.valeur,
              ExtPcMini: props.jour.ExtPcMini,
            }}
            onSubmit={(values) => {
              props.editFDay(props.jour.nom, {
                nom: values.nom,
                nomOctime: values.nomOctime,
                veille: values.veille,
                valeur: parseInt(values.valeur),
                ExtPcMini: parseInt(values.ExtPcMini),
              });
            }}
            validationSchema={Yup.object().shape({
              nom: Yup.string()
                .notOneOf(
                  props.fHoraire.jours
                    .map((x: any) => x.nom)
                    .filter((x: any) => x !== props.jour.nom),
                  "Nom déjà utilisé"
                )
                .required("Merci d'indiquer un nom de jour"),
              nomOctime: Yup.string(),
              veille: Yup.boolean(),
              valeur: Yup.number().integer("Doit être un nombre entier"),
              ExtPcMini: Yup.number().integer("Doit être un nombre entier"),
            })}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form>
                <Grid
                  container
                  alignContent="center"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={7} sm={2} md={2}>
                    <TextField
                      label="nom"
                      name="nom"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nom}
                      helperText={errors.nom && touched.nom ? errors.nom : ""}
                      error={errors.nom && touched.nom ? true : false}
                      className={classes.editJ_button}
                    />
                  </Grid>
                  <Grid item xs={7} sm={2} md={2}>
                    <TextField
                      label="nomOctime"
                      name="nomOctime"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nomOctime}
                      helperText={
                        errors.nomOctime && touched.nomOctime
                          ? errors.nomOctime
                          : ""
                      }
                      error={
                        errors.nomOctime && touched.nomOctime ? true : false
                      }
                      className={classes.editJ_button}
                    />
                  </Grid>
                  <Grid item xs={7} sm={2} md={2}>
                    Veille :
                    <Checkbox
                      checked={values.veille}
                      name="veille"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={7} sm={1} md={1}>
                    <TextField
                      label="valeur"
                      name="valeur"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.valeur}
                      helperText={
                        errors.valeur && touched.valeur ? errors.valeur : ""
                      }
                      error={errors.valeur && touched.valeur ? true : false}
                      className={classes.editJ_button}
                    />
                  </Grid>
                  <Grid item xs={7} sm={1} md={1}>
                    <TextField
                      label="ExtPcMini"
                      name="ExtPcMini"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ExtPcMini}
                      helperText={
                        errors.ExtPcMini && touched.ExtPcMini
                          ? errors.ExtPcMini
                          : ""
                      }
                      error={
                        errors.ExtPcMini && touched.ExtPcMini ? true : false
                      }
                      className={classes.editJ_button}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    sm={4}
                    md={3}
                    className={classes.editJ_buttonGroup}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.editJ_button}
                    >
                      Ok
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => setEdit(false)}
                      style={{ backgroundColor: "white" }}
                    >
                      Annuler
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div style={{ maxWidth: "100%", width: "100%" }}>
          {xs && (
            <>
              <Grid
                container
                alignContent="center"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  {props.jour.nomOctime
                    ? `${props.jour.nom} - (${props.jour.nomOctime})`
                    : `${props.jour.nom}`}
                  {props.jour.veille && (
                    <Tooltip
                      style={{ margin: 3 }}
                      title="Personnel du jour précédent"
                    >
                      <span> - Veille </span>
                    </Tooltip>
                  )}
                  {props.jour.valeur &&
                    (!props.jour.nomOctime || props.jour.nomOctime === "") && (
                      <span>{` - [${props.jour.valeur}]`}</span>
                    )}
                  {props.expended === false &&
                    " - " + props.jour.sousJours.length + " Sous-jours"}
                </Grid>
                {user.role.droits.horaire.edition && (
                  <>
                    <Grid item xs={4} className={props.classes.horaireTopBar}>
                      <Tooltip title="Edit nom, nom Octime, veille et valeur">
                        <Button
                          size="small"
                          variant="outlined"
                          aria-label="editDay"
                          style={{ backgroundColor: "white" }}
                          onFocus={(event) => event.stopPropagation()}
                          onClick={(event) => {
                            event.stopPropagation();
                            setEdit(true);
                          }}
                        >
                          <Edit />
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={3} className={props.classes.horaireTopBar}>
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        aria-label="removeDay"
                        onFocus={(event) => event.stopPropagation()}
                        onClick={(event) => {
                          props.deleteHoraireDay(props.jour, event);
                          event.stopPropagation();
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}

          {!xs && (
            <>
              {props.jour.nomOctime
                ? `${props.jour.nom} - (${props.jour.nomOctime})`
                : `${props.jour.nom}`}
              {props.jour.veille ? (
                <Tooltip
                  style={{ margin: 3 }}
                  title="Personnel du jour précédent"
                >
                  <span> - Veille </span>
                </Tooltip>
              ) : null}
              {props.jour.valeur &&
              (!props.jour.nomOctime || props.jour.nomOctime === "") ? (
                <span>{` - [${props.jour.valeur}]`}</span>
              ) : null}
              {props.expended === false &&
                " - " + props.jour.sousJours.length + " Sous-jours"}

              {user.role.droits.horaire.edition && (
                <>
                  <Tooltip title="Edit nom, nom Octime, veille et valeur">
                    <Button
                      size="small"
                      variant="outlined"
                      aria-label="editDay"
                      style={{
                        position: "absolute",
                        right: 150,
                        bottom: 10,
                        backgroundColor: "white",
                      }}
                      onFocus={(event) => event.stopPropagation()}
                      onClick={(event) => {
                        event.stopPropagation();
                        setEdit(true);
                      }}
                    >
                      <Edit />
                    </Button>
                  </Tooltip>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    style={{ position: "absolute", right: 60, bottom: 10 }}
                    aria-label="removeDay"
                    onFocus={(event) => event.stopPropagation()}
                    onClick={(event) => {
                      props.deleteHoraireDay(props.jour);
                      event.stopPropagation();
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export const EditableH = (props: any) => {
  const classes = useStyles();

  return (
    <div>
      <Formik
        initialValues={{ titre: props.titre, strike: props.strike }}
        onSubmit={(values) => {
          props.editVal(values.titre, props.titre, values.strike);
        }}
        validationSchema={Yup.object().shape({
          titre: Yup.string().required("Le titre est manquant"),
        })}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          errors,
        }) => (
          <Form>
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              alignItems="center"
              className={classes.editUser}
            >
              <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
                <TextField
                  label="Titre"
                  value={values.titre}
                  name="Titre"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.titre && touched.titre ? errors.titre : ""}
                  error={errors.titre && touched.titre ? true : false}
                />
              </Grid>
              <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.strike}
                      onChange={handleChange}
                      name="strike"
                    />
                  }
                  label="Grève"
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={false}
                style={{ textAlign: "center" }}
              ></Grid>

              <Grid
                item
                md={1}
                xs={3}
                className={classes.addUser}
                style={{ textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  type="submit"
                >
                  Ok
                </Button>
              </Grid>

              <Grid item md={1} xs={3} className={classes.addUser}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => props.exit()}
                  style={{ backgroundColor: "white" }}
                >
                  Annuler
                </Button>
              </Grid>
              <Grid
                item
                md={2}
                xs={false}
                style={{ textAlign: "center" }}
              ></Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const EditableValidite = (props: any) => {
  const classes = useStyles();

  return (
    <div>
      <Formik
        initialValues={{
          debut: moment(props.debut, "DD-MM-YYYY"),
          fin: moment(props.fin, "DD-MM-YYYY"),
        }}
        onSubmit={(values, { resetForm }) => {
          props.editValidite(
            props.index,
            values.debut.format("DD/MM/YYYY"),
            values.fin.format("DD/MM/YYYY")
          );
          resetForm();
        }}
        validationSchema={Yup.object().shape({
          debut: Yup.mixed().required(),
          fin: Yup.mixed()
            .required()
            .when("debut", (debut, schema) => {
              return schema.test(
                "La date de fin doit être >= à celle du début",
                "La date de fin doit être >= à celle du début",
                (value: any) => value.isSameOrAfter(debut)
              );
            }),
        })}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          errors,
        }) => (
          <Form>
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              className={classes.editUser}
            >
              <Grid
                item
                md={3}
                xs={false}
                style={{ textAlign: "center" }}
              ></Grid>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid item md={3} xs={5} style={{ textAlign: "center" }}>
                  <KeyboardDatePicker
                    style={{ marginRight: "5px" }}
                    format="DD/MM/yyyy"
                    margin="normal"
                    name="debut"
                    label="Début de validité"
                    value={values.debut}
                    onChange={(value) => setFieldValue("debut", moment(value))}
                    onBlur={handleBlur}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item md={3} xs={5} style={{ textAlign: "center" }}>
                  <KeyboardDatePicker
                    format="DD/MM/yyyy"
                    margin="normal"
                    name="fin"
                    label="Fin de validité"
                    value={values.fin}
                    onChange={(value) => {
                      setFieldValue("fin", moment(value));
                    }}
                    onBlur={handleBlur}
                    error={errors.fin && touched.fin ? true : false}
                    helperText={errors.fin && touched.fin ? errors.fin : ""}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <Grid item md={3} xs={false}></Grid>
              <Grid item md={1} xs={false}></Grid>
              <Grid
                item
                md={1}
                xs={3}
                className={classes.addUser}
                style={{ textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  type="submit"
                >
                  Ok
                </Button>
              </Grid>

              <Grid item md={3} xs={3} className={classes.addUser}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => props.exit()}
                  style={{ backgroundColor: "white" }}
                >
                  Annuler
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const AddValidite = (props: any) => {
  const classes = useStyles();

  return (
    <div>
      <Formik
        initialValues={{ debut: moment(), fin: moment().add(1, "day") }}
        onSubmit={(values, { resetForm }) => {
          props.addValiditeH(
            values.debut.format("DD/MM/YYYY"),
            values.fin.format("DD/MM/YYYY")
          );
          resetForm();
        }}
        validationSchema={Yup.object().shape({
          debut: Yup.mixed().required(),
          fin: Yup.mixed()
            .required()
            .when("debut", (debut, schema) => {
              return schema.test(
                "La date de fin doit être >= à celle du début",
                "La date de fin doit être >= à celle du début",
                (value: any) => value.isSameOrAfter(debut)
              );
            }),
        })}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          errors,
        }) => (
          <Form>
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              className={classes.editUser}
            >
              <Grid
                item
                md={3}
                xs={false}
                style={{ textAlign: "center" }}
              ></Grid>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid item md={3} xs={5} style={{ textAlign: "center" }}>
                  <KeyboardDatePicker
                    style={{ marginRight: "5px" }}
                    format="DD/MM/yyyy"
                    margin="normal"
                    name="debut"
                    label="Début de validité"
                    value={values.debut}
                    onChange={(value) => setFieldValue("debut", moment(value))}
                    onBlur={handleBlur}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item md={3} xs={5} style={{ textAlign: "center" }}>
                  <KeyboardDatePicker
                    format="DD/MM/yyyy"
                    margin="normal"
                    name="fin"
                    label="Fin de validité"
                    value={values.fin}
                    onChange={(value) => {
                      setFieldValue("fin", moment(value));
                    }}
                    onBlur={handleBlur}
                    error={errors.fin && touched.fin ? true : false}
                    helperText={errors.fin && touched.fin ? errors.fin : ""}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <Grid item md={3} xs={false}></Grid>
              <Grid item md={1} xs={false}></Grid>
              <Grid
                item
                md={1}
                xs={3}
                className={classes.addUser}
                style={{ textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  type="submit"
                >
                  Ok
                </Button>
              </Grid>

              <Grid item md={3} xs={3} className={classes.addUser}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => props.exit()}
                  style={{ backgroundColor: "white" }}
                >
                  Annuler
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const EditUser = (props: any) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Formik
        initialValues={{ role: props.roleList[0].nom }}
        onSubmit={(values, { resetForm }) => {
          props.editUser(values.role);
          resetForm();
        }}
        validationSchema={Yup.object().shape({
          role: Yup.string()
            .required()
            .oneOf(props.roleList.map((x: any) => x.nom)),
        })}
      >
        {({ values, handleChange, handleBlur }) => (
          <Form>
            <br />
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={5}
                sm={2}
                lg={4}
                className={classes.AjoutPForm_button}
              >
                <Select
                  name="role"
                  fullWidth
                  value={values.role}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {props.roleList.map((x: any) => (
                    <MenuItem value={x.nom} key={x.nom}>
                      {x.nom}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={2} sm={1} lg={2} className={classes.btneditUser}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Ok
                </Button>
              </Grid>
              <Grid item xs={2} sm={1}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    props.back();
                  }}
                >
                  Annuler
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export const AddUser = (props: any) => {
  const classes = useStyles();

  const [edit, setEdit] = useState(false);

  return edit ? (
    <Formik
      initialValues={{
        role: props.roleList[0].nom,
        nom: "",
        prenom: "",
        email: "",
      }}
      onSubmit={(values, { resetForm }) => {
        props.addUser(values.nom, values.prenom, values.role, values.email);
        resetForm();
        setEdit(false);
      }}
      validationSchema={Yup.object().shape({
        role: Yup.string()
          .required()
          .oneOf(props.roleList.map((x: any) => x.nom)),
        nom: Yup.string().required(),
        prenom: Yup.string().required(),
        email: Yup.string().email().required(),
      })}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
      }) => (
        <Form>
          <Grid
            container
            alignContent="center"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={3} sm={2} lg={2}>
              <TextField
                required
                fullWidth
                label="Nom"
                name="nom"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nom}
                helperText={errors.nom && touched.nom ? errors.nom : ""}
                error={errors.nom && touched.nom ? true : false}
              />
            </Grid>
            <Grid item xs={3} sm={2} lg={2} className={classes.input}>
              <TextField
                required
                fullWidth
                label="Prénom"
                name="prenom"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.prenom}
                helperText={
                  errors.prenom && touched.prenom ? errors.prenom : ""
                }
                error={errors.prenom && touched.prenom ? true : false}
              />
            </Grid>
            <Grid item xs={3} sm={3} lg={3} className={classes.input}>
              <TextField
                label="Email"
                required
                fullWidth
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                helperText={errors.email && touched.email ? errors.email : ""}
                error={errors.email && touched.email ? true : false}
              />
            </Grid>
            <Grid item xs={3} sm={2} lg={1} className={classes.input}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="role"
                label="Role"
                name="role"
                select
                style={{ color: "white", textAlign: "left" }}
                value={values.role}
                helperText={errors.role && touched.role ? errors.role : ""}
                error={errors.role && touched.role ? true : false}
                onChange={(event) => {
                  setFieldValue("role", event.target.value);
                }}
                onBlur={handleBlur}
              >
                {props.roleList.map((x: any) => (
                  <MenuItem value={x.nom} key={x.nom}>
                    {x.nom}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} lg={3} className={classes.input}>
              <br />
              <Grid container>
                <Grid item xs={4}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Ok
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={() => setEdit(false)}
                  >
                    Fermer
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  ) : (
    <>
      <Tooltip title="Ajouter un utilisateur" className={classes.addUser}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          aria-label="addUser"
          onClick={(event) => {
            setEdit(true);
          }}
        >
          +
        </Button>
      </Tooltip>
    </>
  );
};
