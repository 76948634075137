import { useEffect } from "react";
import { Button, Card, Grid, Typography } from "@material-ui/core";
import styled from "@emotion/styled";
import logo from "../Images/logo3.png";
import { makeStyles } from "@material-ui/core/styles";
import FormChangePassword from "../Composant/BackupConnection/FormChangePassword";
import HomeIcon from "@material-ui/icons/Home";
import { useHistory } from "react-router-dom";
import { PATHS } from "../Route/routes";

const StyledCardBigScreen = styled(Card)`
  padding: 10px;
  margin: auto;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const useStyles = makeStyles((theme) => ({
  fond: {
    backgroundColor: "#2D3E4E",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  logo: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    marginTop: theme.spacing(2),
  },
  btn: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(4),
  },
}));

//Composant de connexion
const ChangePassword = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ height: "100vh" }} className={classes.fond}>
      <div style={{ height: "25vh" }}></div>
      <>
        <StyledCardBigScreen>
          <Grid container alignContent="center" justifyContent="center">
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <Button
                color="primary"
                onClick={() => {
                  history.push(PATHS.Home);
                }}
              >
                <HomeIcon />
              </Button>
            </Grid>
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              className={classes.logo}
              style={{ height: "20vh" }}
            ></Grid>
            <br />

            <Grid item xs={8}>
              <Typography variant="h5" style={{ textAlign: "center" }}>
                Changement du mot de passe
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormChangePassword />
            </Grid>
          </Grid>
        </StyledCardBigScreen>
      </>
    </div>
  );
};

export default ChangePassword;
