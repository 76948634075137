import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import FormCreateFirstPassword from "./FormCreateFirstPassword";

export const CreatePasswordModal = (props: any) => {
  const [next, setNext] = useState(0);

  const success = () => {
    setNext(2);
  };

  return (
    props.open && (
      <Dialog open={props.open} aria-labelledby="draggable-dialog-title">
        <DialogTitle style={{ textAlign: "center" }}>
          Renforcement de la continuité du service
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {next === 0 && (
              <Typography style={{ textAlign: "left" }}>
                Afin de pallier à un éventuel problème de disponibilité du
                service d'authentification Angélique, un système
                d'authentification de secours a été mis en place.
                <br />
                Il s'appuie sur votre adresse mail et nécessite la création d'un
                mot de passe qui vous est propre. Ce mot de passe doit comporter
                au minimum 8 caractères et doit avoir au moins 1 majuscule, 1
                minuscule et 1 caractère spécial.
              </Typography>
            )}
            {next === 1 && <FormCreateFirstPassword success={success} />}
            {next === 2 && (
              <Typography style={{ textAlign: "left" }}>
                Votre mot de passe a bien été enregistré.
                <br />
                Vous allez être redirigé vers le site d'UCESO RH Web
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        {next === 0 && (
          <DialogActions>
            <Grid
              container
              alignItems="center"
              alignContent="center"
              justifyContent="center"
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setNext(1);
                }}
                color="primary"
              >
                Suivant
              </Button>
            </Grid>
          </DialogActions>
        )}
        {next === 2 && (
          <DialogActions>
            <Grid
              container
              alignItems="center"
              alignContent="center"
              justifyContent="center"
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  props.closeModal();
                }}
                color="secondary"
              >
                Fermer
              </Button>
            </Grid>
          </DialogActions>
        )}
      </Dialog>
    )
  );
};
