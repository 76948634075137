import React, { useEffect, useState } from "react";
import UcesoGraph from "../Composant/Uceso/UcesoGraph";
import AttributionPersonnel from "../Composant/Uceso/AttributionPersonnel";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AuthenticationService from "../Service/AuthenticationService";
import { Button } from "@material-ui/core";
import Minus from "@material-ui/icons/Remove";
import More from "@material-ui/icons/Add";
import { calculUceso } from "../Composant/Uceso/UcesoTestShareFonction";

//Nous donne le nombre d'agent séléctionné pour un jour
function totalOfAgentForDay(tab: number[]) {
  return tab.reduce((a: number, b: number) => a + b, 0);
}

function getMinFromPosition(
  array: number[],
  position: number,
  cdsPosition: number
) {
  let min = array[position];
  let finalPosition = position;
  for (let index = position; index < array.length; index++) {
    const element = array[index];
    if (element < min && index !== cdsPosition) {
      min = element;
      finalPosition = index;
    }
  }
  return finalPosition;
}

function getMaxFromPosition(
  array: number[],
  position: number,
  cdsPosition: number
) {
  let max = array[position];
  let finalPosition = position;
  for (let index = position; index < array.length; index++) {
    const element = array[index];
    if (element >= max && index !== cdsPosition) {
      max = element;
      finalPosition = index;
    }
  }
  return finalPosition;
}

const Uceso = (props: any) => {
  //Composant principal du mode Uceso
  const user = AuthenticationService.user;
  const classes = props.classes;

  const [uceso, setUceso] = useState([] as any);
  const [repartition, setRepartition] = useState([] as any);
  const [modeTest, setModeTest] = useState(false);

  const newRepartitionAgents = async (newRepartition: any) => {
    setRepartition(newRepartition);
    const horaire: any[] = [];
    const newPrecPersonnel: any[] = [];
    const newPersonnel: any[] = [];
    newRepartition.forEach((jour: any) => {
      const repartitionTest: any[] = [];
      const j = props.uceDay.horaire.jours.find(
        (newJour: any) =>
          newJour.nom === jour.nom && newJour.nomOctime === jour.nomOctime
      );
      horaire.push(j);
      jour.uceso.forEach((uceso: any, index: number) => {
        for (let indexe = 0; indexe < uceso; indexe++) {
          repartitionTest.push({
            horaire: jour.veille ? jour.nom : jour.nomOctime,
            horaireNum: index,
          });
        }
      });
      jour.veille
        ? newPrecPersonnel.push(repartitionTest)
        : newPersonnel.push(repartitionTest);
    });
    setUceso(
      calculUceso(horaire, newPersonnel, newPrecPersonnel, props.uceDay.date)
    );
  };

  // Permet de recalculer l'ensemble à chaque modification du fichier d'origine
  useEffect(() => {
    setModeTest(false);
    let testRepartition = props.uceDay.horaire.jours.map((jour: any) => {
      return {
        nom: jour.nom,
        nomOctime: jour.nomOctime,
        veille: jour.veille,
        uceso: Array(jour.sousJours.length).fill(0),
      };
    });

    props.uceDay.personnel.forEach((jour: any) => {
      const repartitionDayIndex = testRepartition.findIndex(
        (day: any) =>
          (jour[0].horaire === day.nom || jour[0].horaire === day.nomOctime) &&
          !day.veille
      );
      jour.forEach((agent: any) => {
        if (agent.horaireNum !== undefined) {
          testRepartition[repartitionDayIndex].uceso[agent.horaireNum] += 1;
        }
      });
    });

    props.uceDay.precPersonnel.forEach((jour: any) => {
      const repartitionDayIndex = testRepartition.findIndex(
        (day: any) =>
          (jour[0].horaire === day.nom || jour[0].horaire === day.nomOctime) &&
          day.veille
      );
      jour.forEach((agent: any) => {
        if (agent.horaireNum !== undefined) {
          testRepartition[repartitionDayIndex].uceso[agent.horaireNum] += 1;
        }
      });
    });
    newRepartitionAgents(testRepartition);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.uceDay]);

  return (
    <Grid item xs={12} className={props.classes.SpaceBetween}>
      {/* On fait varier l'affichage en fonction de si on est en mode test ou non*/}
      {props.uceDay && props.uceDay.uceso && !modeTest && (
        <UcesoGraph
          uceso={props.uceDay.uceso}
          nop={props.nop}
          besoinFMP={props.besoinFMP}
        />
      )}
      {props.uceDay && props.uceDay.uceso && modeTest && (
        <UcesoGraph
          uceso={props.uceDay.uceso}
          testUceso={uceso}
          nop={props.nop}
          besoinFMP={props.besoinFMP}
        />
      )}

      {user &&
        user.role.droits.uceso.editPers &&
        props &&
        props.uceDay &&
        props.uceDay.personnel &&
        props.uceDay.precPersonnel && (
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              {/* Bouton de switch entre mode test et mode normal*/}
              {user.role.droits.uceso.salto && (
                <Grid item xs style={{ textAlign: "center" }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setModeTest(!modeTest);
                    }}
                    className={classes.horaireValiditeSpace}
                  >
                    {modeTest && <>Mode Normal</>}
                    {!modeTest && <>Mode Test</>}
                  </Button>
                </Grid>
              )}
            </Grid>

            {!modeTest && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container justifyContent="center">
                    {props.uceDay.personnel.map((jour: any, index: number) => {
                      return (
                        <Grid
                          item
                          xs={4}
                          sm={2}
                          md={1}
                          style={{ textAlign: "center" }}
                          key={jour[0].horaire + index}
                          className={classes.horaireTopBar}
                        >
                          {jour[0].horaire} :{" "}
                          {
                            jour.filter(
                              (personnel: any) =>
                                personnel.absence === undefined
                            ).length
                          }
                        </Grid>
                      );
                    })}
                    {props.uceDay.precPersonnel.map(
                      (jour: any, index: number) => {
                        return (
                          <Grid
                            item
                            xs={4}
                            sm={2}
                            md={1}
                            style={{ textAlign: "center" }}
                            key={jour[0].horaire + "Veille" + index}
                            className={classes.horaireTopBar}
                          >
                            {jour[0].horaire + "Veille :"}
                            {
                              jour.filter(
                                (personnel: any) =>
                                  personnel.absence === undefined
                              ).length
                            }
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                </AccordionSummary>

                <AccordionDetails>
                  <AttributionPersonnel
                    uceDay={props.uceDay}
                    classes={classes}
                    handleAbsence={props.handleAbsence}
                    listOfUnassignedAgents={props.listOfUnassignedAgents}
                    addPerson={props.addPerson}
                    changeAttribution={props.changeAttribution}
                    position={props.position}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            {modeTest && (
              <Grid container justifyContent="center">
                {repartition.map((jour: any, index: number) => {
                  if (props.uceDay.horaire.jours[index].valeur !== -1) {
                    return (
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={3}
                        style={{ textAlign: "center" }}
                        key={index}
                        className={classes.horaireTopBar}
                      >
                        {jour.nom} : {totalOfAgentForDay(jour.uceso)}
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          style={{
                            maxWidth: "20px",
                            maxHeight: "20px",
                            minWidth: "20px",
                            minHeight: "20px",
                          }}
                          className={props.classes.horaireValidite}
                          onClick={() => {
                            let newRepartitionagents: any = [].concat(
                              repartition
                            );
                            let newValue = newRepartitionagents[index];

                            const day = props.uceDay.horaire.jours.find(
                              (jour: any) =>
                                jour.nom === newValue.nom &&
                                jour.nomOctime === newValue.nomOctime
                            );
                            const cdsPosition: number = day.sousJours.findIndex(
                              (sousJour: any) => sousJour.cds
                            );
                            const numberOfExtension = day.sousJours.filter(
                              (sousJour: any) => sousJour.nPExt > 0
                            );

                            const numberOfUceso = totalOfAgentForDay(
                              jour.uceso
                            );

                            if (cdsPosition !== -1 && numberOfUceso === 1) {
                              newValue.uceso[cdsPosition] -= 1;
                            } else {
                              //Variable permettant de savoir si les extensions ont déjà été attribué
                              let position: number = numberOfExtension.length;
                              if (numberOfUceso <= numberOfExtension.length) {
                                position = 0;
                              }
                              const indexOfValue = getMaxFromPosition(
                                newValue.uceso,
                                position,
                                cdsPosition
                              );

                              if (newValue.uceso[indexOfValue] > 0) {
                                newValue.uceso[indexOfValue] -= 1;
                              }
                            }

                            newRepartitionAgents(newRepartitionagents);
                          }}
                        >
                          <Minus
                            style={{
                              maxWidth: "15px",
                              maxHeight: "15px",
                              minWidth: "15px",
                              minHeight: "15px",
                            }}
                          />
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            maxWidth: "20px",
                            maxHeight: "20px",
                            minWidth: "20px",
                            minHeight: "20px",
                          }}
                          className={props.classes.horaireValidite}
                          onClick={() => {
                            const newRepartitionOfAgents: any = [].concat(
                              repartition
                            );

                            const newValue = newRepartitionOfAgents[index];

                            const day = props.uceDay.horaire.jours.find(
                              (jour: any) =>
                                jour.nom === newValue.nom &&
                                jour.nomOctime === newValue.nomOctime
                            );
                            const cdsPosition: number = day.sousJours.findIndex(
                              (sousJour: any) => sousJour.cds
                            );
                            const numberOfExtension = day.sousJours.filter(
                              (sousJour: any) => sousJour.nPExt > 0
                            );

                            const numberOfUceso = totalOfAgentForDay(
                              jour.uceso
                            );
                            //On attribue le CDS en premier si jamais le nomnbre d'agent est à 0
                            if (cdsPosition !== -1 && numberOfUceso === 0) {
                              newValue.uceso[cdsPosition] += 1;
                            }
                            // On attribue ensuite les autres sous-jours
                            else {
                              //Variable permettant de savoir si les extensions ont déjà été attribué
                              let position: number = numberOfExtension.length;
                              if (numberOfUceso <= numberOfExtension.length) {
                                position = numberOfUceso;
                              }

                              if (position >= newValue.uceso.length) {
                                position = 0;
                              }
                              const indexOfValue = getMinFromPosition(
                                newValue.uceso,
                                position,
                                cdsPosition
                              );

                              //On ajoute un au sous-jours
                              newValue.uceso[indexOfValue] += 1;
                            }
                            //On relance le calcul
                            newRepartitionAgents(newRepartitionOfAgents);
                          }}
                        >
                          <More
                            style={{
                              maxWidth: "15px",
                              maxHeight: "15px",
                              minWidth: "15px",
                              minHeight: "15px",
                            }}
                          />
                        </Button>
                      </Grid>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </Grid>
            )}
          </>
        )}
    </Grid>
  );
};

export default Uceso;
